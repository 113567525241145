import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SecondaryButton from "../form-components/secondary-button";
import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import copyIconDark from "../../assets/icons/dark/copy-text.svg";
import copyIconLight from "../../assets/icons/light/copy-text.svg";
import zoomLight from "../../assets/icons/light/zoom-in.svg";
import zoomDark from "../../assets/icons/dark/zoom-in.svg";
import { format } from "date-fns";
import { useVerifyAndUpgradeKYC3ByAdmin } from "../../utils/user.api";

const PendingLevelCard = ({ data, onClick }: any) => {
  const toast = useToast();
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue("#000", "#FFF");
  const bgColorSecondary = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "#20342A"
  );
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#262626");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#153116");

  const copyIcon = useColorModeValue(copyIconLight, copyIconDark);
  const editIcon = useColorModeValue(settingeditlight, settingeditdark);
  const zoomIcon = useColorModeValue(zoomLight, zoomDark);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");

  const updateKycStatus = useVerifyAndUpgradeKYC3ByAdmin();

  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
    if (!expand) {
      onClick(data?.userId);
    } else {
      onClick("");
    }
  };

  const handleImageZoom = (image: string) => {
    setSelectedImage(image);
    onOpen();
  };

  const copyText = (text: string, name: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: `${name} copied`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    <Flex
      cursor={"pointer"}
      // maxWidth={"892px"}
      height={expand ? "453px" : "44px"}
      width={"100%"}
      p={"17px 23px 19px 40px"}
      justifyContent={"space-between"}
      bg={bgColor}
      border={expand ? "1px solid #00FFE0" : undefined}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      onClick={handleExpand}
    >
      {/* when row collapse */}
      {!expand && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex gap={"20px"} alignItems={"center"}>
            <Text
              width={"90px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {format(new Date(data?.createdAt), "dd MMM yyyy, hh:mm a")}
            </Text>

            <Text
              width={"128px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.name}
            </Text>

            <Text
              width={"193px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.email}
            </Text>

            <Text
              width={"95px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >{`Level${data?.kycTier}`}</Text>

            <Text
              width={"87px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.adminApprovel}
            </Text>
          </Flex>

          <Image
            src={editIcon}
            alt="edit"
            width={"20px"}
            height={"20px"}
            cursor={"pointer"}
            onClick={handleExpand}
          />
        </Flex>
      )}

      {/* when row expand */}
      {expand && (
        <Flex direction={"column"} gap={"16px"} width={"100%"}>
          <Flex gap={"20px"} alignItems={"center"}>
            <Text
              width={"90px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {format(new Date(data?.createdAt), "dd MMMM yyyy, hh:mm a")}
            </Text>

            <Text
              width={"128px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.name}
            </Text>

            <Flex gap={"8px"} alignItems={"center"}>
              <Text
                width={"95px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.source?.phoneNumber}
              </Text>
              <Image
                src={copyIcon}
                alt="copy"
                width={"16px"}
                height={"16px"}
                onClick={(e) => {
                  e.stopPropagation();
                  copyText(data?.source?.phoneNumber, "Phone Number");
                }}
              />
            </Flex>

            <Flex gap={"8px"} alignItems={"center"}>
              <Text
                width={"193px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.email}
              </Text>
              <Image
                src={copyIcon}
                alt="copy"
                width={"16px"}
                height={"16px"}
                onClick={(e) => {
                  e.stopPropagation();
                  copyText(data?.email, "Email");
                }}
              />
            </Flex>
          </Flex>

          <Flex
            gap={"28px"}
            // alignItems={"center"}
            width={"100%"}
          >
            <Flex
              width={"402px"}
              height={"358px"}
              borderRadius={"10px"}
              border={`0.5px solid #00FFE0`}
              bg={bgColorSecondary}
              direction={"column"}
              gap={"15px"}
              p={"18px"}
            >
              {/* row 1 */}
              <Flex gap={"24px"}>
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    ID Document Type
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.means_of_id || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.means_of_id, "ID Document Type");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    ID Document Number
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.documentNumber || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(
                          data?.source?.documentNumber,
                          "ID Document Number"
                        );
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {/* row 2 */}
              <Flex gap={"24px"}>
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Date of Birth
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.dob || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.dob, "Date of Birth");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Place of Birth
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.placeOfBirth || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {/* row 3 */}
              <Flex gap={"24px"}>
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Address
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.address || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.address, "Address");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Gender
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.gender || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        copyText(data?.source?.gender, "Gender");
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {/* row 4 */}
              <Flex gap={"24px"}>
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    City
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.city || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        copyText(data?.source?.city, "City");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    State
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.state || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.state, "State");
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {/* row 5 */}
              <Flex gap={"24px"}>
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Country
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.country || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.country, "Country");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    BVN
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.source?.bvn || "NIN"}
                    </Text>
                    {/* <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.source?.bvn, "BVN");
                      }}
                    /> */}
                  </Flex>
                </Flex>
              </Flex>
              {data?.kycTier === "3" && (
                <Flex direction={"column"} gap={"8px"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Occupation
                  </Text>

                  <Flex gap={"8px"} alignItems={"center"}>
                    {" "}
                    <Text
                      width={"142px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"normal"}
                    >
                      {data?.occupation || "NIN"}
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(data?.occupation, "Occupation");
                      }}
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Flex
              width={"100%"}
              direction={"column"}
              gap={"46px"}
              alignItems={"center"}
              pr={"36px"}
            >
              {data?.kycTier === "3" ? (
                <Flex alignItems={"center"} gap={"30px"} ml={"26px"}>
                  <Box
                    width={"167px"}
                    height={"167px"}
                    position={"relative"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageZoom(data?.sourceOfFund);
                    }}
                  >
                    <Image
                      src={data?.sourceOfFund}
                      alt="profile"
                      width={"167px"}
                      height={"167px"}
                      borderRadius={"10px"}
                    />
                    <Image
                      src={zoomIcon}
                      alt="zoom"
                      width={"24px"}
                      height={"24px"}
                      position={"absolute"}
                      bottom={"10px"}
                      right={"10px"}
                    />
                  </Box>
                </Flex>
              ) : (
                <Flex alignItems={"center"} gap={"30px"} ml={"26px"}>
                  <Box
                    width={"167px"}
                    height={"167px"}
                    position={"relative"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageZoom(data?.source?.imageOfId);
                    }}
                  >
                    <Image
                      src={data?.source?.imageOfId}
                      alt="profile"
                      width={"167px"}
                      height={"167px"}
                      borderRadius={"10px"}
                    />
                    <Image
                      src={zoomIcon}
                      alt="zoom"
                      width={"24px"}
                      height={"24px"}
                      position={"absolute"}
                      bottom={"10px"}
                      right={"10px"}
                    />
                  </Box>
                  {/* working */}
                  <Box
                    width={"167px"}
                    height={"167px"}
                    position={"relative"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageZoom(data?.source?.passportPhoto);
                    }}
                  >
                    <Image
                      src={data?.source?.passportPhoto}
                      alt="doc"
                      width={"167px"}
                      height={"167px"}
                      borderRadius={"10px"}
                    />
                    <Image
                      src={zoomIcon}
                      alt="zoom"
                      width={"24px"}
                      height={"24px"}
                      position={"absolute"}
                      bottom={"10px"}
                      right={"10px"}
                    />
                  </Box>
                </Flex>
              )}
              <Flex direction={"column"} gap={"8px"} alignSelf={"end"}>
                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  Approval Status
                </Text>
                <Text
                  width={"105px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"normal"}
                >
                  Level {data?.kycTier} {data?.adminApprovel || "Pending"}
                </Text>
              </Flex>
              <Flex alignItems={"center"} gap={"20px"} alignSelf={"flex-end"}>
                <SecondaryButton
                  text={"Reject"}
                  bg={btnSecondaryBG}
                  border={"#000"}
                  isDisabled={updateKycStatus?.isPending}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (data?.adminApprovel === "Rejected") {
                      toast({
                        title: "Already Rejected",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      return;
                    }
                    toast({
                      title: "Submitting...",
                      status: "info",
                      duration: 2000,
                      isClosable: true,
                    });
                    updateKycStatus
                      .mutateAsync({
                        UserId: data?.userId,
                        status: "Rejected",
                        kycTier: data?.kycTier,
                      })
                      .then((result) => {
                        toast({
                          title: "Rejected",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                        handleExpand();
                      })
                      .catch((err) => {
                        toast({
                          title: err?.message || "Error Rejecting",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      });
                  }}
                />
                <SecondaryButton
                  text={"Approve"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  isDisabled={updateKycStatus?.isPending}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (data?.adminApprovel === "Rejected") {
                      toast({
                        title: "Already Rejected",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      return;
                    }
                    toast({
                      title: "Submitting...",
                      status: "info",
                      duration: 2000,
                      isClosable: true,
                    });
                    updateKycStatus
                      .mutateAsync({
                        UserId: data?.userId,
                        status: "Approved",
                        kycTier: data?.kycTier,
                      })
                      .then((result) => {
                        toast({
                          title: "Approved",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                        handleExpand();
                      })
                      .catch((err) => {
                        toast({
                          title: err?.message || "Error Approving",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      });
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}

      {/* Modal for Zoomed Image */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            alignItems={"center"}
            justifyItems={"center"}
            bg={"transparent"}
          >
            <Image
              src={selectedImage}
              alt="Zoomed Image"
              objectFit="contain"
              // maxHeight="100vh"
              // minWidth={"350px"}
              // minHeight={"350px"}
              width={"100%"}
              height={"100%"}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default PendingLevelCard;
