import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  type VerifyEmailForm,
  type User,
  type UserLogin,
  type SystemStatus,
  type CryptoReserves,
  type DeviceHistoryData,
  type NotificationData,
  type UserData,
} from "./types/user.type";
import { client } from "./api.client";

export const useLogin = () =>
  useMutation<
    { token: { access_token: string }; user: User },
    Error,
    UserLogin
  >({
    mutationFn: async (data: any) =>
      await client("auth/loginAdmin", {
        data,
      }),
  });

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, void>({
    mutationFn: async () => {
      localStorage.clear();
      queryClient.invalidateQueries();
      return null;
    },
  });
};

export const useVerifyLogin2FA = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, { code: string; token: string }>({
    mutationFn: async ({ code, token }) =>
      await client(`auth/enable2FA/${code}`, {
        method: "POST",
        data: { access_token: token },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getLoggedInUser"],
      });
    },
  });
};

export const useGetLoggedInUser = () =>
  useQuery<User, Error>({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => await client(`auth/getLoggedInUser`),
  });

export const useVerifyEmail = () =>
  useMutation<
    { user: User; token: { access_token: string } },
    Error,
    VerifyEmailForm
  >({
    mutationFn: async (data) =>
      await client(`auth/verifyEmail`, {
        data,
      }),
  });

export const useGetOtp = () =>
  useMutation<unknown, Error, string>({
    mutationFn: async (email) =>
      await client(`auth/forgotPassword`, {
        data: { email },
      }),
  });

export const useVerifyOtp = () =>
  useMutation<
    {
      token: {
        access_token: string;
      };
    },
    Error,
    VerifyEmailForm
  >({
    mutationFn: async (data) =>
      await client(`auth/verifyOtpForForgotPassword`, {
        data,
      }),
  });

export const useReSendOtp = () =>
  useMutation<unknown, Error, string>({
    mutationFn: async (email) =>
      await client("auth/resendOtp", {
        data: { email },
      }),
  });

export const useResetPassword = () =>
  useMutation<unknown, Error, { password: string; confirmPassword: string }>({
    mutationFn: async ({ password, confirmPassword }) =>
      await client("auth/resetPassword", {
        data: { password, confirmPassword },
        headers: {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing

          Authorization: localStorage.getItem("otp_token") || "",
        },
      }),
  });

export const useGetSystemStatus = () =>
  useQuery<SystemStatus, Error>({
    queryKey: ["getSystemStatus"],
    queryFn: async () => await client(`auth/getSystemStatus`),
  });

export const useGetAllAdmins = (
  limit: number,
  offset: number,
  filterOnlyAdmins: boolean
) =>
  useQuery<UserData, Error>({
    queryKey: ["getAllAdmins", limit, offset, filterOnlyAdmins],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        filterOnlyAdmins: filterOnlyAdmins.toString(), // Convert boolean to string
      });

      const queryString = queryParams.toString(); // Explicitly cast queryParams to string

      return await client(`auth/getAllUsers?${queryString}`);
    },
  });

export const useUpdateAdminPermission = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, any>({
    mutationFn: async (data) =>
      await client("v3/auth/updateAdminPermissions", {
        data,
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getAllAdmins"],
      });
    },
  });
};

export const useGenerateUsersWithBalanceCsv = () =>
  useQuery<any[], Error>({
    queryKey: ["generateUsersWithBalanceCsv"],
    queryFn: async () => await client(`auth/generateUsersWithBalanceCsv`),
  });

export const useGetCryptoReserves = () =>
  useQuery<CryptoReserves, Error>({
    queryKey: ["getCryptoReserves"],
    queryFn: async () => await client(`auth/getCryptoReserves`),
  });
export const useSetSystemStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, string>({
    mutationFn: async (status) =>
      await client("auth/setSystemStatus", {
        data: { status },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getSystemStatus"],
      });
    },
  });
};

export const useGiveAdminAccess = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    { UserId: string; isAdmin: boolean; isActive: boolean }
  >({
    mutationFn: async (data) =>
      await client("auth/giveAdminAccess", {
        data,
      }),

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getAllAdmins"],
      });
    },
  });
};

export const useDeviceHistory = () =>
  useQuery<DeviceHistoryData, Error>({
    queryKey: ["getDeviceHistory"],
    queryFn: async () => await client(`auth/getDeviceHistory`),
  });

export const useNotificationHistory = (toShow: number) =>
  useQuery<NotificationData, Error>({
    queryKey: ["notificationHistory", toShow],
    queryFn: async () =>
      await client(
        `auth/notificationHistory${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      ),
  });

export const useUpdateReferralCodeByUserId = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, any>({
    mutationFn: async (data) =>
      await client("auth/updateReferralCodeByUserId", {
        data,
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getReferralData"],
      });
    },
  });
};

export const useAddDeviceTokenForAdmin = () => {
  const addDeviceTokenForAdmin = async (deviceToken: any) => {
    const response = await client("auth/addDeviceTokenForAdmin", {
      method: "POST",
      data: deviceToken,
    });

    if (response?.error) {
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation({ mutationFn: addDeviceTokenForAdmin });
};

export const useRemoveDeviceTokenForAdmin = () => {
  const removeDeviceToken = async (deviceToken: any) => {
    const response = await client("auth/deletDeviceTokenAdmin", {
      method: "POST",
      data: deviceToken,
    });

    if (response?.error) {
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation({ mutationFn: removeDeviceToken });
};

export const useGetReferralData = (
  limit: Number,
  offset: Number,
  searchBy?: string,
  search?: string
) =>
  useQuery<any, Error>({
    queryKey: ["getReferralData"],
    queryFn: async () =>
      await client(
        `v3/auth/getReferralData/?limit=${limit}&offset=${offset}&searchBy=${searchBy}&search=${search}`
      ),
  });

export const useGetReferralStats = (type?: string) =>
  useQuery<any, Error>({
    queryKey: ["getReferralStats"],
    queryFn: async () =>
      await client(`v3/auth/getReferralStats?filterBy=${type}`),
  });

export const useCreateAdmin = () => {
  return useMutation<unknown, Error, any>({
    mutationFn: async (data) =>
      await client(`v3/auth/createAdmin?email=${data}`, {
        method: "POST",
      }),

    onSuccess() {},
  });
};

export const useUpdateReferralPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, any>({
    mutationFn: async (data) =>
      await client("auth/updateReferralPriceForAllUsers", {
        data,
      }),

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getReferralData"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getLoggedInUser"],
      });
    },
  });
};
