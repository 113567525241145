import {
  Avatar,
  Flex,
  Image,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "../general/tag";
import settingIcon from "../../assets/icons/general/setting.svg";
import SecondaryButton from "../form-components/secondary-button";
import Dropdown from "../form-components/dropdown";
import { useGetUserActivities } from "../../utils/user.api";
import { format } from "date-fns";
const SideBarTransactionHistory = ({ user }: any) => {
  const [reffCode, setReffCode] = useState("");

  const { data, isLoading, refetch } = useGetUserActivities({
    userId: user,
    limit: 5,
    offset: 0,
  });

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#153116");
  const textColor = useColorModeValue("#000", "#FFF");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleReffCodeUpdate = (e: any) => {
    setReffCode(e);
  };

  useEffect(() => {
    setReffCode("");
    refetch();
    console.log("testing something");
  }, [user, refetch]);

  const handleEmailButtonClick = () => {
    window.location.href = "https://mail.zoho.com";
  };
  return (
    <Flex
      width={{ base: "305px", md: "333px" }}
      height={"605px"}
      borderRadius={"10px"}
      border={"1px solid #00FFE0"}
      bg={bgColor}
      p={"18px 14.5px  18px 14.5px"}
      direction={"column"}
      gap={"30px"}
      alignItems={"center"}
    >
      {user ? (
        <>
          <Flex gap={"14px"} alignItems={"center"}>
            <Avatar
              src={data?.image}
              name={data?.user?.fullname}
              boxSize="36px"
            />
            <Flex gap={"20px"} alignItems={"center"} width={"100%"}>
              <Tag
                text={"Referral Code"}
                width={"130px"}
                icon
                user={data}
                reffCode={handleReffCodeUpdate}
              />

              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"14px"}
                fontWeight={500}
                lineHeight={"normal"}
              >
                {reffCode ? reffCode : data?.user?.referralCode || ""}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems={"center"} gap={"15px"} position={"relative"}>
            <Tag text={"User Activity"} />
            <Image
              src={settingIcon}
              alt="settingicon"
              width={"13px"}
              height={"13px"}
              onClick={handleDropdownToggle}
              cursor={"pointer"}
            />
            {dropdownOpen && (
              <Dropdown
                isOpen={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
                positionTop={"20px"}
                sub
                left
                positionRight={"-120px"}
                userId={user}
                type={"userActivity"}
              />
            )}
          </Flex>

          {/* Here are history of transactions  */}
          <Flex direction={"column"} gap={"16px"} mt={"-16px"} width={"253px"}>
            {isLoading ? (
              <Spinner />
            ) : (
              data?.userActivities.map((item: any, index: number) => (
                <Flex
                  key={index}
                  gap={"7px"}
                  //alignItems={"center"}
                  alignItems={"start"}
                  justifyContent={"flex-start"}
                >
                  <Text
                    width={"70px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"12px"}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    {format(new Date(item?.createdAt), "dd/MM/yyyy, hh:mm a")}
                  </Text>
                  <Text
                    width={"176px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"12px"}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    {item?.details}
                  </Text>
                </Flex>
              ))
            )}
          </Flex>

          <SecondaryButton
            text={"Send email"}
            primary
            bg={btnPrimaryBG}
            border={"#00FFE0"}
            width={"250px"}
            onClick={handleEmailButtonClick}
          />
        </>
      ) : (
        <>
          <Text
            mt={"70%"}
            color={textColor}
            textAlign={"center"}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"23px"}
            fontStyle={"italic"}
            fontWeight={"400"}
          >
            Nothing to show here.{" "}
          </Text>
          <Text
            mt={"15px"}
            color={textColor}
            textAlign={"center"}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"23px"}
            fontStyle={"italic"}
            fontWeight={"400"}
          >
            Select a user to view their activity
          </Text>
        </>
      )}
    </Flex>
  );
};

export default SideBarTransactionHistory;
