import {
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import searchdark from "../../assets/icons/dark/search-icon.svg";
import searchlight from "../../assets/icons/light/search-icon.svg";
import settingIcon from "../../assets/icons/general/setting.svg";
import searchCloseLight from "../../assets/icons/light/searchbar-close.svg";
import searchCloseDark from "../../assets/icons/dark/searchbar-close.svg";
import Dropdown from "../form-components/dropdown";
import { SearchIcon } from "@chakra-ui/icons";

const ReferralSearchbar = ({ text, onFilterChange, onTextChange }: any) => {
  const editIcon = useColorModeValue(settingeditlight, settingeditdark);
  const searchIcon = useColorModeValue(searchlight, searchdark);
  const closeIcon = useColorModeValue(searchCloseLight, searchCloseDark);

  const textColor = useColorModeValue("#000", "rgba(255, 255, 255, 0.49)");
  const textColorPlaceholder = useColorModeValue("#000", " #FFF");

  const bgColor = useColorModeValue("rgba(255, 255, 255, 0.4)", "#0e0e0e");
  const bgColor1 = useColorModeValue("rgba(255, 255, 255, 0.4)", "#313131");

  const bgInput = useColorModeValue("#e7e7e7", "#000000");

  const [expand, setExpand] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSearchDropdownToggle = () => {
    setSearchDropdownOpen(!searchDropdownOpen);
  };

  const handleFilterSelection = (filter: string, value: string) => {
    onFilterChange(filter, value);
  };

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      onTextChange(searchTerm);
    }, 700); // adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, onTextChange]);

  return (
    <Flex width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
      <Flex
        alignItems={"center"}
        bg={bgColor1}
        width={expand ? "567px" : "335px"}
        height={"49px"}
        borderRadius={
          "var(--Timer-seconds, 20px) var(--Timer-seconds, 20px) var(--Spacing-spacing-none, 0px) var(--Spacing-spacing-none, 0px)"
        }
        justifyContent={"space-between"}
        p={"17px 22px"}
      >
        {!expand && (
          <Image
            cursor={"pointer"}
            src={searchIcon}
            alt="edit"
            width={"16px"}
            height={"16px"}
            onClick={() => {
              setExpand(!expand);
            }}
          />
        )}
        {expand && (
          <InputGroup width={"462px"} display={"flex"} alignItems={"center"}>
            <InputLeftElement pointerEvents="none" marginTop={"-6px"}>
              <SearchIcon color={textColorPlaceholder} />{" "}
            </InputLeftElement>
            <Input
              bg={bgInput}
              borderRadius={"10px"}
              borderColor={"transparent"}
              width={"462px"}
              height={"29px"}
              placeholder="Click to toggle the search options"
              textColor={textColorPlaceholder}
              _placeholder={{ color: textColorPlaceholder }}
              onChange={handleInputChange}
            />
            <InputRightElement marginTop={"-6px"}>
              <Image
                src={editIcon}
                alt="edit"
                width={"16px"}
                height={"16px"}
                onClick={handleSearchDropdownToggle}
                cursor={"pointer"}
              />
              {searchDropdownOpen && (
                <Dropdown
                  isOpen={searchDropdownOpen}
                  onClose={() => setSearchDropdownOpen(false)}
                  options={["Referral Code", "User Name", "Referral Price"]}
                  positionTop={"32px"}
                  positionLeft={"12px"}
                  onSelect={handleFilterSelection}
                  setSelectedOption={() => {}}
                />
              )}
            </InputRightElement>
          </InputGroup>
        )}

        {!expand && (
          <Text
            width={"full"}
            color={textColorPlaceholder}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"15px"}
            fontStyle={"normal"}
            fontWeight={700}
            lineHeight={"25px"}
            ml={"8px"}
          >
            {text}
          </Text>
        )}
        <Flex alignItems={"center"} gap={"10px"} position={"relative"}>
          {expand && (
            <Image
              src={closeIcon}
              width={"24px"}
              height={"24px"}
              cursor={"pointer"}
              onClick={() => {
                setExpand(!expand);
              }}
            />
          )}
        </Flex>
      </Flex>

      <Flex
        alignItems={"center"}
        bg={bgColor}
        width={"152px"}
        height={"49px"}
        borderRadius={
          "var(--Timer-seconds, 20px) var(--Timer-seconds, 20px) var(--Spacing-spacing-none, 0px) var(--Spacing-spacing-none, 0px)"
        }
        justifyContent={"space-between"}
        p={"17px 22px"}
        position={"relative"}
      >
        <Text
          fontFamily={"Plus Jakarta Sans"}
          fontSize={"15px"}
          fontWeight={500}
          lineHeight={"25px"}
          color={textColor}
        >
          Export
        </Text>
        <Image
          src={settingIcon}
          alt="filter"
          width={"16px"}
          height={"16px"}
          onClick={handleDropdownToggle}
          cursor="pointer"
        />
        {dropdownOpen && (
          <Dropdown
            isOpen={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
            sub
            left
            type={"referrers"}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ReferralSearchbar;
