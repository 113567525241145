import { Box, Flex, Image, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import Logo from "../assets/Logo.svg";
// import LogoDark from "../assets/LogoDark.svg";
// import Hamburger from "hamburger-react";
import { useLocation } from "react-router-dom";
import logoLight from "../../assets/payremit-logo(s)/payremit-light.svg";
import logoDark from "../../assets/payremit-logo(s)/payremit-dark.svg";
import logoMinLight from "../../assets/payremit-logo(s)/payremit-min-logo-light.svg";
import logoMinDark from "../../assets/payremit-logo(s)/payremit-min-logo-dark.svg";
import MenuList from "./MenuList";
import { useGetLoggedInUser } from "../../utils/auth.api";

const SideBar = ({ isOpen, setIsOpen, handleSideBar }: any) => {
  const { data: user } = useGetLoggedInUser();

  const [isActive, setIsActive] = useState(() => {
    const storedValue = sessionStorage.getItem("isActive");
    return storedValue ? storedValue : "/index";
  });

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [width, setWidth] = useState<string>(
    screenWidth < 480
      ? "60px"
      : screenWidth < 768 && screenWidth > 479
      ? "110px"
      : "270px"
  );

  const sidebarW = sessionStorage.getItem("sidebarW");

  const bg = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );
  const mainLogo = useColorModeValue(logoLight, logoDark);
  const mainLogoMin = useColorModeValue(logoMinLight, logoMinDark);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 768 && screenWidth > 479) {
      setIsOpen(false);
      sessionStorage.setItem("isOpen", "0");
    }
    if (screenWidth > 479 && isOpen) {
      setWidth("270px");
      sessionStorage.setItem("sidebarW", "270px");
    } else if (screenWidth > 479 && !isOpen) {
      setWidth("110px");
      sessionStorage.setItem("sidebarW", "110px");
    } else if (screenWidth < 480 && isOpen) {
      setWidth("270px");
      sessionStorage.setItem("sidebarW", "270px");
    } else if (screenWidth < 480 && !isOpen) {
      setWidth("60px");
      sessionStorage.setItem("sidebarW", "60px");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth, isOpen]);

  useEffect(() => {
    if (screenWidth < 479) {
      setIsOpen(false);
      localStorage.setItem("isOpen", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isActive]);

  return (
    <Box
      bg={bg}
      h="100vh"
      // position={"fixed"}
      maxW={sidebarW || width || "270px"}
      w="100%"
      transition={"width 0.3s ease"}
      p={["36px 4px 40px", "36px 4px 40px", "56px 24px 40px"]}
      zIndex={2}
      overflowY={"auto"}
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "2px",
        },
        "&::-webkit-scrollbar-track": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "teal",
          borderRadius: "24px",
        },
      }}
    >
      <Box
        display={{ sm: "none" }}
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            localStorage.setItem("isOpen", "0");
          } else localStorage.setItem("isOpen", "1");
        }}
      >
        {/* <Hamburger toggled={isOpen} size={20} /> */}
      </Box>
      <Flex justify={"center"} align={"center"} gap={"15px"}>
        {isOpen && <Image alt="logo" src={mainLogo} />}
        {!isOpen && <Image alt="logo" src={mainLogoMin} />}
      </Flex>
      {/* <Divider my="40px" /> */}

      <Flex direction={"column"} justify={"space-between"} h="80%" mt={"30px"}>
        <MenuList
          {...{
            isOpen,
            setIsOpen,
            isActive,
            setIsActive,
            handleSideBar,
            user,
          }}
        />
      </Flex>
    </Box>
  );
};

export default SideBar;
