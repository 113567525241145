import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import PriceCard from "../cards/price-card";
import settingIco from "../../assets/icons/general/setting.svg";

import Dropdown from "../form-components/dropdown";
import { useGetCryptoPriceToday } from "../../utils/wallet.api";

const CryptoPriceToday = (coinId: any) => {
  console.log("test 5 id", coinId);
  const { data, isLoading, error } = useGetCryptoPriceToday();

  let cryptoData: any = [];

  if (data && Array.isArray(data)) {
    cryptoData = data.map((item: any) => ({
      price: item.current_price.toFixed(2),
      stat: `${
        item.price_change_percentage_24h >= 0 ? "+" : ""
      }${item.price_change_percentage_24h.toFixed(2)}%`,
      currency: item.symbol.split(" ")[0],
      image: item.icon,
      id: item.coinId,
    }));
  }

  const textColor = useColorModeValue("#000", "#FFF");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <Flex direction={"column"} gap={"12px"}>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Text
          color={textColor}
          fontFamily={"Plus Jakarta Sans"}
          fontSize={"19px"}
          fontWeight={500}
          lineHeight={"25px"}
        >
          Crypto Prices Today
        </Text>
        <Box position={"relative"}>
          <Image
            src={settingIco}
            width={"20px"}
            height={"20px"}
            onClick={handleDropdownToggle}
            cursor="pointer"
          />
          {dropdownOpen && (
            <Dropdown
              isOpen={dropdownOpen}
              onClose={() => setDropdownOpen(false)}
              positionTop={"20px"}
              positionRight={"-20px"}
              left
              sub
              type={"coinPrices"}
            />
          )}
        </Box>
      </Flex>

      {isLoading ? (
        <Text>Loading...</Text>
      ) : error ? (
        <Text>Error loading data</Text>
      ) : (
        <Flex direction={"column"} gap={"21px"}>
          {cryptoData?.map((item: any, index: number) => (
            <PriceCard
              key={index}
              image={item.image}
              currency={item.currency}
              stat={item.stat}
              price={item.price}
              id={coinId}
              currencyId={item.id}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default CryptoPriceToday;
