import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Box, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import StatsCard from "../components/cards/stats-card";
import chartIconGrowDark from "../assets/icons/dark/chart-arrow-grow.svg";
import chartIconDownDark from "../assets/icons/dark/chat-arrow-down.svg";
import chartIconGrowLight from "../assets/icons/light/chat-arrow-grow.svg";
import chartIconDownLight from "../assets/icons/light/chat-arrow-down.svg";
import FilterCard from "../components/cards/filter-stats-card";

import MultilineChartWithFilters from "../components/gaphs/multilines-graph";
import Searchbar from "../components/searchbar-filters/searchbar";
import Pagination from "../components/general/pagination";
import CryptoPriceToday from "../components/general/crypto-price-right-bar";
import {
  useGetFilterTransactions,
  useGetTransactionsStats,
} from "../utils/wallet.api";
import AprrovedTransactionCard from "../components/cards/approved-transaction/approved-transaction-card";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useNavigate } from "react-router-dom";

const Transaction = () => {
  const navigate = useNavigate();
  // const textColor = useColorModeValue("#000", "#FFF");

  ///stats
  //stats icon
  const chartGrow = useColorModeValue(chartIconGrowLight, chartIconGrowDark);
  const chartDown = useColorModeValue(chartIconDownLight, chartIconDownDark);
  //stats
  const [statsData, setStatsData] = useState([
    {
      title: "Withdrawals",
      stats: "",
      image: chartDown,
      type: "withdrawals",
    },
    {
      title: "Deposits",
      stats: "",
      image: chartGrow,
      type: "deposits",
    },
    {
      title: "Crypto. Reserves",
      stats: "",
      image: chartGrow,
      type: "cryptoReserves",
    },
  ]);

  const [transactions, setTransactions] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  // State variables for filters
  const [coinName, setCoinName] = useState("");
  const [type, setType] = useState("");
  const [searchType, setSearchType] = useState("userEmail");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Completed");
  const [period, setPeriod] = useState("All time");

  // Fetching transactions history with filters
  const { data, isLoading, error, refetch } = useGetFilterTransactions({
    coinName,
    status,
    type,
    searchType,
    search,
    limit,
    offset,
    period,
  });
  // Fetching stats data
  const { data: statsResponse } = useGetTransactionsStats({
    period: "MAX",
  });

  const { data: user, isLoading: userLoading } = useGetLoggedInUser();

  //search bar filter
  const handleFilterChange = (filter: string, value: string) => {
    switch (filter) {
      case "type":
        setType(value);
        break;
      case "coinName":
        setCoinName(value);
        break;
      case "searchType":
        if (value.toLowerCase().includes("email")) {
          setSearchType("userEmail");
        } else if (value.toLowerCase().includes("amount")) {
          setSearchType("transactionAmount");
        } else if (value.toLowerCase().includes("number")) {
          setSearchType("phoneNumber");
        } else {
          setSearchType("");
        }
        break;
      case "transactionStatus":
        setStatus(value);
        break;
      case "period":
        setPeriod(value);
        console.log("period test", period);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setTransactions(data?.transactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, coinName, search, searchType, status, limit, offset, period]);

  useEffect(() => {
    if (!userLoading && !user?.permissions?.transactions) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (statsResponse) {
      setStatsData([
        {
          title: "Withdrawals",
          stats: statsResponse.totalWithdrawals.toFixed(2),
          image: chartDown,

          type: "withdrawals",
        },
        {
          title: "Deposits",
          stats: statsResponse.totalDeposits.toFixed(2),
          image: chartGrow,
          type: "deposits",
        },
        {
          title: "Crypto. Reserves",
          stats: statsResponse.totalReserve.toFixed(2),
          image: chartGrow,
          type: "cryptoReserves",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsResponse]);

  return (
    <AdminLayout>
      <Flex direction={"column"} gap={"58px"}>
        {/* top stats row */}
        <Flex
          gap={"26px"}
          justifyContent={"flex-start"}
          alignItems={{ base: undefined, md: "center" }}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
        >
          {statsData?.map((stat: any, index: number) => (
            <StatsCard
              key={index}
              title={stat.title}
              stats={stat.stats}
              image={stat.image}
              type={stat.type}
            />
          ))}
          <FilterCard type={"users"} left={true} />
        </Flex>

        {/* pending req and crypto rates today */}
        <Flex gap={"23px"} direction={{ base: "column", md: "row" }}>
          {/* pending req */}
          <Box
            overflowX={{ base: "auto", md: undefined }}
            width={{ base: "100%", md: "100%" }}
          >
            {" "}
            <Flex
              gap={"11px"}
              width={{ base: "895px", md: "100%" }}
              direction={"column"}
              // maxWidth={"892px"}
            >
              <MultilineChartWithFilters />

              <Flex
                gap={"6px"}
                width={"100%"}
                direction={"column"}
                // maxWidth={"892px"}
              >
                <Box width={"100%"} mb={"-6px"}>
                  <Searchbar
                    onTextChange={(e: any) => setSearch(e)}
                    text={`Transaction History(${data?.total || 0})`}
                    onFilterChange={handleFilterChange}
                    type={type}
                    searchType={searchType}
                    search={search}
                    status={status}
                    home={false}
                  />
                </Box>

                {isLoading ? (
                  <Spinner />
                ) : error ? (
                  <Box color="red">Error fetching transactions</Box>
                ) : (
                  <>
                    {transactions?.map((transaction: any, index: number) => (
                      <AprrovedTransactionCard
                        key={index}
                        transaction={transaction}
                      />
                    ))}
                  </>
                )}

                <Box>
                  <Pagination
                    total={data?.total || 0}
                    limit={limit}
                    offset={offset}
                    onLimitChange={setLimit}
                    onPageChange={setOffset}
                  />
                </Box>
              </Flex>
            </Flex>
          </Box>

          {/* today crypto rate */}
          <CryptoPriceToday />
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default Transaction;
