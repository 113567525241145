import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import CustomDropdown from "../form-components/dropdown-gen";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const Pagination = ({
  total,
  onPageChange,
  onLimitChange,
  limit,
  offset,
}: any) => {
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const handleLeftButtonClick = () => {
    if (offset > 0) {
      if (offset - limit < 0) {
        onPageChange(0);
        return;
      }
      onPageChange(offset - limit);
    }
  };

  const handleRightButtonClick = () => {
    if (offset < total) {
      onPageChange(offset + limit);
    }
  };

  return (
    <Flex justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
      <Flex alignItems={"center"} gap={"20px"}>
        <Text
          fontFamily={"Plus Jakarta Sans"}
          color={textColor}
          fontSize={"13px"}
          fontWeight={500}
          lineHeight={"normal"}
        >
          Showing
        </Text>
        <Flex alignItems={"center"} gap={"14px"}>
          <CustomDropdown
            padding={"5px 4px"}
            pl={"5px"}
            width={"50px"}
            height={"44px"}
            placeholder={limit}
            value={limit}
            handleChange={(e: any) => {
              onLimitChange(Number(e));
            }}
            options={["10", "20", "50", "100"]}
          />
          <Text
            fontFamily={"Plus Jakarta Sans"}
            color={textColor}
            fontSize={"13px"}
            fontWeight={500}
            lineHeight={"normal"}
          >
            of {total || 0}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"20px"}>
        <Button
          bg={bgColor}
          width="32px"
          height="44px"
          borderRadius="5px"
          alignItems="center"
          justifyContent="center"
          onClick={handleLeftButtonClick}
        >
          <Flex
            width={"32px"}
            height={"44px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconChevronLeft size="24px" color={textColor} />
          </Flex>
        </Button>

        <Button
          bg={bgColor}
          width="32px"
          height="44px"
          borderRadius="5px"
          alignItems="center"
          justifyContent="center"
          onClick={handleRightButtonClick}
        >
          <Flex
            width={"32px"}
            height={"44px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconChevronRight size="24px" color={textColor} />
          </Flex>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Pagination;
