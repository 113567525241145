import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Box, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import StatsCard from "../components/cards/stats-card";
import chartIconGrowDark from "../assets/icons/dark/chart-arrow-grow.svg";
import chartIconDownDark from "../assets/icons/dark/chat-arrow-down.svg";
import chartIconGrowLight from "../assets/icons/light/chat-arrow-grow.svg";
import chartIconDownLight from "../assets/icons/light/chat-arrow-down.svg";
import FilterCard from "../components/cards/filter-stats-card";

import Searchbar from "../components/searchbar-filters/searchbar";
import Pagination from "../components/general/pagination";
import {
  useGetFilterTransactions,
  useGetTransactionsStats,
} from "../utils/wallet.api";
import PendingTransactionCard from "../components/cards/pending-transaction/penidng-transaction-card";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  // const textColor = useColorModeValue("#000", "#FFF");

  ///stats
  //stats icon
  const chartGrow = useColorModeValue(chartIconGrowLight, chartIconGrowDark);
  const chartDown = useColorModeValue(chartIconDownLight, chartIconDownDark);
  //stats dummy data
  const [statsData, setStatsData] = useState([
    {
      title: "Withdrawals",
      stats: "",
      image: chartDown,
      nav: "/transactions",
      type: "withdrawals",
    },
    {
      title: "Deposits",
      stats: "",
      image: chartGrow,
      nav: "/transactions",
      type: "deposits",
    },
    {
      title: "Crypto. Reserves",
      stats: "",
      image: chartGrow,
      nav: "/transactions",
      type: "cryptoReserves",
    },
  ]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [transactions, setTransactions] = useState([]);

  // State variables for filters
  const [coinName, setCoinName] = useState("");
  const [type, setType] = useState("");
  const [searchType, setSearchType] = useState("userEmail");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Pending");
  const [period, setPeriod] = useState("All time");

  // Fetching pending transactions with filters
  const { data, isLoading, error, refetch } = useGetFilterTransactions({
    coinName,
    status,
    type,
    searchType,
    search,
    limit,
    offset,
    period,
  });
  const { data: user, isLoading: userLoading } = useGetLoggedInUser();

  // Fetching transaction stats data
  const { data: statsResponse } = useGetTransactionsStats({
    period: "MAX",
  });

  const handleFilterChange = (filter: string, value: string) => {
    console.log("home search test", filter, value);
    switch (filter) {
      case "type":
        setType(value);
        break;
      case "coinName":
        setCoinName(value);
        break;

      case "searchType":
        if (value.toLowerCase().includes("email")) {
          setSearchType("userEmail");
        } else if (value.toLowerCase().includes("amount")) {
          setSearchType("transactionAmount");
        } else if (value.toLowerCase().includes("number")) {
          setSearchType("phoneNumber");
        } else {
          setSearchType("");
        }
        break;
      case "transactionStatus":
        setStatus(value);
        break;
      case "period":
        setPeriod(value);
        console.log("period test on home page", period);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      console.log("test home", data);
      setTransactions(data?.transactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, coinName, search, searchType, status, limit, offset, period]);

  useEffect(() => {
    if (statsResponse) {
      setStatsData([
        {
          title: "Withdrawals",
          stats: statsResponse.totalWithdrawals.toFixed(2),
          image: chartDown,
          nav: "/transactions",
          type: "withdrawals",
        },
        {
          title: "Deposits",
          stats: statsResponse.totalDeposits.toFixed(2),
          image: chartGrow,
          nav: "/transactions",
          type: "deposits",
        },
        {
          title: "Crypto. Reserves",
          stats: statsResponse.totalReserve.toFixed(2),
          image: chartGrow,
          nav: "/transactions",
          type: "cryptoReserves",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsResponse]);

  useEffect(() => {
    if (!userLoading && !user?.permissions?.home) {
      navigate("/rate");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AdminLayout>
      <Flex direction={"column"} gap={"58px"}>
        {/* top stats row */}
        <Flex
          gap={"26px"}
          justifyContent={"flex-start"}
          alignItems={{ base: undefined, md: "center" }}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
        >
          {statsData?.map((stat: any, index: number) => (
            <StatsCard
              key={index}
              title={stat.title}
              stats={stat.stats}
              image={stat.image}
              nav={stat.nav}
              type={stat.type}
            />
          ))}
          <FilterCard left={true} />
        </Flex>

        {/* pending req and crypto rates today */}
        <Flex
          gap={{ base: "23px", md: undefined }}
          justifyContent={{ md: "space-between" }}
          direction={{ base: "column", md: "row" }}
          minH={"550px"}
        >
          {/* pending req */}
          <Box
            overflowX={{ base: "auto", md: undefined }}
            width={{ base: "100%", md: undefined }}
          >
            <Flex
              gap={"11px"}
              width={{ base: "895px", md: "100%" }}
              direction={"column"}
              // maxWidth={"892px"}
              minWidth={"800px"}
            >
              <Box width={"100%"} mb={"-11px"}>
                <Searchbar
                  onTextChange={(e: any) => setSearch(e)}
                  text={`${status} transactions (${data?.total || 0})`}
                  onFilterChange={handleFilterChange}
                  pending={true}
                  type={type}
                  searchType={searchType}
                  search={search}
                  status={status}
                  home={true}
                  options={[
                    "Transaction Date",
                    "Transaction Type",
                    "Currency Type",
                    "User email",
                    "Transaction amount",
                    "Phone Number",
                  ]}
                />
              </Box>

              {isLoading ? (
                <Spinner />
              ) : error ? (
                <Box color="red">Error fetching transactions</Box>
              ) : (
                <>
                  {" "}
                  {transactions?.map((transaction: any, index: number) => (
                    <PendingTransactionCard data={transaction} key={index} />
                  ))}
                </>
              )}

              <Box>
                <Pagination
                  total={data?.total || 0}
                  limit={limit}
                  offset={offset}
                  onLimitChange={setLimit}
                  onPageChange={setOffset}
                />
              </Box>
            </Flex>
          </Box>

          {/* for now it is commented becuase of chnaging in requirement */}
          {/* today crypto rate */}
          {/* <CryptoPriceToday /> */}
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default Home;
