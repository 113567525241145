import React, { useState } from "react";
import { Box, useColorModeValue, Button, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CustomDropdown from "../form-components/dropdown-gen";

const CustomDropdownAdmin = ({
  pl,
  padding,
  width,
  height,
  placeholder,
  options,
  handleChange,
}: any) => {
  const bgColor = useColorModeValue("#FFF", "#000");
  // const hoverColor = useColorModeValue("#67C475", "#00FFE0");
  // const hoverColorText = useColorModeValue("#FFF", "#000");
  const textColor = useColorModeValue("#000", "#FFF");

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Box position="relative">
      <Button
        pl={pl}
        onClick={handleToggle}
        bg={bgColor}
        color={textColor}
        borderRadius="5px"
        width={width ? width : "170px"}
        height={height ? height : "40px"}
        boxShadow="0px 4px 40px 0px rgba(0, 0, 0, 0.05)"
        rightIcon={<ChevronDownIcon />}
        padding={padding}
      >
        {placeholder}
      </Button>
      {isOpen && (
        <Flex
          zIndex={9999}
          // maxW="390px"
          width="400px"
          direction={"column"}
          gap="15px"
          bg={bgColor}
          borderRadius="5px"
          mt="2"
          position="absolute"
          top="40px"
          padding={"22px"}
        >
          <>
            <Text color={textColor} fontSize={"13px"} fontWeight={700}>
              Update Privileges
            </Text>

            {options.map((option: any, index: number) => (
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Text color={textColor} fontSize={"15px"} fontWeight={500}>
                  {option?.titlePage}
                </Text>
                <CustomDropdown
                  bg={"#00FFE0"}
                  placeholder={option?.titleAccess}
                  value={option?.titleAccess}
                  options={["Give Access", "Revoke Access"]}
                  handleChange={(value: string) => {
                    handleChange({ value, index });
                  }}
                />
              </Flex>
            ))}
          </>
        </Flex>
      )}
    </Box>
  );
};

export default CustomDropdownAdmin;
