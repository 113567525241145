import React, { useEffect, useState } from "react";
import MapChart from "../../components/map/map";
import AdminLayout from "../../layouts/AdminLayout";
import { Flex, useColorModeValue } from "@chakra-ui/react";

import StatsCard from "../../components/cards/stats-card";
import FilterCard from "../../components/cards/filter-stats-card";

import userIconLight from "../../assets/icons/general/user-icon-light.svg";
import userIconDark from "../../assets/icons/general/user-icon-dark.svg";
import locationLight from "../../assets/icons/light/location.svg";
import deviceLight from "../../assets/icons/light/device.svg";
import locationDark from "../../assets/icons/dark/location.svg";
import deviceDark from "../../assets/icons/dark/device.svg";
import { useGetUsersStats } from "../../utils/user.api";

const Location = () => {
  const { data: statsResponse } = useGetUsersStats("1D");

  // const textColor = useColorModeValue("#000", "#FFF");

  ///stats
  //stats icon
  const userIcon = useColorModeValue(userIconLight, userIconDark);
  const locationIcon = useColorModeValue(locationLight, locationDark);
  const deviceIcon = useColorModeValue(deviceLight, deviceDark);
  //stats dummy data
  const [statsData, setStatsData] = useState([
    {
      title: "Total Users",
      stats: "0",
      image: userIcon,
      nav: "/user",
      type: "users",
      active: false,
    },
    {
      title: "Locations",
      stats: "0",
      image: locationIcon,
      nav: "/user/location",
      type: "locations",
      active: true,
    },
    {
      title: "Devices",
      stats: "0",
      image: deviceIcon,
      nav: "/user/device",
      type: "devices",
      active: false,
    },
  ]);

  useEffect(() => {
    if (statsResponse) {
      setStatsData([
        {
          title: "Total Users",
          stats: statsResponse.totalUsers || "0",
          image: userIcon,
          nav: "/user",
          type: "users",
          active: false,
        },
        {
          title: "Locations",
          stats: statsResponse.locations || "0",
          image: locationIcon,
          nav: "/user/location",
          type: "users",
          active: true,
        },
        {
          title: "Devices",
          stats: statsResponse.devices || "0",
          image: deviceIcon,
          nav: "/user/device",
          type: "users",
          active: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsResponse]);

  return (
    <AdminLayout>
      <Flex direction={"column"} gap={"58px"}>
        {/* top stats row */}
        <Flex
          gap={"26px"}
          justifyContent={"flex-start"}
          alignItems={{ base: undefined, md: "center" }}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
        >
          <FilterCard type={"users"} />
          {statsData?.map((stat: any, index: number) => (
            <StatsCard
              key={index}
              title={stat.title}
              stats={stat.stats}
              image={stat.image}
              nav={stat.nav}
              active={stat.active}
              type={stat.type}
            />
          ))}
        </Flex>

        {/* map */}

        <Flex>
          {/* map*/}
          <MapChart />
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default Location;
