import {
  Avatar,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "../general/tag";
import SecondaryButton from "../form-components/secondary-button";
import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import copyIconDark from "../../assets/icons/dark/copy-text.svg";
import copyIconLight from "../../assets/icons/light/copy-text.svg";
import settingIcon from "../../assets/icons/general/setting.svg";
import { format } from "date-fns";

// import moneyDark from "../../assets/icons/dark/money.svg";
// import moneyLight from "../../assets/icons/light/money.svg";
import Dropdown from "../form-components/dropdown";
import { useUpdateUser, useUpdateUserRole } from "../../utils/user.api";

interface BalanceItem {
  title: any;
  code: any;
  balance: any;
  icon: any;
}

const UserDirectoryCard = ({ data, onClick }: any) => {
  // const moneyIcon = useColorModeValue(moneyLight, moneyDark);
  const toast = useToast();

  const updateUserRole = useUpdateUserRole();
  const updateUser = useUpdateUser();

  const [balanceArray, setBalanceArray] = useState<BalanceItem[]>([]);
  const [userRole, setUserRole] = useState(data?.role || "");
  const [userStatus, setUserStatus] = useState(data?.isActive || false);

  useEffect(() => {
    if (data?.walletWithBalance) {
      const newBalanceArray = data?.walletWithBalance?.balance
        ?.map((balanceItem: any) => {
          if (balanceItem.coinType === "Other") {
            // console.log("test 1 pass:", balanceItem.coinType);
            return {
              title: balanceItem.name,
              code: balanceItem.address,
              balance: balanceItem.balance,
              icon: balanceItem.logoUrl,
            };
          } else {
            // console.log("test 2 pass:", balanceItem.coinType);
            return balanceItem.data?.map((item: any) => ({
              title: item.name,
              code: item.address,
              balance: item.balance,
              icon: item.logoUrl,
            }));
          }
        })
        .flat();

      setBalanceArray(newBalanceArray);
      // console.log("Balance array changed:", newBalanceArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.walletWithBalance]);

  //data for sub tag
  const tagsData = [
    {
      title: "Date Created",
      data: data?.createdAt
        ? format(new Date(data?.createdAt), "dd MMM yyyy")
        : "",
    },
    {
      title: "Time",
      data: data?.createdAt ? format(new Date(data?.createdAt), "HH:mm") : "",
    },
    {
      title: "App Version",
      data: data?.appVersion || "",
    },
    {
      title: "Device",
      data: data?.deviceName || "",
    },
    {
      title: "Device History",
      data: "View History",
    },
    {
      title: "New Role",
      data: data?.role ? data.role : "",
    },
    {
      title: "Last Logged in",
      data: data?.lastLogIn && format(data?.lastLogIn, "dd MMM yyyy hh:mm a"),
    },
    {
      title: "Deactivate User",
      data: data?.isActive ? "Active" : "Inactive",
    },
  ];

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue("#000", "#FFF");
  const bgColorSecondary = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "#20342A"
  );
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#262626");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#153116");

  const copyIcon = useColorModeValue(copyIconLight, copyIconDark);

  const editIcon = useColorModeValue(settingeditlight, settingeditdark);

  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [levelDropdownOpen, setLevelDropdownOpen] = useState(false);

  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
    if (!expand) {
      onClick(data?.id);
    } else {
      onClick("");
    }
  };

  const handleRoleDropdownToggle = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const handleAccountDropdownToggle = () => {
    setAccountDropdownOpen(!accountDropdownOpen);
  };

  const handleLevelDropdownToggle = () => {
    setLevelDropdownOpen(!levelDropdownOpen);
  };

  const handleSubmit = () => {
    updateUserRole.mutate({
      userId: data?.id,
      role: userRole,
    });
    updateUser
      ?.mutateAsync({
        userId: data?.id,
        isActive: userStatus,
      })
      .then((result) => {
        toast({
          title: "User updated successfully",
          status: "success",
          duration: 3000,
        });
      })
      .catch((err) => {
        toast({
          title: "User update failed",
          status: "error",
          duration: 3000,
        });
      });
  };

  return (
    <Flex
      // maxWidth={"892px"}
      height={expand ? undefined : "44px"}
      width={"100%"}
      p={"17px 23px 19px 40px"}
      justifyContent={"space-between"}
      bg={bgColor}
      border={expand ? "1px solid #00FFE0" : undefined}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      // onClick={handleExpand}
    >
      {/* when row collapse */}
      {!expand && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex gap={"20px"} alignItems={"center"}>
            <Avatar size={"sm"} name={data?.fullname} src={data?.image} />

            <Text
              width={"224px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.fullname}
            </Text>

            <Text
              width={"294px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.email}
            </Text>

            <Text
              width={"173px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.phone}
            </Text>
          </Flex>

          <Image
            src={editIcon}
            alt="edit"
            width={"20px"}
            height={"20px"}
            cursor={"pointer"}
            onClick={handleExpand}
          />
        </Flex>
      )}

      {/* when row expand */}
      {expand && (
        <Flex direction={"column"} gap={"16px"} width={"100%"}>
          <Flex gap={"20px"} alignItems={"center"}>
            <Avatar size={"sm"} name={data?.fullname} src={data?.image} />

            <Text
              width={"147px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.fullname}
            </Text>

            <Flex gap={"8px"} alignItems={"center"} width={"173px"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.phone}
              </Text>
              <Image
                src={copyIcon}
                alt="copy"
                width={"16px"}
                height={"16px"}
                onClick={() => {
                  navigator.clipboard.writeText(data?.phone).then(() => {
                    toast({
                      title: "Phone number copied",
                      status: "success",
                      duration: 2000,
                    });
                  });
                }}
                cursor={"pointer"}
              />
            </Flex>

            <Flex gap={"8px"} alignItems={"center"} width={"244px"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.email}
              </Text>
              <Image
                src={copyIcon}
                alt="copy"
                width={"16px"}
                height={"16px"}
                onClick={() => {
                  navigator.clipboard.writeText(data?.email).then(() => {
                    toast({
                      title: "Email copied",
                      status: "success",
                      duration: 2000,
                    });
                  });
                }}
                cursor={"pointer"}
              />
            </Flex>

            <Flex
              gap={"8px"}
              alignItems={"center"}
              width={"120px"}
              position={"relative"}
            >
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                Level {data?.kycTier}{" "}
                {data?.kycTier2Status?.toLowerCase() === "pending" && "(TBC)"}
              </Text>
              {/* working */}
              {data?.kycTier2Status?.toLowerCase() === "pending" && (
                <Image
                  src={settingIcon}
                  alt="copy"
                  width={"16px"}
                  height={"16px"}
                  onClick={handleLevelDropdownToggle}
                  cursor={"pointer"}
                />
              )}
              {levelDropdownOpen && (
                <Dropdown
                  isOpen={levelDropdownOpen}
                  onClose={() => setLevelDropdownOpen(false)}
                  options={["Send reminder", "Review KYC"]}
                  positionTop={"16px"}
                />
              )}
            </Flex>
          </Flex>

          <Flex
            gap={"28px"}
            // alignItems={"center"}
            width={"100%"}
          >
            <Flex
              minWidth={"402px"}
              width={"402px"}
              // height={"358px"}
              borderRadius={"10px"}
              border={`0.5px solid #00FFE0`}
              bg={bgColorSecondary}
              // direction={"column"}
              gap={"15px 24px"}
              wrap={"wrap"}
              p={"18px"}
              align="flex-start"
            >
              {balanceArray?.map((item: any, index: number) => (
                <Flex
                  key={index}
                  direction={"column"}
                  gap={"8px"}
                  minW={"145px"}
                  maxW="160px"
                >
                  <Flex alignItems={"center"} gap={"9px"}>
                    {item.icon && (
                      <Image
                        src={item.icon}
                        alt="crypto icon"
                        width={"20px"}
                        height={"20px"}
                      />
                    )}
                    <Text
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"13px"}
                      fontWeight={700}
                      lineHeight={"normal"}
                    >
                      {item.title}
                    </Text>
                  </Flex>

                  {item.code && (
                    <Flex gap={"8px"} alignItems={"center"}>
                      <Text
                        width={"142px"}
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"14px"}
                        fontWeight={500}
                        lineHeight={"normal"}
                      >
                        {item.code?.slice(0, 6) + "***" + item?.code?.slice(-4)}
                      </Text>
                      <Image
                        src={copyIcon}
                        alt="copy"
                        width={"16px"}
                        height={"16px"}
                        onClick={() => {
                          navigator.clipboard.writeText(item.code).then(() => {
                            toast({
                              title: "Address copied",
                              status: "success",
                              duration: 2000,
                              isClosable: true,
                            });
                          });
                        }}
                        cursor={"pointer"}
                      />
                    </Flex>
                  )}

                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"14px"}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    Balance:{" "}
                    <span style={{ fontWeight: 500 }}>
                      {Number(item.balance)?.toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })}
                    </span>
                  </Text>
                </Flex>
              ))}
            </Flex>

            <Flex
              width={"100%"}
              direction={"column"}
              gap={"69px"}
              alignItems={"center"}
              pr={"36px"}
            >
              <Flex
                minWidth={"330px"}
                width={"100%"}
                gap={"14px 30px"}
                wrap={"wrap"}
                justifyContent={"end"}
              >
                {tagsData?.map((item: any, index: number) => (
                  <Flex direction={"column"} gap={"7px"} width={"148px"}>
                    <Tag text={item.title} />

                    {item.title.includes("Role") ? (
                      <Flex
                        alignItems={"center"}
                        gap={"8px"}
                        position={"relative"}
                      >
                        <Text
                          color={textColor}
                          fontFamily={"Plus Jakarta Sans"}
                          fontSize={"12px"}
                          fontWeight={400}
                          lineHeight={"normal"}
                        >
                          {userRole}
                        </Text>

                        <Image
                          width={"12px"}
                          height={"12px"}
                          src={settingIcon}
                          onClick={handleRoleDropdownToggle}
                          cursor={"pointer"}
                        />

                        {roleDropdownOpen && (
                          <Dropdown
                            isOpen={roleDropdownOpen}
                            onClose={() => setRoleDropdownOpen(false)}
                            setSelectedOption={setUserRole}
                            options={["User", "Admin", "Super Admin"]}
                            positionTop={"16px"}
                          />
                        )}
                      </Flex>
                    ) : item.title?.includes("User") ? (
                      <Flex
                        alignItems={"center"}
                        gap={"8px"}
                        position={"relative"}
                      >
                        <Text
                          color={textColor}
                          fontFamily={"Plus Jakarta Sans"}
                          fontSize={"12px"}
                          fontWeight={400}
                          lineHeight={"normal"}
                        >
                          {userStatus ? "Active" : "DeActivited"}
                        </Text>

                        <Image
                          width={"12px"}
                          height={"12px"}
                          src={settingIcon}
                          onClick={handleAccountDropdownToggle}
                          cursor={"pointer"}
                        />
                        {accountDropdownOpen && (
                          <Dropdown
                            isOpen={accountDropdownOpen}
                            setSelectedOption={(option: string) => {
                              if (option === "Active") {
                                setUserStatus(true);
                              } else {
                                setUserStatus(false);
                              }
                            }}
                            onClose={() => setAccountDropdownOpen(false)}
                            options={["Active", "Deactivate"]}
                            positionTop={"16px"}
                          />
                        )}
                      </Flex>
                    ) : (
                      <Text
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"12px"}
                        fontWeight={400}
                        lineHeight={"normal"}
                      >
                        {item.data}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Flex>

              <Flex alignItems={"center"} gap={"20px"} alignSelf={"flex-end"}>
                <SecondaryButton
                  text={"cancel"}
                  bg={btnSecondaryBG}
                  border={"#000"}
                  isDisabled={
                    updateUserRole?.isPending || updateUser?.isPending
                  }
                  onClick={handleExpand}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  isDisabled={
                    updateUserRole?.isPending || updateUser?.isPending
                  }
                  isLoading={updateUserRole?.isPending || updateUser?.isPending}
                  onClick={handleSubmit}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default UserDirectoryCard;
