import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SideBar from "../components/dashboard/sideBar";
import ThemeToggle from "../components/general/ThemeToggle";
import bgLight from "../assets/backgrounds/admin-bg-light.svg";
import bgDark from "../assets/backgrounds/admin-bg-dark.svg";
import { useGetLoggedInUser } from "../utils/auth.api";
import MenuList from "../components/dashboard/MenuList";
import logoLight from "../assets/payremit-logo(s)/payremit-light.svg";
import logoDark from "../assets/payremit-logo(s)/payremit-dark.svg";
import theme from "../theme/theme";
import menuIconLight from "../assets/icons/light/menuIcon.svg";
import menuIconDark from "../assets/icons/dark/menuIcon.svg";

const AdminLayout = ({ children, noSidebar }: any) => {
  const { data: loggedInUser, isLoading } = useGetLoggedInUser();
  //side drawer
  const { isOpen: drawerOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);
  const bgColor = useColorModeValue(theme.colors.light.bg, "#181818");
  const btnbgColor = useColorModeValue("#181818", theme.colors.light.bg);
  const menuIcon = useColorModeValue(menuIconLight, menuIconDark);
  ///

  function getTimeOfDay() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 5 && hours < 12) {
      return "Morning";
    } else if (hours >= 12 && hours < 17) {
      return "Afternoon";
    } else if (hours >= 17 && hours < 21) {
      return "Evening";
    } else {
      return "Night";
    }
  }

  const timeOfDay = getTimeOfDay();

  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isOpen") === "0" ? false : true
  );
  // const borderColor = useColorModeValue("white", "black");
  const textColor = useColorModeValue("#000", "#FFF");
  const mainLogo = useColorModeValue(logoLight, logoDark);
  const bg = useColorModeValue(bgLight, bgDark);

  const handleSideBar = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      localStorage.setItem("isOpen", "0");
    } else localStorage.setItem("isOpen", "1");
  };
  return (
    <Box
      bgImage={bg}
      bgSize="cover"
      bgPosition="center"
      width={"100%"}
      minH={"100vh"}
      maxH={{ md: "100vh" }}
    >
      <Flex
        position={"relative"}
        width={"100%"}
        minH={"100vh"}
        display={{ base: "none", md: "flex" }}
        gap="30px"
      >
        <SideBar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSideBar={handleSideBar}
        />
        <Flex
          direction={"column"}
          // maxWidth={"1350px"}
          width={"100%"}
          maxH="100vh"
          overflowY="scroll"
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            py={"58px"}
            pr={"25px"}
            width={"100%"}
          >
            <Flex direction={"column"} gap={"13px"}>
              <Text
                fontFamily={"Plus Jakarta Sans"}
                color={textColor}
                fontSize={"19px"}
                fontWeight={500}
                lineHeight={"25px"}
              >
                {`Good ${timeOfDay}`}
              </Text>
              <Text
                fontFamily={"Plus Jakarta Sans"}
                color={textColor}
                fontSize={"42px"}
                fontWeight={700}
                lineHeight={"25px"}
              >
                Hey,{" "}
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  loggedInUser?.fullname?.split(" ")?.[0] || "Admin"
                )}
              </Text>
            </Flex>
            <ThemeToggle />
          </Flex>

          <Flex pr={"25px"} pb={"35px"}>
            <Box width={"100%"} height={"100%"}>
              {children}
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        px={"12px"}
        // pb={"35px"}
        display={{ base: "block", md: "none" }}
        position={"relative"}
        width={"100%"}
        minH={"100vh"}
      >
        <Flex
          //  border={"1px solid red"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          py={"24px"}
          // pr={"10px"}
          // pl={{ base: "-30px", md: undefined }}
          width={"100%"}
        >
          <Flex direction={"column"} gap={"13px"}>
            <Text
              fontFamily={"Plus Jakarta Sans"}
              color={textColor}
              fontSize={"19px"}
              fontWeight={500}
              lineHeight={"25px"}
            >
              {`Good ${timeOfDay}`}
            </Text>
            <Text
              fontFamily={"Plus Jakarta Sans"}
              color={textColor}
              fontSize={"24px"}
              fontWeight={700}
              lineHeight={"25px"}
              textTransform="capitalize"
            >
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                loggedInUser?.fullname || "Hey, Admin"
              )}
            </Text>
          </Flex>
          {/* side drawer */}
          <Box>
            <Button
              ref={btnRef}
              colorScheme="teal"
              onClick={onOpen}
              background={btnbgColor}
              textColor={bgColor}
            >
              <Image src={menuIcon} />
            </Button>
            <Drawer
              isOpen={drawerOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent
                style={{ display: "flex", overflowY: "scroll" }}
                background={bgColor}
              >
                <DrawerCloseButton />
                <DrawerHeader>
                  <Flex justify={"center"} align={"center"}>
                    <Image alt="logo" src={mainLogo} height={50} />
                  </Flex>
                </DrawerHeader>

                <DrawerBody>
                  {" "}
                  <ThemeToggle ml={"35%"} />
                  <MenuList
                    isOpen={true}
                    setIsActive={() => {}}
                    isActive={false}
                    user={loggedInUser}
                  />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
        <Box width={"100%"} height={"100%"} px={"10px"}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default AdminLayout;
