import React, { useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();

  const { data: user, isLoading: userLoading } = useGetLoggedInUser();

  useEffect(() => {
    if (!userLoading && !user?.permissions?.support) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AdminLayout>
      <iframe
        src="https://desk.zoho.com/"
        style={{ width: "100%", height: "100vh" }}
        title="Iframe Example"
      ></iframe>
    </AdminLayout>
  );
};

export default Support;
