import { useColorModeValue, Input } from "@chakra-ui/react";
import React from "react";

const CustomInput = ({
  key,
  placeholder,
  handleText,
  width,
  value,
  onChange,
  px,
}: any) => {
  const textColor = useColorModeValue("#000", "#FFF");
  const placeholderColor = useColorModeValue(
    "rgba(0, 0, 0, 0.5)",
    "rgba(255, 255, 255, 0.5)"
  );

  const bgColor = useColorModeValue("#E9E9E9", "#535353");

  return (
    <Input
      placeholder={placeholder}
      borderRadius={"15px"}
      px={px || "auto"}
      value={value}
      onChange={onChange}
      // onChange={(e) => handleText(e.target.value)}
      border="none"
      _focus={{ outline: "none" }}
      _placeholder={{ color: placeholderColor }}
      color={textColor}
      bg={bgColor}
      height={"22px"}
      textColor={textColor}
    />
  );
};

export default CustomInput;
