import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import lockLight from "../../assets/icons/light/lockIcon.svg";
import eyeLight from "../../assets/icons/light/eyeIcon.svg";
import shutEyeLight from "../../assets/icons/light/shutEyeIcon.svg";
import lockDark from "../../assets/icons/dark/lock.svg";
import eyeDark from "../../assets/icons/dark/eye.svg";
import shutEyeDark from "../../assets/icons/dark/shutEye.svg";
import arrow1 from "../../assets/icons/light/backArrow.svg";
import arrow2 from "../../assets/icons/dark/backArrow.svg";
import AuthCardHeader from "../../components/auth/AuthCardHeader";
import UserInfoInput from "../../components/form-components/UserInfoInput";
import PrimaryButton from "../../components/form-components/PrimaryButton";
import AuthLayout from "../../layouts/AuthLayout";
import { useResetPassword } from "../../utils/auth.api";

const AddNewPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = formData;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { mutateAsync: resetPasswordMutation } = useResetPassword();

  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => navigate(-1)}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Enter your new password"
        des="Set your new password to login into your account"
        desColor={useColorModeValue("#000", "#FFF")}
        widthHeading={"350px"}
        widthSubheading={"300px"}
        headingSize={"43px"}
        subheadingSize={"23px"}
        subheadingWeight={400}
        textalign={"center"}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (password !== confirmPassword) {
            toast({
              title: "Passwords do not match.",
              status: "error",
            });
            return;
          }
          try {
            await resetPasswordMutation({ password, confirmPassword });
            navigate("/auth/password-updated");
            toast({
              title: "Password updated.",
              status: "success",
            });
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
          }
        }}
      >
        <Flex gap={"24px"} direction={"column"}>
          <UserInfoInput
            type={show ? "text" : "password"}
            placeholder="Add new password"
            icon={lockIcon}
            rightIcon={!show ? eyeIcon : shutEyeIcon}
            setShow={setShow}
            show={show}
            name="password"
            value={password}
            onChange={(e: any) => handleChange(e)}
          />
          <UserInfoInput
            type={showConfirm ? "text" : "password"}
            placeholder="Confirm Password"
            icon={lockIcon}
            rightIcon={!showConfirm ? eyeIcon : shutEyeIcon}
            setShow={setShowConfirm}
            show={showConfirm}
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e: any) => handleChange(e)}
            border={password !== confirmPassword && confirmPassword && "red"}
          />
          {password !== confirmPassword && confirmPassword && (
            <Text color={"red"} mt={"-14px"}>
              Password didn't match with the first one.
            </Text>
          )}
        </Flex>

        <Box minH="100px" />
        <PrimaryButton type="submit" text="Update Password" />
      </form>
    </AuthLayout>
  );
};

export default AddNewPassword;
