import { useMutation } from "@tanstack/react-query";
import { client } from "./api.client";

export const useExportData = () =>
  useMutation<any, Error, any>({
    mutationFn: async ({
      type,
      period,
      downloadType,
      coinId,
      userId,
      transactionalType,
      searchType,
      search,
      status,
    }: any) => {
      // construct the base URL
      let url = `v3/wallet/csvOrPdfDownload?type=${type}&period=${period}&downloadType=${downloadType}`;

      if (coinId) {
        url += `&coinId=${coinId}`;
      }
      if (userId) {
        url += `&userId=${userId}`;
      }
      if (transactionalType) {
        url += `&transactionalType=${transactionalType}`;
      }
      if (searchType) {
        url += `&searchType=${searchType}`;
      }
      if (search) {
        url += `&search=${search}`;
      }
      if (status) {
        url += `&status=${status}`;
      }

      return await client(url);
    },
  });
