import { Flex, Image, Input, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import filterdark from "../../assets/icons/dark/filter-icon.svg";
import filterlight from "../../assets/icons/light/filter-icon.svg";
import searchdark from "../../assets/icons/dark/search-icon.svg";
import searchlight from "../../assets/icons/light/search-icon.svg";
import settingIcon from "../../assets/icons/general/setting.svg";
import Dropdown from "../form-components/dropdown";

const SearchbarKyc = ({
  onClick,
  onChangeKyc,
  onChangeUser,
  onFilterChangeKyc,
  onFilterChangeUser,
}: any) => {
  const editIcon = useColorModeValue(settingeditlight, settingeditdark);
  const filterIcon = useColorModeValue(filterlight, filterdark);
  const searchIcon = useColorModeValue(searchlight, searchdark);

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue(" #808080", "#808080");
  const bgColor2 = useColorModeValue(
    "rgba(223, 223, 223, 0.4)",
    " rgba(42, 42, 42, 0.4)"
  );

  const [expand] = useState(true);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expandedSearch, setExpandedSearch] = useState("directory");
  const [searchTermUser, setSearchTermUser] = useState("");
  const [searchTermKyc, setSearchTermKyc] = useState("");

  const [searchDropdownOpenUser, setSearchDropdownOpenUser] = useState(false);
  const [searchDropdownOpenKyc, setSearchDropdownOpenKyc] = useState(false);

  const handleSearchClick = (searchType: string) => {
    if (expandedSearch === searchType) {
      setExpandedSearch(searchType);
      onClick(searchType);
      if (searchType === "directory") {
      }
    } else {
      setExpandedSearch(searchType);
      onClick(searchType);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleInputChangeUser = (e: any) => {
    setSearchTermUser(e.target.value);
  };

  const handleInputChangeKyc = (e: any) => {
    setSearchTermKyc(e.target.value);
  };

  const handleFilterSelectionUser = (filter: string, value: string) => {
    onFilterChangeUser(filter, value);

    //  setFilterSelected(true);
  };

  const handleFilterSelectionKyc = (filter: string, value: string) => {
    onFilterChangeKyc(filter, value);

    //  setFilterSelected(true);
  };

  const handleSearchDropdownToggleUser = () => {
    if (expandedSearch === "directory")
      setSearchDropdownOpenUser(!searchDropdownOpenUser);
  };
  const handleSearchDropdownToggleKyc = () => {
    if (expandedSearch !== "directory")
      setSearchDropdownOpenKyc(!searchDropdownOpenKyc);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      onChangeUser(searchTermUser);
    }, 700); // adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermUser]);

  useEffect(() => {
    const handler = setTimeout(() => {
      onChangeKyc(searchTermKyc);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermKyc]);

  return (
    <Flex width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
      <Flex alignItems={"center"}>
        {/* search 1 user directory*/}
        <Flex
          cursor={"pointer"}
          //onClick={onClick}
          alignItems={"center"}
          bg={expandedSearch === "directory" ? bgColor : bgColor2}
          width={expand ? "340px" : "335px"}
          height={"49px"}
          borderRadius={
            "var(--Timer-seconds, 20px) var(--Timer-seconds, 0px) var(--Spacing-spacing-none, 0px) var(--Spacing-spacing-none, 0px)"
          }
          justifyContent={"space-between"}
          p={"17px 22px"}
          onClick={() => handleSearchClick("directory")}
        >
          <Image
            src={searchIcon}
            alt="edit"
            width={"16px"}
            height={"16px"}
            mr={"5px"}
          />
          {expandedSearch === "directory" && (
            <Input
              //width={"402px"}
              height={"29px"}
              placeholder="User Directory"
              mr={"5px"}
              onChange={handleInputChangeUser}
            />
          )}

          {!(expandedSearch === "directory") && (
            <Text
              width={"full"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontStyle={"normal"}
              fontWeight={500}
              lineHeight={"25px"}
              ml={"5px"}
            >
              User Directory
            </Text>
          )}
          <Flex alignItems={"center"} gap={"10px"} position={"relative"}>
            <Image
              src={editIcon}
              alt="edit"
              width={"16px"}
              height={"16px"}
              cursor={"pointer"}
              onClick={handleSearchDropdownToggleUser}
            />
            {searchDropdownOpenUser && (
              <Dropdown
                isOpen={searchDropdownOpenUser}
                onClose={() => setSearchDropdownOpenUser(false)}
                options={[
                  "Date Created",
                  "Device",
                  "App Version",
                  "User email",
                  "Total Transactions",
                  "Location",
                  "Phone number",
                  "First name",
                  "Last name",
                  "User level",
                  "Access level",
                  "IP Address",
                  "Referral code",
                ]}
                kycSearch
                positionTop={"16px"}
                positionLeft={"8px"}
                onSelect={handleFilterSelectionUser}
                setSelectedOption={() => {}}
              />
            )}
            <Image
              src={filterIcon}
              alt="filter"
              width={"16px"}
              height={"16px"}
            />
          </Flex>
        </Flex>

        {/* search 2 kyc */}
        <Flex
          cursor={"pointer"}
          alignItems={"center"}
          bg={expandedSearch === "kyc" ? bgColor : bgColor2}
          width={expand ? "340px" : "335px"}
          height={"49px"}
          borderRadius={
            "var(--Timer-seconds, 0px) var(--Timer-seconds, 20px) var(--Spacing-spacing-none, 0px) var(--Spacing-spacing-none, 0px)"
          }
          justifyContent={"space-between"}
          p={"17px 22px"}
          onClick={() => handleSearchClick("kyc")}
        >
          <Image
            src={searchIcon}
            alt="edit"
            width={"16px"}
            height={"16px"}
            mr={"5px"}
          />
          {expandedSearch === "kyc" && (
            <Input
              // width={"402px"}
              height={"29px"}
              placeholder="KYC Directory"
              mr={"5px"}
              onChange={handleInputChangeKyc}
            />
          )}
          {!(expandedSearch === "kyc") && (
            <Text
              width={"full"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontStyle={"normal"}
              fontWeight={500}
              lineHeight={"25px"}
              ml={"5px"}
            >
              KYC Directory
            </Text>
          )}
          <Flex alignItems={"center"} gap={"10px"} position={"relative"}>
            <Image
              src={editIcon}
              alt="edit"
              width={"16px"}
              height={"16px"}
              cursor={"pointer"}
              onClick={handleSearchDropdownToggleKyc}
            />
            {searchDropdownOpenKyc && (
              <Dropdown
                isOpen={searchDropdownOpenKyc}
                onClose={() => setSearchDropdownOpenKyc(false)}
                options={[
                  "Date Created",
                  "Device",
                  "App Version",
                  "User email",
                  "Total Transactions",
                  "Location",
                  "Phone number",
                  "First name",
                  "Last name",
                  "User level",
                  "Access level",
                  "IP Address",
                  "Referral code",
                ]}
                kycSearch
                positionTop={"16px"}
                positionLeft={"8px"}
                onSelect={handleFilterSelectionKyc}
                setSelectedOption={() => {}}
              />
            )}

            <Image
              src={filterIcon}
              alt="filter"
              width={"16px"}
              height={"16px"}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        alignItems={"center"}
        bg={bgColor}
        width={"152px"}
        height={"49px"}
        borderRadius={
          "var(--Timer-seconds, 20px) var(--Timer-seconds, 20px) var(--Spacing-spacing-none, 0px) var(--Spacing-spacing-none, 0px)"
        }
        justifyContent={"space-between"}
        p={"17px 22px"}
        position={"relative"}
      >
        <Text
          fontFamily={"Plus Jakarta Sans"}
          fontSize={"15px"}
          fontWeight={500}
          lineHeight={"25px"}
        >
          Export
        </Text>
        <Image
          src={settingIcon}
          alt="filter"
          width={"16px"}
          height={"16px"}
          onClick={handleDropdownToggle}
          cursor="pointer"
        />
        {dropdownOpen && (
          <Dropdown
            isOpen={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
            sub
            left
            type={"users"}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default SearchbarKyc;
