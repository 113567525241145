import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import emailLight from "../../assets/icons/light/emailIcon.svg";
import emailLightActive from "../../assets/icons/light/emailActive.svg";
import lockLight from "../../assets/icons/light/lockIcon.svg";
import lockActive from "../../assets/icons/light/lockActive.svg";
import eyeLight from "../../assets/icons/light/eyeIcon.svg";
import eyeActive from "../../assets/icons/light/eyeActive.svg";
import shutEyeLight from "../../assets/icons/light/shutEyeIcon.svg";
import shutEyeLightActive from "../../assets/icons/light/shutEyeActive.svg";
import emailDark from "../../assets/icons/dark/email.svg";
import lockDark from "../../assets/icons/dark/lock.svg";
import eyeDark from "../../assets/icons/dark/eye.svg";
import shutEyeDark from "../../assets/icons/dark/shutEye.svg";
import theme from "../../theme/theme";
import AuthLayout from "../../layouts/AuthLayout";
import AuthCardHeader from "../../components/auth/AuthCardHeader";
import UserInfoInput from "../../components/form-components/UserInfoInput";
import PrimaryButton from "../../components/form-components/PrimaryButton";
import CustomModal from "../../components/form-components/CustomModal";
import { useLogin, useVerifyLogin2FA } from "../../utils/auth.api";
import { useQueryClient } from "@tanstack/react-query";

const Signin = () => {
  const queryClient = useQueryClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [pinInput, setPinInput] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [is2FA, setIs2FA] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { email, password } = formData;
  console.log("is2FA", is2FA);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const emailIcon = useColorModeValue(emailLight, emailDark);
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const activeEyeIcon = useColorModeValue(eyeActive, eyeDark);
  const activeShutEyeIcon = useColorModeValue(shutEyeLightActive, shutEyeDark);
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);

  const { mutate: userLogin, isPending: isLoginLoading } = useLogin();
  const { mutateAsync: verify2FA, isPending: isVerifyLoading } =
    useVerifyLogin2FA();

  const callLogin = (token: any) => {
    localStorage.setItem("token", token);
    queryClient.invalidateQueries({ queryKey: ["token"] });
    toast({
      title: "Logged in successfully!!!",
      status: "success",
      duration: 1500,
    });
    navigate("/home");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    userLogin(
      { email, password },
      {
        onSuccess: (res) => {
          if (res?.user?.is2FAEnabled) {
            setAccessToken(res.token.access_token);
            setIs2FA(true);
            onOpen();
          } else {
            callLogin(res?.token?.access_token);
          }
        },
        onError: (err: any) => {
          setLoginError(err.message);
          toast({ title: err.message, status: "error" });
        },
      }
    );
  };

  const handle2FASubmit = async () => {
    try {
      const res: any = await verify2FA({ code: pinInput, token: accessToken });
      if (res?.isValid) {
        callLogin(accessToken);
      } else {
        toast({
          title: "Wrong OTP, Verify again",
          status: "error",
          duration: 1500,
        });
      }
    } catch (err: any) {
      toast({
        title: err.message,
        status: "error",
        duration: 1500,
      });
    }
  };

  return (
    <AuthLayout>
      <AuthCardHeader
        title="Sign in"
        des="Login to resume your journey with Payremit."
      />
      <form onSubmit={handleSubmit}>
        <Flex gap={"24px"} direction={"column"}>
          <Box>
            <UserInfoInput
              type="email"
              placeholder="Email"
              darkBG="transparent"
              icon={emailIcon}
              activeIcon={useColorModeValue(emailLightActive, emailDark)}
              name="email"
              value={email}
              onChange={handleChange}
              border={!emailPattern.test(email) && email && "red"}
            />
            {!emailPattern.test(email) && email && (
              <Text mt="10px" color={"red"}>
                Enter correct email{" "}
              </Text>
            )}
          </Box>

          <Box>
            <UserInfoInput
              type={show ? "text" : "password"}
              placeholder="Password"
              darkBG="transparent"
              icon={lockIcon}
              activeIcon={useColorModeValue(lockActive, lockDark)}
              rightIcon={!show ? eyeIcon : shutEyeIcon}
              activeRightIcon={!show ? activeEyeIcon : activeShutEyeIcon}
              setShow={setShow}
              show={show}
              name="password"
              value={password}
              onChange={handleChange}
              border={loginError && "red"}
            />
            {loginError && (
              <Text mt="10px" color={"red"}>
                {loginError}. Try again{" "}
              </Text>
            )}
          </Box>
        </Flex>

        <Box textAlign={"right"} mt={"12px"}>
          <ChakraLink
            as={ReactRouterLink}
            to="/auth/forgot-password"
            color={useColorModeValue("#00332D", "#00FFE0")}
            fontWeight={600}
            fontSize={"12px"}
          >
            Forgot Password?
          </ChakraLink>
        </Box>
        <PrimaryButton
          mt="130px"
          type="submit"
          text={isLoginLoading ? <Spinner /> : "Login"}
          disabled={isLoginLoading}
        />
      </form>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Verify 2FA"
        onSubmit={handle2FASubmit}
        isDisabled={isVerifyLoading}
        isLoading={isVerifyLoading}
        footerText={"Verify Now"}
        closeText={"Cancel"}
      >
        <Flex
          my={"32px"}
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={"16px"}
          alignSelf={"stretch"}
        >
          <Heading
            color={color}
            fontFamily={"Audiowide"}
            fontSize={"20px"}
            fontWeight={"400"}
            textAlign="center"
          >
            Verify Two Factor Authentication
          </Heading>
          <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
            <PinInput
              errorBorderColor="red.300"
              focusBorderColor="teal.300"
              size={"lg"}
              variant={"flushed"}
              placeholder="0"
              value={pinInput}
              isInvalid={pinInput.length !== 6}
              onChange={(value) => setPinInput(value)}
              autoFocus
            >
              <PinInputField fontWeight={700} />
              <PinInputField fontWeight={700} />
              <PinInputField fontWeight={700} />
              <PinInputField fontWeight={700} />
              <PinInputField fontWeight={700} />
              <PinInputField fontWeight={700} />
            </PinInput>
          </Flex>
          <Text
            color={paraColor2}
            fontFamily={"Manrope"}
            fontWeight={500}
            fontSize={"14px"}
          >
            Enter the 6-digit code
          </Text>
        </Flex>
      </CustomModal>
    </AuthLayout>
  );
};

export default Signin;
