/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint @typescript-eslint/restrict-template-expressions: off */
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";
import {
  type UserTransaction,
  type CoinType,
  type UserStats,
  type ListedUser,
  type User,
  type UpdateUserEmail,
  type UpdateUserPassword,
  type Airtime,
  type InternetD,
  type ElectricityD,
  type TVSubs,
  type MarketData,
} from "./types/user.type";

export const useGetLoggedInUser = () =>
  useQuery<User, Error>({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => await client(`auth/getLoggedInUser`),
  });

export const useGetTransactions = (
  status: "Completed" | "Pending" | "Rejected",
  type?: "Deposit" | "Swap" | "Withdraw" | "Withdraw Fiat" | "Deposit Fiat",
  toShow?: number
) =>
  useQuery<UserTransaction[], Error>({
    queryKey: ["getTransactions", status, type],
    queryFn: async () =>
      await client(
        `wallet/getTransactionsByAdmin${
          toShow ? `?limit=${toShow}` : `?limit={50}`
        }&offset=0&status=${status}${type ? `&type=${type}` : ""}`
      ),
  });

export const useCsvTransactionsByAdmin = (
  status: "Completed" | "Pending" | "Rejected",
  type?: "Deposit" | "Swap" | "Withdraw" | "Withdraw Fiat",
  toShow?: number,
  fromDate?: string,
  toDate?: string
) =>
  useQuery<UserTransaction[], Error>({
    queryKey: [
      "CsvTransactionsByAdmin",
      status,
      type,
      toShow,
      fromDate,
      toDate,
    ],
    queryFn: async () => {
      const queryString = `wallet/CsvTransactionsByAdmin?limit=${
        Number.MAX_SAFE_INTEGER
      }&offset=0&status=${status}${
        fromDate ? `&fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}${
        type ? `&type=${type}` : ""
      }`;

      return await client(queryString);
    },
  });

export const useGetStats = () =>
  useQuery<UserStats, Error>({
    queryKey: ["getStats"],
    queryFn: async () => await client(`wallet/getStats`),
  });

export const useGetUserList = (
  isAdmin?: boolean,
  toShow?: number,
  email?: string
) =>
  useQuery<ListedUser[], Error>({
    queryKey: ["getUserList"],
    queryFn: async () =>
      await client(
        `auth/getAllUsers${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }${isAdmin ? "&filterOnlyAdmins=true" : ""}${
          email ? `&email=${email}` : ""
        }`
      ),
  });

export const useCsvUsers = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvUsers"],
    queryFn: async () => {
      const queryString = `auth/CsvUsers?limit=${
        Number.MAX_SAFE_INTEGER
      }&offset=${0}${fromDate ? `&fromDate=${fromDate.toString()}` : ""}${
        toDate ? `&toDate=${toDate.toString()}` : ""
      }`;

      return await client(queryString);
    },
  });

export const useGetRecentTransactions = (limit?: number, offset?: number) =>
  useQuery<any, Error>({
    queryKey: ["getRecentTransactions", limit, offset],
    queryFn: async () => {
      const queryString = `wallet/getRecentTransactions?limit=${
        limit ?? Number.MAX_SAFE_INTEGER
      }&offset=${offset ?? 0}`;
      return await client(queryString);
    },
  });

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      userId: string;
      isActive: boolean;
      phone?: string;
      fullname?: string;
      email?: string;
      kycTier?: string;
      bvn?: string;
    }
  >({
    mutationFn: async (data) => await client(`auth/updateUserActive`, { data }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getUserList"],
      });
    },
  });
};

export const useGetCoins = () =>
  useQuery<CoinType[], Error>({
    queryKey: ["getCoins"],
    queryFn: async () => await client(`coins/getCoins`),
  });

export const useGetCoinsMarketData = () =>
  useQuery<MarketData[], Error>({
    queryKey: ["getCoinsMarketData"],
    queryFn: async () => await client(`coins/getCoinsMarketData`),
  });

export const useCsvGetCoinsMarketData = (fromDate?: string, toDate?: string) =>
  useQuery<MarketData[], Error>({
    queryKey: ["CsvGetCoinsMarketData", fromDate, toDate],
    queryFn: async () =>
      await client(
        `coins/CsvGetCoinsMarketData${fromDate ? `?fromDate=${fromDate}` : ""}${
          toDate ? `${fromDate ? "&" : "?"}toDate=${toDate}` : ""
        }`
      ),
  });

export const useGetDepositWithdrawAnalysis = () =>
  useQuery<any, Error>({
    queryKey: ["getDepositWithdrawAnalysis"],
    queryFn: async () => await client(`coins/getDepositWithdrawAnalysis`),
  });

export const useUpdateCoinPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { price?: number; priceSell?: number; coinId: string }
  >({
    mutationFn: async (data) => await client(`coins/updatePrice`, { data }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["useGetCryptoRateStats"],
      });
    },
  });
};

export const useUpdateEmail = () =>
  useMutation<unknown, Error, UpdateUserEmail>({
    mutationFn: async (data) => await client(`auth/changeEmail`, { data }),
  });

export const useUpdatePassword = () =>
  useMutation<unknown, Error, UpdateUserPassword>({
    mutationFn: async (data) => await client(`auth/changePassword`, { data }),
  });

export const useUpdateUserRole = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { userId: string; role: "User" | "Admin" | string }
  >({
    mutationFn: async (data) => await client(`auth/updateUserRole`, { data }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getUserList"],
      });
    },
  });
};

// export const useUpdateTransaction = () => {
//   const queryClient = useQueryClient();
//   return useMutation<unknown, Error, { transactionId: string; status: string }>(
//     async (data) => await client(`wallet/updateTransaction`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getTransactions", "Pending"]);
//       },
//     }
//   );
// };

export const useCsvCoins = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvCoins"],
    queryFn: async () => {
      const queryString = `coins/CsvCoins?limit=${900}&offset=${0}${
        fromDate ? `&fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

      return await client(queryString);
    },
  });

export const useVerifyAndUpgradeKYC3ByAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    { UserId: string; kycTier: string; status: string }
  >({
    mutationFn: async (data) =>
      await client(`auth/verifyAndUpgradeKYC3ByAdmin`, { data }),

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getKyc3UsersVerifyRecords"],
      });
    },
  });
};

export const useGetKycHistory = (status: string) =>
  useQuery<any[], Error>({
    queryKey: ["getKycHistory", status],
    queryFn: async () => await client(`auth/getKycHistory?status=${status}`),
  });

export const useGetKyc3UsersVerifyRecords = ({
  searchTypeKyc,
  searchKyc,
  offsetKyc,
  limitKyc,
  periodKyc,
}: {
  searchTypeKyc?: string;
  searchKyc?: string;
  limitKyc?: number;
  offsetKyc?: number;
  periodKyc: string;
}) =>
  useQuery<any>({
    queryKey: [
      "getKyc3UsersVerifyRecords",
      searchTypeKyc,
      searchKyc,
      limitKyc,
      offsetKyc,
      periodKyc,
    ],
    queryFn: async () => {
      const params = new URLSearchParams({
        period: periodKyc,
      });

      if (searchTypeKyc && searchKyc)
        params.append("searchType", searchTypeKyc);
      if (searchKyc) params.append("search", searchKyc);
      if (limitKyc) params.append("limit", String(limitKyc));
      params.append("offset", String(offsetKyc));

      const queryString = params.toString();
      return await client(`v3/auth/getKyc3UsersVerifyRecords?${queryString}`);
    },
  });

export const useEnable2Fa = () =>
  useQuery<any, Error>({
    queryKey: ["enable2FA"],
    queryFn: async () => await client(`auth/enable2FA`),
  });

export const useEnable2FaOtp = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>({
    mutationFn: async (otp: string) =>
      await client(`auth/enable2FA/${otp}`, { method: "POST" }),

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getLoggedInUser"],
      });
    },
  });
};

// export const useEnable2FaOtp = (otp: string) =>
//   useQuery<unknown, Error>(
//     ["enable2Fa", otp],
//     async () => await client(`auth/enable2Fa/${otp}`)
//   );

export const useGetCompletedAirtime = (toShow: number) =>
  useQuery<Airtime[], Error>({
    queryKey: ["getCompletedAirtime", toShow],
    queryFn: async () =>
      await client(
        `service/getCompletedAirtime${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      ),
  });

export const useGetCompletedInternet = (toShow: number) =>
  useQuery<InternetD[], Error>({
    queryKey: ["getCompletedInternet", toShow],
    queryFn: async () =>
      await client(
        `service/getCompletedInternet${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      ),
  });

export const useGetCompletedElectricityBill = (toShow: number) =>
  useQuery<ElectricityD[], Error>({
    queryKey: ["getCompletedElectricityBill", toShow],
    queryFn: async () =>
      await client(
        `service/getCompletedElectricityBill${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      ),
  });

export const useGetCompletedvSubscription = (toShow: number) =>
  useQuery<TVSubs[], Error>({
    queryKey: ["getCompeletedvSubscription", toShow],
    queryFn: async () =>
      await client(
        `service/getCompeletedtvSubscription${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      ),
  });

export const useGetCoinsHistoryOneYear = () =>
  useQuery<any[], Error>({
    queryKey: ["getCoinsHistoryOneYear"],
    queryFn: async () => await client(`coins/getCoinsHistoryOneYear`),
  });

export const useGetTradingHistory = () =>
  useQuery<any[], Error>({
    queryKey: ["getTradingHistory"],
    queryFn: async () => await client(`coins/getTradingHistory`),
  });

export const useCsvGetTradingHistory = (fromDate?: string, toDate?: string) =>
  useQuery<any[], Error>({
    queryKey: ["CsvGetTradingHistory", fromDate, toDate],
    queryFn: async () =>
      await client(
        `coins/CsvGetTradingHistory${fromDate ? `?fromDate=${fromDate}` : ""}${
          toDate ? `${fromDate ? "&" : "?"}toDate=${toDate}` : ""
        }`
      ),
  });

export const useUploadFile = () => {
  return useMutation<unknown, Error, any>({
    mutationFn: async (data: any) => {
      console.log(data);
      return await client(`media-upload/mediaFiles/images`, { data });
    },
  });
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, unknown>({
    mutationFn: async (data: any) =>
      await client(`auth/updateProfile`, { data }),

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getLoggedInUser"],
      });
    },
  });
};

export const useCsvAirtimeDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvAirtimeDownload"],
    queryFn: async () => {
      const queryString = `service/CsvAirtimeDownload${
        fromDate ? `?fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

      return await client(queryString);
    },
  });

export const useCsvInternetDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvInternetDownload"],
    queryFn: async () => {
      const queryString = `service/CsvInternetDownload${
        fromDate ? `?fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

      return await client(queryString);
    },
  });

export const useCsvElectricityDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvElectricityDownload"],
    queryFn: async () => {
      const queryString = `service/CsvElectricityDownload${
        fromDate ? `?fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

      return await client(queryString);
    },
  });

export const useCsvTvSubscriptionDownload = (
  fromDate: string,
  toDate: string
) =>
  useQuery<unknown, Error>({
    queryKey: ["CsvTvSubscriptionDownload"],
    queryFn: async () => {
      const queryString = `service/CsvTvSubscriptionDownload${
        fromDate ? `?fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

      return await client(queryString);
    },
  });

export const useGetBankList = () =>
  useQuery<any, Error>({
    queryKey: ["getListOfNigerianBank"],
    queryFn: async () => await client(`service/getListOfNigerianBank`),
  });

export const useGetBankAccountDetails = () =>
  useQuery<any, Error>({
    queryKey: ["getAccountDetails"],
    queryFn: async () => await client(`service/getAccountdetails`),
  });

export const useUpdateBankAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, unknown>({
    mutationFn: async (data: any) =>
      await client(`service/updateCollectionAccount?accountId=${data?.id}`, {
        data: data?.payload,
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getAccountDetails"],
      });
    },
  });
};

// v3

export const useGetAllUserList = ({
  searchTypeUser,
  searchUser,
  limit,
  offset,
  periodUser,
}: {
  searchTypeUser?: string;
  searchUser?: string;
  limit?: number;
  offset?: number;
  periodUser: string;
}) =>
  useQuery<any>({
    queryKey: [
      "getAllUserList",
      searchTypeUser,
      searchUser,
      limit,
      offset,
      periodUser,
    ],
    queryFn: async () => {
      const params = new URLSearchParams({
        period: periodUser,
      });

      if (searchTypeUser && searchUser)
        params.append("searchType", searchTypeUser);
      if (searchUser) params.append("search", searchUser);
      if (limit) params.append("limit", String(limit));
      params.append("offset", String(offset));

      const queryString = params.toString();
      return await client(`v3/wallet/getUsers?${queryString}`);
    },
  });

export const useGetUsersStats = (period: string) =>
  useQuery<any, Error>({
    queryKey: ["useGetUsersStats", period],
    queryFn: async () =>
      await client(`v3/wallet/getUserStats?period=${period}`),
  });

export const useGetUserWalletBalance = (userId: string) =>
  useQuery<any, Error>({
    queryKey: ["useGetUserWalletBalance", userId],
    queryFn: async () =>
      await client(`wallet/getUserWalletWithBalance/${userId}`),
  });

interface UserActivitiesParams {
  userId: string;
  limit: number;
  offset: number;
}

export const useGetUserActivities = ({
  userId,
  limit,
  offset,
}: UserActivitiesParams) =>
  useQuery<any, Error>({
    queryKey: ["useGetUserActivities", userId, limit, offset],
    queryFn: async () => {
      const url = `v3/wallet/getUserActivitiesById/${userId}?limit=${limit}&offset=${offset}`;
      const response = await client(url);
      return response;
    },
    enabled: !!userId,
  });

export const useGetUserDataGraph = ({ period }: { period: string }) =>
  useQuery<any>({
    queryKey: ["GetUserDataGraph", period],
    queryFn: async () =>
      await client(`v3/wallet/getUserGraphData?period=${period}`),
  });
