import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import React, { useEffect, useState } from "react";
import CustomInputForm from "./form-data/custom-input";
import SecondaryButton from "../form-components/secondary-button";
import {
  useGetLoggedInUser,
  useUpdateReferralCodeByUserId,
  useUpdateReferralPrice,
} from "../../utils/auth.api";

const CardHeading = ({ padding }: any) => {
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");

  const bgModal = useColorModeValue("#E4E4E4", "#000");
  const editIcon = useColorModeValue(settingeditlight, settingeditdark);

  const {
    isOpen: isChangeModalOpen,
    onOpen: onChangeModalOpen,
    onClose: onChangeModalClose,
  } = useDisclosure();
  const toast = useToast();

  const { data: user } = useGetLoggedInUser();
  const updateReferralPrice = useUpdateReferralPrice();
  const updateReferralCode = useUpdateReferralCodeByUserId();

  const [referralPrice, setReferralPrice] = useState({
    currentPrice: "",
    RefferalPrice: "",
  });
  const [referralCode, setReferralCode] = useState("");

  const handleUpdateReferralPrice = () => {
    if (!referralPrice.RefferalPrice) {
      toast({
        title: "Referral Price must be greater than 0",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateReferralPrice
      .mutateAsync({
        RefferalPrice: referralPrice.RefferalPrice,
      })
      .then((result) => {
        toast({
          title: "Referral Price has been updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onChangeModalClose();
        setReferralPrice({
          ...referralPrice,
          RefferalPrice: "",
        });
      })
      .catch((err) => {
        toast({
          title: err?.message || "Failed to update Referral Price",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleUpdateReferralCode = () => {
    if (!referralCode) {
      toast({
        title: "Referral Code must not be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateReferralCode
      .mutateAsync({
        UserId: user?.id,
        RefferalCode: referralCode,
      })
      .then((result) => {
        toast({
          title: "Referral Code has been updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onChangeModalClose();
        setReferralCode("");
      })
      .catch((err) => {
        toast({
          title: err?.message || "Failed to update Referral Code",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    setReferralPrice({
      ...referralPrice,
      currentPrice: user?.referralPrice?.toString() || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Flex
        cursor={"pointer"}
        // maxWidth={"892px"}
        height={"44px"}
        width={"100%"}
        p={padding ? padding : "17px 23px 19px 40px"}
        justifyContent={"space-between"}
        bg={bgColor}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          {!padding && (
            <>
              <Flex gap={"9px"} alignItems={"center"}>
                <Box
                  borderRadius={"32px"}
                  width={"32px"}
                  height={"32px"}
                  bg={"#00FFE0"}
                  mr={"11px"}
                />

                <Text
                  width={"150px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  User Name
                </Text>

                <Text
                  width={"125px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  Total Earned
                </Text>

                <Text
                  width={"125px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  Referral Balance
                </Text>

                <Text
                  width={"125px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  Referral Code
                </Text>

                <Text
                  width={"110px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  No of Referrals
                </Text>

                <Text
                  width={"110px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  Referral Price (N)
                </Text>
              </Flex>
              {(user?.isSuperAdmin ||
                user?.permissions?.createCustomCodeChange) && (
                <Image
                  src={editIcon}
                  alt="edit"
                  width={"20px"}
                  height={"20px"}
                  cursor={"pointer"}
                  onClick={onChangeModalOpen}
                />
              )}
            </>
          )}

          {padding && (
            <Flex gap={"9px"} alignItems={"center"}>
              <Box
                borderRadius={"32px"}
                width={"32px"}
                height={"32px"}
                bg={"#00FFE0"}
                mr={"11px"}
              />

              <Text
                width={"150px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={700}
                lineHeight={"normal"}
              >
                Name
              </Text>

              <Text
                width={"175px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={700}
                lineHeight={"normal"}
              >
                Email
              </Text>

              <Text
                width={"125px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={700}
                lineHeight={"normal"}
              >
                Referral Code
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      {/* update reff, and add custom reff */}
      <Modal
        isOpen={isChangeModalOpen}
        onClose={() => {
          setReferralPrice({
            ...referralPrice,
            RefferalPrice: "",
          });
          onChangeModalClose();
        }}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody pl={"70px"} py={"45px"} bg={bgModal}>
            <Flex direction={"column"} gap={"23px"}>
              {/* update reff */}
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"25px"}
              >
                Update Referral Price
              </Text>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  width={"130px"}
                  height={"45px"}
                  labelText="Current Price"
                  inputType={"number"}
                  value={referralPrice.currentPrice}
                />
                <CustomInputForm
                  width={"130px"}
                  height={"45px"}
                  labelText="New Price"
                  inputType={"number"}
                  value={referralPrice.RefferalPrice}
                  onChange={(e: any) =>
                    setReferralPrice({
                      ...referralPrice,
                      RefferalPrice: e.target.value,
                    })
                  }
                />

                <Flex ml={"3px"} alignItems={"center"} gap={"20px"} mt={"25px"}>
                  <SecondaryButton
                    text={"Cancel"}
                    bg={btnSecondaryBG}
                    //   border={"#000"}
                    border={btnPrimaryBG}
                    textColor={textColor}
                    onClick={() => {
                      setReferralPrice({
                        ...referralPrice,
                        RefferalPrice: "",
                      });
                      onChangeModalClose();
                    }}
                    isDisabled={updateReferralPrice.isPending}
                  />
                  <SecondaryButton
                    text={"Update"}
                    primary
                    bg={btnPrimaryBG}
                    border={"#00FFE0"}
                    textColor={"#000"}
                    isDisabled={updateReferralPrice.isPending}
                    isLoading={updateReferralPrice.isPending}
                    onClick={handleUpdateReferralPrice}
                  />
                </Flex>
              </Flex>

              {/* custom referral */}
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"25px"}
                mt={"21px"}
              >
                Create CUSTOM referral code
              </Text>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Enter CUSTOM referral code"
                  inputType={"text"}
                  width={"281px"}
                  height={"45px"}
                  value={referralCode}
                  onChange={(e: any) => setReferralCode(e.target.value)}
                />

                <Flex ml={"3px"} alignItems={"center"} gap={"20px"} mt={"25px"}>
                  <SecondaryButton
                    text={"Cancel"}
                    bg={btnSecondaryBG}
                    // border={"#000"}
                    border={btnPrimaryBG}
                    textColor={textColor}
                    isDisabled={updateReferralCode.isPending}
                    onClick={() => {
                      setReferralCode("");
                      onChangeModalClose();
                    }}
                  />
                  <SecondaryButton
                    text={"Update"}
                    primary
                    bg={btnPrimaryBG}
                    border={"#00FFE0"}
                    textColor={"#000"}
                    isDisabled={updateReferralCode.isPending}
                    isLoading={updateReferralCode.isPending}
                    onClick={handleUpdateReferralCode}
                  />
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onClose}>
        Close
      </Button>
      <Button variant="ghost">Secondary Action</Button>
    </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CardHeading;
