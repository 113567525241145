import React, { useState } from "react";
import { Image, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import AuthCardHeader from "../../components/auth/AuthCardHeader";
import PrimaryButton from "../../components/form-components/PrimaryButton";
import arrow1 from "../../assets/icons/light/backArrow.svg";
import arrow2 from "../../assets/icons/dark/backArrow.svg";

const PasswordUpdated = () => {
  const navigate = useNavigate();
  const [formData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const arrowIcon = useColorModeValue(arrow1, arrow2);
  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => navigate(-1)}
        cursor={"pointer"}
        mb={"40px"}
      />

      <AuthCardHeader
        widthHeading={"390px"}
        headingSize={"43px"}
        title="Your password has been successfully changed"
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/", {
            state: formData,
          });
        }}
      >
        <PrimaryButton type="submit" text="Sign in" />
      </form>
    </AuthLayout>
  );
};

export default PasswordUpdated;
