import {
  Box,
  Flex,
  Image,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme/theme";

//importing icons ACTIVE DARK
import homeDarkActive from "../../assets/dashboard-menu-icons/dark/active/home.svg";
import rateDarkActive from "../../assets/dashboard-menu-icons/dark/active/rate.svg";
import settingDarkActive from "../../assets/dashboard-menu-icons/dark/active/setting.svg";
import supportDarkActive from "../../assets/dashboard-menu-icons/dark/active/support.svg";
import transactionDarkActive from "../../assets/dashboard-menu-icons/dark/active/transactions.svg";
import userDarkActive from "../../assets/dashboard-menu-icons/dark/active/user.svg";

//importing icons NON-ACTIVE DARK
import homeDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/home.svg";
import rateDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/rate.svg";
import settingDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/setting.svg";
import supportDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/support.svg";
import transactionDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/transactions.svg";
import userDarkNonactive from "../../assets/dashboard-menu-icons/dark/non-active/user.svg";

//importing icons ACTIVE Light
import homeLightActive from "../../assets/dashboard-menu-icons/light/active/home.svg";
import rateLightActive from "../../assets/dashboard-menu-icons/light/active/rate.svg";
import settingLightActive from "../../assets/dashboard-menu-icons/light/active/setting.svg";
import supportLightActive from "../../assets/dashboard-menu-icons/light/active/support.svg";
import transactionLightActive from "../../assets/dashboard-menu-icons/light/active/transactions.svg";
import userLightActive from "../../assets/dashboard-menu-icons/light/active/user.svg";

//importing icons NON-ACTIVE Light
import homeLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/home.svg";
import rateLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/rate.svg";
import settingLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/setting.svg";
import supportLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/support.svg";
import transactionLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/transactions.svg";
import userLightNonactive from "../../assets/dashboard-menu-icons/light/non-active/user.svg";

/// logout and minimize dark and light mode
import minimizeLightIco from "../../assets/dashboard-menu-icons/light/minimize.svg";
import minimizeDarkIco from "../../assets/dashboard-menu-icons/dark/minimize.svg";
import logoutLightIco from "../../assets/dashboard-menu-icons/light/logout.svg";
import logoutDarkIco from "../../assets/dashboard-menu-icons/dark/logout.svg";
import { useEffect } from "react";
import { useLogout } from "../../utils/auth.api";

const MenuList = ({
  isOpen,
  isActive,
  setIsActive,
  handleSideBar,
  user,
}: any) => {
  const logout = useLogout();
  //Non-active icons

  const homeIco = useColorModeValue(homeLightNonactive, homeDarkNonactive);
  const rateIco = useColorModeValue(rateLightNonactive, rateDarkNonactive);
  const userIco = useColorModeValue(userLightNonactive, userDarkNonactive);
  const supportIco = useColorModeValue(
    supportLightNonactive,
    supportDarkNonactive
  );
  const transactionIco = useColorModeValue(
    transactionLightNonactive,
    transactionDarkNonactive
  );
  const settingIco = useColorModeValue(
    settingLightNonactive,
    settingDarkNonactive
  );

  //active icons
  const homeActiveIco = useColorModeValue(homeLightActive, homeDarkActive);
  const rateActiveIco = useColorModeValue(rateLightActive, rateDarkActive);
  const userActiveIco = useColorModeValue(userLightActive, userDarkActive);
  const supportActiveIco = useColorModeValue(
    supportLightActive,
    supportDarkActive
  );
  const transactionActiveIco = useColorModeValue(
    transactionLightActive,
    transactionDarkActive
  );

  const settingActiveIco = useColorModeValue(
    settingLightActive,
    settingDarkActive
  );

  //active
  const logoutIco = useColorModeValue(logoutLightIco, logoutDarkIco);
  const minimizeIco = useColorModeValue(minimizeLightIco, minimizeDarkIco);

  const location = useLocation();

  const navigate = useNavigate();
  // eslint-disable-next-line
  const menuItems = [
    {
      text: "Minimize",
      path: "/minimize",
      key: "minimize",
      icon: minimizeIco,
    },
    {
      text: "Home",
      path: "/home",
      key: "home",
      icons: {
        active: homeActiveIco,
        nonActive: homeIco,
      },
    },
    {
      text: "Transactions",
      path: "/transactions",
      key: "transactions",
      icons: {
        active: transactionActiveIco,
        nonActive: transactionIco,
      },
    },
    {
      text: "Rates",
      path: "/rate",
      key: "rates",
      icons: {
        active: rateActiveIco,
        nonActive: rateIco,
      },
    },
    {
      text: "Users",
      path: "/user",
      key: "userDirectory",
      icons: {
        active: userActiveIco,
        nonActive: userIco,
      },
    },
    {
      text: "Support",
      path: "/support",
      key: "support",
      icons: {
        active: supportActiveIco,
        nonActive: supportIco,
      },
    },

    {
      text: "Settings",
      path: "/settings",
      key: "settings",
      icons: {
        active: settingActiveIco,
        nonActive: settingIco,
      },
    },

    {
      text: "Logout",
      path: "/logout",
      key: "logout",
      icon: logoutIco,
    },
  ];

  const tooltipBgColor = useColorModeValue("#FFFFFF", "#333");
  const tooltipTextColor = useColorModeValue(
    "#000000",
    theme.colors.dark.text.primary
  );

  const filteredMenuItems = () => {
    if (user?.isSuperAdmin) {
      return menuItems;
    }
    return menuItems.filter((item) => user?.permissions?.[item?.key] !== false);
  };

  const handleClick = (path: any) => {
    if (path === "/logout") {
      logout.mutateAsync();
      return;
    }
    setIsActive(path);
    if (path === "/minimize") {
      handleSideBar();
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location.pathname, setIsActive]);

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.path)
    );
    if (activeItem) {
      setIsActive(activeItem.path);
    }
  }, [location.pathname, setIsActive, menuItems]);

  return (
    <Box>
      {filteredMenuItems()?.map((item: any, index: number) => {
        const isItemActive = isActive === item?.path;

        return (
          <Box key={index} position="relative">
            <Tooltip
              label={item?.text}
              placement="right"
              borderRadius={"6px"}
              bg={tooltipBgColor}
              color={tooltipTextColor}
            >
              <Flex
                p={"12px"}
                align={"center"}
                justify={isOpen ? "left" : "center"}
                gap="15px"
                cursor={"pointer"}
                borderRadius={"8px"}
                my="15px"
                // _hover={{ bg: hoverBgColor }}
                //bg={isItemActive ? activeBgColor : ""}
                onClick={() => handleClick(item.path)}
                color={isItemActive ? tooltipTextColor : ""}
              >
                <Image
                  src={
                    item?.icons
                      ? isItemActive
                        ? item?.icons?.active
                        : item?.icons?.nonActive
                      : item?.icon
                  }
                  alt={"icon"}
                  width={"45px"}
                  height={"45px"}
                />

                {isOpen && (
                  <Text
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"24px"}
                    fontWeight={400}
                    lineHeight={"20px"}
                    fontStyle={"normal"}
                    style={{
                      fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
                    }}
                  >
                    {item?.text}
                  </Text>
                )}
              </Flex>
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
};

export default MenuList;
