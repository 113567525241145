import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import CustomText from "../form-components/custom-text";
import postiveIcoDark from "../../assets/icons/general/arrow-up-dark.svg";
import postiveIcoLight from "../../assets/icons/general/arrow-up-light.svg";
import negativeIco from "../../assets/icons/general/arrow-down.svg";

const PriceCard = ({ image, currency, stat, price, id, currencyId }: any) => {
  console.log("test 5 pass ids", currencyId, id.coinId);
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const inflIcoPositive = useColorModeValue(postiveIcoLight, postiveIcoDark);
  const textColor = useColorModeValue("#000", "#FFF");
  const inflpostivie = useColorModeValue("#000", "#7CFF90");
  const inflnegative = useColorModeValue("#FF6674", "#FF6674");
  let inflcolor = inflpostivie;

  if (stat?.includes("+")) {
    inflcolor = inflpostivie;
  } else {
    inflcolor = inflnegative;
  }

  return (
    <Flex
      px={"10px"}
      pt={"19px"}
      pb={"17px"}
      maxWidth={"340px"}
      // maxHeight={"68px"}
      height={"100%"}
      width={{ base: "100%", md: "340px" }}
      borderRadius={"5px"}
      bg={bgColor}
      border={`3px solid`}
      borderColor={id.coinId === currencyId ? "#00FFE0" : bgColor}
      //justifyContent={"space-between"}
      gap={"12px"}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
    >
      <Image src={image} alt="icon" width={"45px"} height={"45px"} />
      <Flex direction={"column"} gap={"8px"} alignItems={"flex-start"}>
        <Flex>
          <CustomText
            text={currency ? currency : "USDT"}
            color={textColor}
            weight={700}
            size={"16px"}
            fontFamily={"Plus Jakarta Sans"}
          />
          <CustomText
            text={`/NGN`}
            color={textColor}
            weight={400}
            size={"16px"}
            fontFamily={"Plus Jakarta Sans"}
          />
        </Flex>
        <Flex gap={"3px"} alignItems={"center"}>
          <CustomText
            text={stat}
            color={inflcolor}
            weight={400}
            size={"10px"}
            fontFamily={"Plus Jakarta Sans"}
            lineHeight={"normal"}
          />
          <Image
            src={stat?.includes("+") ? inflIcoPositive : negativeIco}
            width={"8px"}
            height={"8px"}
            alt="+/-"
          />
        </Flex>
      </Flex>

      <Box alignSelf={{ base: undefined, md: "flex-end" }} width={"100%"}>
        <Text
          color={inflcolor}
          fontWeight={500}
          size={"25px"}
          fontFamily={"Plus Jakarta Sans"}
          lineHeight={"normal"}
          align={"end"}
        >
          {`₦${price}`}
        </Text>
      </Box>
    </Flex>
  );
};

export default PriceCard;
