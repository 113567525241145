import {
  Avatar,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SecondaryButton from "../form-components/secondary-button";
import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import CustomInput from "./custom-input-setting";
import CardHeading from "./card-heading";
import { useUpdateReferralCodeByUserId } from "../../utils/auth.api";

const SettingCard = ({ data }: any) => {
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue("#000", "#FFF");
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");

  const toast = useToast();
  const editIcon = useColorModeValue(settingeditlight, settingeditdark);

  const [expand, setExpand] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const updateReferralCode = useUpdateReferralCodeByUserId();

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleUpdateReferralCode = () => {
    if (!referralCode) {
      toast({
        title: "Referral code cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateReferralCode
      ?.mutateAsync({ userId: data?.id, RefferalCode: referralCode })
      .then((result) => {
        toast({
          title: "Referral code updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleExpand();
        setReferralCode("");
      })
      .catch((err) => {
        toast({
          title: err?.message || "Error updating referral code",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      cursor={"pointer"}
      // maxWidth={"892px"}
      height={expand ? "360px" : "44px"}
      width={"100%"}
      p={"17px 23px 19px 40px"}
      justifyContent={"space-between"}
      bg={bgColor}
      border={expand ? "1px solid #00FFE0" : undefined}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
    >
      {/* when row collapse */}
      {!expand && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex gap={"9px"} alignItems={"center"}>
            <Flex onClick={handleExpand} gap={"9px"} alignItems={"center"}>
              <Avatar
                mr={"11px"}
                size={"sm"}
                bg="#00FFE0"
                name={data?.UserName || data?.email}
                src={data?.image}
              />

              <Text
                width={"150px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.UserName || " "}
              </Text>
            </Flex>

            <Text
              width={"125px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {Number(data?.totalRewards || 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) || "0"}
            </Text>

            <Text
              width={"125px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {Number(data?.RefferalBalance || 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) || "0"}
            </Text>

            <Text
              width={"125px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.referralCode || ""}
            </Text>

            <Text
              width={"90px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.NoOfReferrals || 0}
            </Text>

            <Text
              width={"53px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {data?.RefferalPrice || 0}
            </Text>
          </Flex>

          <Image
            src={editIcon}
            alt="edit"
            width={"20px"}
            height={"20px"}
            cursor={"pointer"}
            onClick={handleExpand}
          />
        </Flex>
      )}

      {/* when row expand */}
      {expand && (
        <Flex direction={"column"} gap={"16px"} width={"100%"}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Flex gap={"9px"} alignItems={"center"}>
              <Avatar
                mr={"11px"}
                size={"sm"}
                bg="#00FFE0"
                name={data?.UserName || data?.email}
                src={data?.image}
              />

              <Text
                width={"150px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.UserName || " "}
              </Text>

              <Text
                width={"125px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {Number(data?.totalRewards || 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }) || "0"}
              </Text>

              <Text
                width={"125px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {Number(data?.RefferalBalance || 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }) || "0"}
              </Text>

              <Text
                width={"125px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.referralCode || ""}
              </Text>

              <Text
                width={"110px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.NoOfReferrals || 0}
              </Text>

              <Text
                width={"110px"}
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {data?.RefferalPrice || 0}
              </Text>
            </Flex>

            <Image
              src={editIcon}
              alt="edit"
              width={"20px"}
              height={"20px"}
              cursor={"pointer"}
              onClick={handleExpand}
            />
          </Flex>
          <Flex gap={"20px"} alignItems={"center"}>
            <CustomInput
              placeholder={"Current Referral code"}
              width={"192px"}
              value={data?.referralCode}
            />
            <CustomInput
              placeholder={"New Referral Code"}
              width={"192px"}
              value={referralCode}
              onChange={(e: any) => setReferralCode(e.target.value)}
            />

            <SecondaryButton
              text={"Cancel"}
              bg={btnSecondaryBG}
              // border={"#000"}
              onClick={() => {
                handleExpand();
                setReferralCode("");
              }}
              border={"#00FFE0"}
              textColor={textColor}
              isDisabled={updateReferralCode?.isPending}
            />
            <SecondaryButton
              text={"Update"}
              primary
              bg={btnPrimaryBG}
              border={"#00FFE0"}
              textColor={"#000"}
              isDisabled={updateReferralCode?.isPending}
              isLoading={updateReferralCode?.isPending}
              onClick={handleUpdateReferralCode}
            />
          </Flex>

          <Flex direction={"column"} gap={"10px"}>
            <Text
              width={"150px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"19px"}
              fontWeight={700}
              lineHeight={"normal"}
            >
              Referrals
            </Text>

            <CardHeading padding={"17px 23px 19px 0px"} />
            {/* row 1 */}
            {!data?.referredUsersData?.length ? (
              <Flex justifyContent={"center"} width={"100%"}>
                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={400}
                  lineHeight={"normal"}
                >
                  No Referred Users
                </Text>
              </Flex>
            ) : (
              data?.referredUsersData?.map((user: any, idx: number) => (
                <Flex gap={"9px"} alignItems={"center"} key={idx}>
                  <Avatar
                    mr={"11px"}
                    size={"sm"}
                    bg="#00FFE0"
                    name={user?.fullname}
                    src={user?.image}
                  />

                  <Text
                    width={"150px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    {user?.fullname || ""}
                  </Text>

                  <Text
                    width={"175px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    {user?.email || ""}
                  </Text>

                  <Text
                    width={"125px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    {user?.referralCode || " "}
                  </Text>
                </Flex>
              ))
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SettingCard;
