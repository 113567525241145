import {
  Flex,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../form-components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useReSendOtp, useVerifyOtp } from "../../utils/auth.api";

const TwoFactorAuthentication = ({ email }: any) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    if (!email) {
      // eslint-disable-next-line
      navigate("/auth/forgot-password");
    }

    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, email, navigate]);

  const {
    mutate: verifyOtp,
    isSuccess: otpSuccess,
    data: otpData,
  } = useVerifyOtp();
  const { mutateAsync: resendOtp } = useReSendOtp();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await verifyOtp({ email, otp: pinInput });
      toast({
        title: "Email verified successfully.",
        status: "success",
      });
    } catch (error) {
      setIsVerified(0);
      toast({
        title: "Incorrect verification code.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (otpSuccess) {
      localStorage.setItem("otp_token", otpData?.token?.access_token);
      // eslint-disable-next-line
      navigate("/auth/add-new-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpSuccess]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
        <PinInput
          errorBorderColor="red.300"
          focusBorderColor="teal.300"
          size={"lg"}
          variant={"outline"}
          placeholder="0"
          value={pinInput}
          isInvalid={isVerified === 0 && pinInput !== ""}
          onChange={(value) => {
            setPinInput(value);
            setIsVerified(1);
          }}
          autoFocus
        >
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
          <PinInputField
            _placeholder={{ color: "#C7C7C7" }}
            fontWeight={700}
            color={useColorModeValue("#5A5A5A", "#EEEEEE")}
            fontFamily={"Roboto"}
            fontSize={"20px"}
            border={`1px solid #00CCB3`}
            height={"117px"}
            width={"68px"}
            _hover={{ borderColor: "#00ffe0" }}
          />
        </PinInput>
      </Flex>
      <Flex justify={"space-between"} mt="20px" align={"center"}>
        {isVerified === 0 && pinInput?.length === 6 && (
          <Text color="red.300">Incorrect verification code.</Text>
        )}
      </Flex>

      <Flex direction={"column"} gap={"27px"} alignItems={"center"}>
        <PrimaryButton
          // text={verifyEmailMutation.isLoading ? <Spinner /> : "Verify"}
          //   disabled={verifyEmailMutation.isLoading}
          text={"Submit"}
          type="submit"
        />

        <Flex gap={"5px"}>
          <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
            {displayTime === "00:00" ? "Didn’t get a code?" : "Resend within"}
          </Text>
          <Text
            color={useColorModeValue("#00332D", "#00FFE0")}
            fontWeight={700}
            cursor={displayTime === "00:00" ? "pointer" : ""}
            onClick={async () => {
              if (displayTime === "00:00") {
                try {
                  await resendOtp(email);
                  toast({
                    title: "Otp sent again.",
                    status: "success",
                  });
                  setTimer(60);
                } catch (error: any) {
                  toast({
                    title: error.message,
                    status: "error",
                  });
                }
              }
            }}
          >
            {displayTime === "00:00" ? "Resend code" : displayTime}
          </Text>
        </Flex>
      </Flex>
    </form>
  );
};

export default TwoFactorAuthentication;
