import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import theme from "./theme/theme";
import Signin from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AddNewPassword from "./pages/auth/AddNewPassword";
import Verification from "./pages/auth/Verification";

import Home from "./pages/home";
import Rate from "./pages/rate";
import Transaction from "./pages/transaction";
import User from "./pages/user/user";
import Device from "./pages/user/device";
import Setting from "./pages/setting/setting";
import Support from "./pages/support";
import Location from "./pages/user/location";
import PasswordUpdated from "./pages/auth/PasswordUpdated";
import { useQuery } from "@tanstack/react-query";

function App() {
  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });

  const bgColor = useColorModeValue(theme.colors.light.bg, "#181818");
  const color = useColorModeValue(
    theme.colors.light.text.secondary,
    theme.colors.dark.text.secondary
  );

  return (
    <>
      <Box
        bgColor={bgColor}
        transition={"background-color 1s ease"}
        color={color}
        bgSize={"cover"}
        minH={"100vh"}
      >
        <Router>
          {!token ? (
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route
                path="/auth/forgot-password"
                element={<ForgotPassword />}
              />
              <Route path="/auth/verification" element={<Verification />} />
              <Route
                path="/auth/add-new-password"
                element={<AddNewPassword />}
              />
              <Route
                path="/auth/password-updated"
                element={<PasswordUpdated />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/rate" element={<Rate />} />
              <Route path="/transactions" element={<Transaction />} />
              <Route path="/user" element={<User />} />
              <Route path="/user/device" element={<Device />} />
              <Route path="/user/location" element={<Location />} />
              <Route path="/settings" element={<Setting />} />
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          )}
        </Router>
      </Box>
    </>
  );
}

export default App;
