import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import StatsCard from "../../components/cards/stats-card";
// import chartIconGrowDark from "../assets/icons/dark/chart-arrow-grow.svg";
// import chartIconDownDark from "../assets/icons/dark/chat-arrow-down.svg";
// import chartIconGrowLight from "../assets/icons/light/chat-arrow-grow.svg";
// import chartIconDownLight from "../assets/icons/light/chat-arrow-down.svg";
import FilterCard from "../../components/cards/filter-stats-card";

import dbLight from "../../assets/icons/light/database.svg";
import dbDark from "../../assets/icons/dark/database.svg";
import SettingCard from "../../components/setting/setting-card";
import CardHeading from "../../components/setting/card-heading";
import TwoFactorCard from "../../components/setting/bottom-cards/two-factor-card";
import BankDetailCard from "../../components/setting/bottom-cards/bank-detail-card";
import CustomInputForm from "../../components/setting/form-data/custom-input";
import SecondaryButton from "../../components/form-components/secondary-button";
import CustomInput from "../../components/setting/custom-input-setting";
import Pagination from "../../components/general/pagination";
import {
  useCreateAdmin,
  useGetAllAdmins,
  useGetLoggedInUser,
  useGetReferralData,
  useGetReferralStats,
} from "../../utils/auth.api";
import ReferralSearchbar from "../../components/searchbar-filters/ReferralSearchbar";
import AdminRoleCard from "../../components/setting/admin-role-card";
import { useUpdateEmail, useUpdatePassword } from "../../utils/user.api";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const navigate = useNavigate();

  const {
    isOpen: isChangeModalOpen,
    onOpen: onChangeModalOpen,
    onClose: onChangeModalClose,
  } = useDisclosure();

  const {
    isOpen: isAdminModalOpen,
    onOpen: onAdminModalOpen,
    onClose: onAdminModalClose,
  } = useDisclosure();

  const toast = useToast();

  //for now it is for test purpose so true and false
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue("#E4E4E4", "#000");
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");
  ///stats
  //stats icon
  const dbIcon = useColorModeValue(dbLight, dbDark);
  //stats dummy data
  const statsData = [{ title: "Referral codes", stats: "9", image: dbIcon }];

  //const isopen = localStorage.getItem("isOpen");
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isOpen") === "0" ? false : true
  );

  const [searchBy, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [adminEmail, setAdminEmail] = useState("");
  const [updateEmailPayload, setUpdateEmailPayload] = useState({
    oldEmail: "",
    newemail: "",
    confirmEmail: "",
    password: "",
  });
  const [passwordUpdatePayload, setPasswordUpdatePayload] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });

  const {
    data: referralData,
    isLoading,
    refetch: refetchReferralRecords,
  } = useGetReferralData(limit, offset, searchBy, search);
  const { data: admins, isLoading: adminLoading } = useGetAllAdmins(
    Number.MAX_SAFE_INTEGER,
    0,
    true
  );
  const { data: referralStats, isLoading: referralLoading } =
    useGetReferralStats();
  const { data: user, isLoading: userLoading } = useGetLoggedInUser();
  const createAdmin = useCreateAdmin();
  const updateEmail = useUpdateEmail();
  const updatePassword = useUpdatePassword();

  const clearFields = () => {
    setUpdateEmailPayload({
      oldEmail: "",
      newemail: "",
      confirmEmail: "",
      password: "",
    });
    setPasswordUpdatePayload({
      password: "",
      confirmPassword: "",
      oldPassword: "",
    });
  };

  const handleEmailUpdate = () => {
    if (
      !updateEmailPayload.oldEmail ||
      !updateEmailPayload.newemail ||
      !updateEmailPayload.confirmEmail ||
      !updateEmailPayload.password
    ) {
      toast({
        title: "Please fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (updateEmailPayload.newemail !== updateEmailPayload.confirmEmail) {
      toast({
        title: "Emails do not match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateEmail
      .mutateAsync({
        oldEmail: updateEmailPayload.oldEmail,
        newemail: updateEmailPayload?.newemail,
        password: updateEmailPayload.password,
      })
      .then((result) => {
        toast({
          title: "Email updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onChangeModalClose();
        clearFields();
      })
      .catch((err: any) => {
        toast({
          title: err?.message || "Error updating email",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleUpdatePassword = () => {
    if (
      !passwordUpdatePayload.password ||
      !passwordUpdatePayload.confirmPassword ||
      !passwordUpdatePayload.oldPassword
    ) {
      toast({
        title: "Please fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (
      passwordUpdatePayload.password !== passwordUpdatePayload.confirmPassword
    ) {
      toast({
        title: "Passwords do not match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updatePassword
      .mutateAsync(passwordUpdatePayload)
      .then((result) => {
        toast({
          title: "Password updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onChangeModalClose();
        clearFields();
      })
      .catch((err: any) => {
        toast({
          title: err?.message || "Error updating password",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleFilterChange = (filter: string, value: string) => {
    switch (filter) {
      case "searchType":
        if (value.toLowerCase().includes("code")) {
          setSearchBy("referralCode");
        } else if (value.toLowerCase().includes("name")) {
          setSearchBy("UserName");
        } else if (value.toLowerCase().includes("price")) {
          setSearchBy("referralPrice");
        } else {
          setSearchBy("");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    refetchReferralRecords();
    console.log("refetching data again setting");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, searchBy, search]);

  useEffect(() => {
    if (!userLoading && !user?.permissions?.settings) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    console.log("test sidebar", isOpen);
    const handleStorageChange = (event: any) => {
      if (event.key === "isOpen") {
        setIsOpen(event.newValue === "1");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      {/* 2 buttons on heading bar */}
      <Flex
        // position={"absolute"}
        // top={"100px"}
        // left={isOpen ? "41%" : "30.5%"}
        mb="20px"
        alignItems={"center"}
        justifyContent={"start"}
        gap={"20px"}
      >
        {/* edit admin button for ONLY SUPER-ADMIN */}
        {(user?.isSuperAdmin || user?.permissions?.addNewAdmin) && (
          <Flex
            cursor={"pointer"}
            bg={bgColor}
            width={"317px"}
            height={"56px"}
            borderRadius={"10px"}
            //  position={"absolute"}
            //top={"100px"}
            //   right={"40%"}
            //left={"43%"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={onAdminModalOpen}
          >
            <Text
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"17px"}
              fontWeight={700}
            >
              Other Admins{" "}
              <span style={{ fontSize: "15px", fontWeight: 400 }}>
                (for Super Admin only)
              </span>
            </Text>
          </Flex>
        )}
        {/* change Email Password button */}
        <Flex
          cursor={"pointer"}
          bg={bgColor}
          width={"317px"}
          height={"56px"}
          borderRadius={"10px"}
          //  position={"absolute"}
          // top={"100px"}
          //   right={"40%"}
          // left={"43%"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={onChangeModalOpen}
        >
          <Text
            color={textColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"17px"}
            fontWeight={600}
          >
            Change Email OR Password
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={isOpen ? "column" : "row"}
        justifyContent={isOpen ? "flex-start" : "space-between"}
      >
        {/* stats cards and searchbar and cards */}
        <Flex direction={"column"} gap={"58px"}>
          {/* top stats row */}
          <Flex
            gap={"26px"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <FilterCard type={"users"} />
            <FilterCard
              title={"Total Referrals"}
              count={referralStats?.totalReferrals || "0"}
              isLoading={referralLoading}
              type={"totalReferrals"}
            />
            <FilterCard
              title={"Referrs"}
              count={referralStats?.referrs || 0}
              isLoading={referralLoading}
              type={"referrers"}
            />

            {statsData?.map((stat: any, index: number) => (
              <StatsCard
                key={index}
                title={stat.title}
                stats={referralStats?.referralCodes || "0"}
                image={stat.image}
                nav={stat.nav}
                isLoading={referralLoading}
                type={"referralCodes"}
              />
            ))}
          </Flex>

          {/* setting data */}

          <Flex gap={"23px"}>
            {/* rpos */}

            <Flex
              gap={"6px"}
              width={"100%"}
              direction={"column"}
              // maxWidth={"975px"}
            >
              <Box>
                <Text
                  color={textColor}
                  fontWeight={700}
                  size={"31px"}
                  fontFamily={"Plus Jakarta Sans"}
                  lineHeight={"25px"}
                >
                  Referral codes
                </Text>
              </Box>
              <Box width={"100%"} mb={"-6px"}>
                <ReferralSearchbar
                  onTextChange={(e: any) => setSearch(e)}
                  text={`Referral Codes (${referralData?.totalCount || 0})`}
                  onFilterChange={handleFilterChange}
                />
              </Box>
              <CardHeading />
              {isLoading ? (
                <Flex justify="center">
                  <Spinner size="md" />
                </Flex>
              ) : !referralData?.data?.length ? (
                <Flex justify="center">
                  <Text color={textColor}>No data found</Text>
                </Flex>
              ) : (
                referralData?.data?.map((data: any, index: number) => (
                  <SettingCard key={index} data={data} />
                ))
              )}
              <Box>
                <Pagination
                  total={referralData?.totalCount || 0}
                  limit={limit}
                  offset={offset}
                  onLimitChange={setLimit}
                  onPageChange={setOffset}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        {/* bank and 2fa card */}
        <Flex
          alignItems={"center"}
          gap={"32px"}
          mt={isOpen ? "50px" : "0px"}
          direction={isOpen ? "row" : "column"}
        >
          <TwoFactorCard />
          <BankDetailCard />
          {/* <AdminCard /> */}
        </Flex>
      </Flex>
      {/* Change Email or Password MODAL */}
      <Modal
        isOpen={isChangeModalOpen}
        onClose={() => {
          onChangeModalClose();
          clearFields();
        }}
        size={"5xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody pl={"70px"} py={"45px"} bg={bgColor}>
            <Flex direction={"column"} gap={"23px"}>
              {/* change email */}
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"25px"}
              >
                Change Email address
              </Text>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Current email address"
                  inputType={"email"}
                  value={updateEmailPayload.oldEmail}
                  onChange={(e: any) =>
                    setUpdateEmailPayload({
                      ...updateEmailPayload,
                      oldEmail: e.target.value,
                    })
                  }
                />
                <CustomInputForm
                  labelText="New email address"
                  inputType={"email"}
                  value={updateEmailPayload.newemail}
                  onChange={(e: any) =>
                    setUpdateEmailPayload({
                      ...updateEmailPayload,
                      newemail: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Confirm email address"
                  inputType={"email"}
                  value={updateEmailPayload.confirmEmail}
                  onChange={(e: any) =>
                    setUpdateEmailPayload({
                      ...updateEmailPayload,
                      confirmEmail: e.target.value,
                    })
                  }
                />
                <CustomInputForm
                  labelText="Password"
                  inputType={"password"}
                  value={updateEmailPayload.password}
                  onChange={(e: any) =>
                    setUpdateEmailPayload({
                      ...updateEmailPayload,
                      password: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex gap={"20px"} alignItems={"center"}>
                <SecondaryButton
                  text={"Cancel"}
                  bg={btnSecondaryBG}
                  // border={"#000"}
                  border={btnSecondaryBG}
                  textColor={textColor}
                  onClick={() => {
                    onChangeModalClose();
                    clearFields();
                  }}
                  isDisabled={updateEmail?.isPending}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  textColor={"#000"}
                  isDisabled={updateEmail?.isPending}
                  isLoading={updateEmail?.isPending}
                  onClick={handleEmailUpdate}
                />
              </Flex>
              {/* change Password */}
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"25px"}
                mt={"21px"}
              >
                Change password
              </Text>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="New passowrd"
                  inputType={"password"}
                  value={passwordUpdatePayload.password}
                  onChange={(e: any) =>
                    setPasswordUpdatePayload({
                      ...passwordUpdatePayload,
                      password: e.target.value,
                    })
                  }
                />
                <CustomInputForm
                  labelText="Confirm new password"
                  inputType={"password"}
                  value={passwordUpdatePayload.confirmPassword}
                  onChange={(e: any) =>
                    setPasswordUpdatePayload({
                      ...passwordUpdatePayload,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Current password"
                  inputType={"password"}
                  value={passwordUpdatePayload.oldPassword}
                  onChange={(e: any) =>
                    setPasswordUpdatePayload({
                      ...passwordUpdatePayload,
                      oldPassword: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex gap={"20px"} alignItems={"center"}>
                <SecondaryButton
                  text={"Cancel"}
                  bg={btnSecondaryBG}
                  // border={"#000"}
                  border={btnSecondaryBG}
                  textColor={textColor}
                  isDisabled={updatePassword?.isPending}
                  onClick={() => {
                    onChangeModalClose();
                    clearFields();
                  }}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  textColor={"#000"}
                  isDisabled={updatePassword?.isPending}
                  isLoading={updatePassword?.isPending}
                  onClick={handleUpdatePassword}
                />
              </Flex>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* CHANGE ADMIN PERMISSIONs */}
      <Modal
        isOpen={isAdminModalOpen}
        onClose={() => {
          onAdminModalClose();
          setAdminEmail("");
        }}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody pl={"70px"} py={"45px"} bg={bgColor}>
            <Flex direction={"column"} gap={"17px"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"17px"}
                fontWeight={700}
              >
                Other Admins{" "}
                <span style={{ fontSize: "15px", fontWeight: 400 }}>
                  (for Super Admin only)
                </span>
              </Text>

              {adminLoading ? (
                <Flex justify="center">
                  <Spinner size="md" />
                </Flex>
              ) : !admins?.length ? (
                <Flex justify="center">
                  <Text color={textColor}>No data found</Text>
                </Flex>
              ) : (
                admins?.map((admin: any, index: number) => (
                  <AdminRoleCard admin={admin} key={index} />
                ))
              )}

              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInput
                  placeholder={"Type user email"}
                  width={"160px"}
                  value={adminEmail}
                  onChange={(e: any) => setAdminEmail(e.target.value)}
                />
                <SecondaryButton
                  text={"Add"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  textColor={"#000"}
                  isLoading={createAdmin?.isPending}
                  isDisabled={createAdmin?.isPending}
                  onClick={() => {
                    if (!adminEmail) {
                      toast({
                        title: "Please enter email",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    createAdmin
                      .mutateAsync(adminEmail)
                      .then((result) => {
                        toast({
                          title: "Admin created successfully",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                        setAdminEmail("");
                        onAdminModalClose();
                      })
                      .catch((err: any) => {
                        toast({
                          title: err?.message || "Error creating admin",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      });
                  }}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AdminLayout>
  );
};

export default Setting;
