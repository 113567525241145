import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const CustomDropdown = ({
  pl,
  padding,
  width,
  height,
  placeholder,
  options,
  value,
  handleChange,
  bg,
}: any) => {
  const bgColor = useColorModeValue("#FFF", "#000");
  const hoverColor = useColorModeValue("#67C475", "#00FFE0");
  const hoverColorText = useColorModeValue("#FFF", "#000");
  const textColor = useColorModeValue("#000", "#FFF");

  const [selectedOption, setSelectedOption] = useState(value || "");
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  return (
    <Box position="relative">
      <Button
        pl={pl}
        onClick={handleToggle}
        bg={bg ? bg : bgColor}
        color={bg ? "#000" : textColor}
        borderRadius="5px"
        width={width ? width : "170px"}
        height={height ? height : "40px"}
        boxShadow="0px 4px 40px 0px rgba(0, 0, 0, 0.05)"
        rightIcon={<ChevronDownIcon />}
        padding={padding}
        // _hover={bg ? "#00FFE0" : undefined}
        _hover={bg ? { bg: "#00FFE0" } : undefined}
      >
        {selectedOption || placeholder}
      </Button>
      {isOpen && (
        <Menu isOpen={isOpen} onClose={handleClose}>
          <MenuList
            width={width}
            bg={bgColor}
            color={textColor}
            borderRadius="5px"
            mt="2"
            position="absolute"
            top="40px"
          >
            {options.map((option: string, index: number) => (
              <MenuItem
                bg={bgColor}
                key={index}
                onClick={() => {
                  handleChange(option);
                  setSelectedOption(option);
                  handleClose();
                }}
                _hover={{ bg: hoverColor, color: hoverColorText }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};

export default CustomDropdown;
