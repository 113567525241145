import { Input, Box, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const CustomInputForm = ({
  width,
  height,
  labelText,
  inputType,
  value,
  onChange,
}: any) => {
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue("#F5F5F5", "#1C1C1C");

  const inputStyles = {
    // height: "56px",
    borderRadius: "5px",
    background: bgColor,
    color: textColor,
    fontFamily: '"Plus Jakarta Sans"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    //lineHeight: "25px",
  };

  const labelStyles = {
    color: textColor,
    fontFamily: '"Plus Jakarta Sans"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "25px",
    //marginBottom: "8px",
  };

  return (
    <Box>
      <Text style={labelStyles}>{labelText}</Text>
      <Input
        type={inputType}
        value={value}
        onChange={onChange}
        sx={inputStyles}
        width={width ? width : "383px"}
        height={height ? height : "56px"}
      />
    </Box>
  );
};

export default CustomInputForm;
