/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint @typescript-eslint/restrict-template-expressions: off */
import toast from "react-hot-toast";
// import * as auth from 'auth-provider'

// const apiURL = "https://live-api.payremit.io";
const apiURL = "https://stg-api.payremit.io";

async function client(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: Partial<RequestInit> & { data?: any } = {}
) {
  const token = localStorage.getItem("token");
  const config: RequestInit = {
    method: data ? "POST" : "GET",
    body: data
      ? data?.access_token
        ? undefined
        : data instanceof FormData
        ? data
        : JSON.stringify(data)
      : undefined,
    headers: {
      Authorization: data?.access_token
        ? `${data?.access_token}`
        : token
        ? `${token}`
        : "",
      ...(!data || data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      const contentType = response.headers.get("Content-Type");

      if (contentType?.includes("text/csv")) {
        const data = await response.text();
        return data;
      } else if (contentType?.includes("application/pdf")) {
        const data = await response.blob();
        return data;
      }
      const data = await response.json();

      // if (response.status === 401) {
      //   queryCache.clear();
      //   toast.error(data?.message);
      //   // eslint-disable-next-line prefer-promise-reject-errors
      //   return await Promise.reject({ message: "Please re-authenticate." });
      // }
      if (response.ok) {
        return data;
      } else {
        toast.error(data?.message);

        return await Promise.reject(data);
      }
    });
}

export { client };
