import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";

// export const useGetFilterTransactions = ({
//   coinName,
//   status,
//   type,
//   toShow,
// }: {
//   coinName: string;
//   status: string;
//   type: string;
//   toShow: number;
// }) =>
//   useQuery<any>({
//     queryKey: ["filterTransactions"],
//     queryFn: async () =>
//       await client(
//         `v3/wallet/filterTransactions${
//           toShow ? `?limit=${toShow}&offset=0` : `?limit=5&offset=0`
//         }&coinName=${coinName}&status=${status}&type=${type}`
//       ),

//   });

export const useGetFilterTransactions = ({
  coinName,
  status,
  type,
  searchType,
  from,
  to,
  search,
  limit,
  offset,
  period,
}: {
  coinName: string;
  status: string;
  type: string;
  searchType?: string;
  from?: string;
  to?: string;
  search?: string;
  limit?: number;
  offset?: number;
  period: string;
}) =>
  useQuery<any>({
    queryKey: [
      "filterTransactions",
      coinName,
      status,
      type,
      searchType,
      from,
      to,
      search,
      limit,
      offset,
      period,
    ],
    queryFn: async () => {
      const params = new URLSearchParams({
        status,
        period,
      });
      if (type) params.append("type", type);
      if (coinName) params.append("coinName", coinName);
      if (searchType && search) params.append("searchType", searchType);
      if (from) params.append("from", from);
      if (to) params.append("to", to);
      if (search) params.append("search", search);
      if (limit) params.append("limit", String(limit));
      params.append("offset", String(offset));

      const queryString = params.toString();
      return await client(`v3/wallet/filterTransactions?${queryString}`);
    },
  });

export const useCsvPendingTransactions = ({
  coinName,
  status,
  type,
  fromDate,
  toDate,
}: {
  coinName: string;
  status: string;
  type: string;
  fromDate: string;
  toDate: string;
}) =>
  useQuery<any>({
    queryKey: ["CsvPendingTransactions"],
    queryFn: async () =>
      await client(
        `wallet/CsvPendingTransactions?coinName=${coinName}&status=${status}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`
      ),
  });

export const useGetDepositAndWithdrawalAmount = () =>
  useQuery<any[], Error>({
    queryKey: ["getDepositAndWithdrawalAmount"],
    queryFn: async () => await client(`wallet/getDepositAndWithdrawalAmount`),
  });

export const useAssignTransactionToAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; assignTo: string }>(
    {
      mutationFn: async (data: any) =>
        await client(`wallet/AssignTransactionToAdmin`, { data }),

      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: ["filterTransactions"],
        });
      },
    }
  );
};
export const useRevokeTransactionToAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; assignTo: string }>(
    {
      mutationFn: async (data: any) =>
        await client(`wallet/revokeTransactionFromAdmin`, { data }),

      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: ["filterTransactions"],
        });
      },
    }
  );
};

export const useManualVerificationOfPendingTransactions = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; status: string }>({
    mutationFn: async ({ transactionId, status }) => {
      const queryParams = new URLSearchParams({
        transactionId,
        status,
      });

      return await client(
        `wallet/manualVerificationOfPendingTransactions?${queryParams.toString()}`,
        { method: "POST" }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["filterTransactions"],
      });
    },
  });
};

// for graph deposit and withdraw

// this is updated again on 2ndjuly now it has depsoit,withdraw and reserve data aswell in a single end point,
// export const useGetDepositStats = ({
//   period,
//   fromDate,
//   toDate,
// }: {
//   period: string;
//   fromDate?: string;
//   toDate?: string;
// }) =>
//   useQuery<any>({
//     queryKey: ["useGetDepositStats"],
//     queryFn: async () =>
//       await client(
//         `v3/wallet/getDepositStats?period=${period}&from=${fromDate}&to=${toDate}`
//       ),
//   });

// export const useGetWithdrawStats = ({
//   period,
//   fromDate,
//   toDate,
// }: {
//   period: string;
//   fromDate?: string;
//   toDate?: string;
// }) =>
//   useQuery<any>({
//     queryKey: ["useGetWithdrawStats"],
//     queryFn: async () =>
//       await client(
//         `v3/wallet/getWithdrawalStats?period=${period}&from=${fromDate}&to=${toDate}`
//       ),
//   });

export const useGetTransactionsStats = ({
  period,
  fromDate,
  toDate,
}: {
  period: string;
  fromDate?: string;
  toDate?: string;
}) =>
  useQuery<any>({
    queryKey: ["useGetWithdrawStats"],
    queryFn: async () =>
      await client(
        `v3/wallet/getDepositWithdrawalStats?period=${period}&from=${fromDate}&to=${toDate}`
      ),
  });

export const useGetGraphTransactionStats = ({
  period,
  fromDate,
  toDate,
}: {
  period: string;
  fromDate?: string;
  toDate?: string;
}) =>
  useQuery<any>({
    queryKey: ["useGetGraphWithdrawStats"],
    queryFn: async () =>
      await client(
        `v3/wallet/getDepositWithdrawalStats?period=${period}&from=${fromDate}&to=${toDate}`
      ),
  });

export const useGetCryptoRateStats = ({
  period,
  limit,
  offset,
}: {
  period: string;
  limit?: number;
  offset?: number;
}) =>
  useQuery<any>({
    queryKey: ["useGetCryptoRateStats", period, limit, offset],
    queryFn: async () => {
      const params = new URLSearchParams({
        period,
      });

      params.append("limit", String(limit));
      params.append("offset", String(offset));

      const queryString = params.toString();
      return await client(`v3/wallet/getCryptoDataAndStats?${queryString}`);
    },
  });

export const useGetCryptoPriceToday = () =>
  useQuery<any>({
    queryKey: ["useGetCryptoPriceToday"],
    queryFn: async () => await client(`v3/wallet/cryptoPricesToday`),
  });

export const useGetCryptoRateStatsByCoinID = ({
  period,
  coinId,
}: {
  period: string;
  coinId: string;
}) =>
  useQuery<any>({
    queryKey: ["useGetCryptoRateStats", period, coinId],
    queryFn: async () =>
      await client(
        `v3/wallet/getCoinRatesGraphData?period=${period}&coinId=${coinId}`
      ),
  });

export const useGetAccountDetails = () => {
  return useQuery<any>({
    queryKey: ["getAccountDetails"],
    queryFn: async () => await client(`v3/wallet/getAccountDetails`),
  });
};

export const useUpdateAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, any>({
    mutationFn: async ({ payload, id }: any) =>
      await client(`v3/wallet/updateCollectionAccount?accountId=${id}`, {
        data: payload,
        method: "POST",
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["getAccountDetails"],
      });
    },
  });
};

export const useGetUserInfoGraph = ({
  period,
  fromDate,
  toDate,
  dateFilterFor,
}: {
  period: string;
  fromDate?: string;
  toDate?: string;
  dateFilterFor: string;
}) =>
  useQuery<any>({
    queryKey: ["useGetUserInfoGraph"],
    queryFn: async () =>
      await client(
        `v3/wallet/getLocationsAndDevices?period=${period}&from=${fromDate}&to=${toDate}&dateFilterFor=${dateFilterFor}`
      ),
  });
