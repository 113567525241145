import {
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Tag from "../../general/tag";
import SecondaryButton from "../../form-components/secondary-button";
import { format } from "date-fns";
import { useManualVerificationOfPendingTransactions } from "../../../utils/wallet.api";
import copyIconDark from "../../../assets/icons/dark/copy-text.svg";
import copyIconLight from "../../../assets/icons/light/copy-text.svg";

const PendingTransactionCard = ({ data, to }: any) => {
  const completeTransaction = useManualVerificationOfPendingTransactions();
  const toast = useToast();

  const formatedDate = format(new Date(data?.createdAt), "dd MMMM yyyy");
  const formatedTime = format(new Date(data?.createdAt), "hh:mm a");
  const currency = data?.coin ? data?.coin?.symbol?.split("(")?.[0] : "Naira";

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const copyIcon = useColorModeValue(copyIconLight, copyIconDark);
  const textColor = useColorModeValue("#000", "#FFF");
  const inflpostivie = useColorModeValue("#348537", "#7CFF90");
  const inflnegative = useColorModeValue("#FF6674", "#FF6674");
  let inflcolor = inflpostivie;

  if (data?.type?.includes("eposit")) {
    inflcolor = inflpostivie;
  } else if (data?.type?.includes("ithd")) {
    inflcolor = inflnegative;
  } else {
    inflcolor = textColor;
  }

  const [expand, setExpand] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const handleExpand = () => {
    setExpand(!expand);
  };

  const getSenderWallet = () => {
    if (data?.type === "Transfer") {
      return data?.user?.uniqueId;
    } else if (data?.type === "Withdraw") {
      return data?.user?.uniqueId;
    } else if (data?.type?.includes("Fiat")) {
      return data?.bankName;
    } else {
      return data?.fromAddress;
    }
  };

  const getReceiverWallet = () => {
    if (data?.type === "Transfer") {
      return data?.receiverUserId;
    } else if (data?.type === "Withdraw") {
      return data?.toAddress?.slice(0, 5) + "***" + data?.toAddress?.slice(-5);
    } else if (data?.type?.includes("Fiat")) {
      return data?.accountNumber;
    } else {
      return data?.toAddress;
    }
  };

  const getUserEmail = () => {
    if (data?.type === "Transfer") {
      return data?.user?.email;
    } else if (data?.type === "Withdraw") {
      return data?.user?.email;
    } else {
      return data?.email;
    }
  };

  const copyText = (text: string, name: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: `${name} copied`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    <Flex
      cursor={"pointer"}
      // maxWidth={"892px"}
      height={expand ? "206px" : "75px"}
      width={"100%"}
      p={{ base: "17px 10px 19px 10px", md: "17px 23px 19px 40px" }}
      justifyContent={"space-between"}
      bg={bgColor}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      onClick={handleExpand}
    >
      {/* when row collapse */}
      {!expand && (
        <>
          <Flex
            gap={{ base: undefined, md: "50px" }}
            // border={"1px solid red"}
            justifyContent={{ base: "space-between", md: undefined }}
            alignItems={"center"}
          >
            <Text
              width={"105px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={{ base: "10px", md: "15px" }}
              fontWeight={600}
              lineHeight={"25px"}
            >
              {formatedDate}
            </Text>

            <Flex gap={"2px"} alignItems={"center"}>
              <Text
                color={inflcolor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ base: "15px", md: "25px" }}
                fontWeight={600}
                lineHeight={"30px"}
              >{`${data?.type || " "}`}</Text>
              <Text
                color={inflcolor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ base: "15px", md: "25px" }}
                fontWeight={600}
                lineHeight={"30px"}
              >
                {to ? `: ${to}` : ""}
              </Text>
              <Text
                color={inflcolor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ base: "15px", md: "25px" }}
                fontWeight={600}
                lineHeight={"30px"}
              >{`-${currency}`}</Text>
            </Flex>
          </Flex>

          <Text
            color={textColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={{ base: "15px", md: "25px" }}
            fontWeight={500}
            lineHeight={"normal"}
          >
            {currency?.includes("air")
              ? `₦${Number(data?.amount)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}`
              : ` ${Number(data?.amount || 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 5,
                })},${currency}`}
          </Text>
        </>
      )}

      {/* when row expand */}
      {expand && (
        // <Flex gap={"5px"}>
        //   {tags?.map((tag: string, index: number) => (
        //     <Tag text={tag} key={index} />
        //   ))}

        // </Flex>
        <>
          <>
            <Flex gap={"50px"} width={"100%"}>
              {/* column 1 */}
              <Flex direction={"column"} gap={"15px"} alignItems={"flex-start"}>
                <Flex gap={"8px"} direction={"column"}>
                  <Text
                    width={"105px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={{ base: "10px", md: "15px" }}
                    fontWeight={600}
                    // lineHeight={"25px"}
                  >
                    {formatedDate}
                  </Text>
                  <Text
                    width={"105px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={{ base: "10px", md: "15px" }}
                    fontWeight={400}
                  >
                    {formatedTime}
                  </Text>
                </Flex>

                <Flex gap={"8px"} direction={"column"}>
                  <Tag
                    text={
                      data?.type?.includes("Fiat")
                        ? "Bank Name"
                        : "Sender wallet"
                    }
                  />

                  <Text
                    //  width={"105px"}
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    {getSenderWallet() || "N/A"}
                  </Text>
                </Flex>

                <Flex gap={"15px"}>
                  <Tag text={"USDT/NGN"} />
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    {data?.amountInUsd || "N/A"},USDT
                  </Text>
                </Flex>
              </Flex>

              {/* column 2 */}
              <Flex
                direction={"column"}
                gap={"15px"}
                alignItems={"flex-start"}
                width={"100%"}
              >
                <Flex gap={"8px"} direction={"column"}>
                  <Flex gap={"2px"} alignItems={"center"}>
                    <Text
                      color={inflcolor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={{ base: "15px", md: "25px" }}
                      fontWeight={600}
                      lineHeight={"30px"}
                    >{`${data?.type}`}</Text>
                    <Text
                      color={inflcolor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={{ base: "15px", md: "25px" }}
                      fontWeight={600}
                      lineHeight={"30px"}
                    >
                      {to ? `: ${to}` : ""}
                    </Text>
                    <Text
                      color={inflcolor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={{ base: "15px", md: "25px" }}
                      fontWeight={600}
                      lineHeight={"30px"}
                    >{`-${currency}`}</Text>
                  </Flex>

                  <Flex alignItems={"center"} gap={"4px"}>
                    <Text
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"13px"}
                      fontWeight={700}
                      lineHeight={"normal"}
                    >
                      User email:{" "}
                      <span
                        style={{ fontWeight: 400, textDecoration: "underline" }}
                      >
                        {getUserEmail() || "N/A"}
                      </span>
                    </Text>
                    <Image
                      src={copyIcon}
                      alt="copy"
                      width={"16px"}
                      height={"16px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(getUserEmail(), "Email");
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex gap={"54px"}>
                  <Flex direction={"column"} gap={"15px"}>
                    <Flex gap={"8px"} direction={"column"} mt={"-3px"}>
                      <Tag
                        text={
                          data?.type?.includes("Fiat")
                            ? "Account Number"
                            : "Recipient wallet"
                        }
                      />

                      <Text
                        //  width={"105px"}
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {getReceiverWallet() || "N/A"}
                      </Text>
                    </Flex>

                    <Flex gap={"15px"}>
                      <Tag
                        text={
                          data?.type?.includes("Fiat")
                            ? "Account Name"
                            : "Network"
                        }
                      />
                      <Text
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {data?.type?.includes("Fiat")
                          ? data?.accountName
                          : data?.network || "N/A"}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex direction={"column"} gap={"15px"}>
                    <Flex gap={"8px"} direction={"column"} mt={"-3px"}>
                      <Tag text={"Current balance"} />

                      <Text
                        //  width={"105px"}
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"13px"}
                        fontWeight={700}
                      >
                        {Number(data?.balance)?.toLocaleString(undefined, {
                          maximumFractionDigits: 5,
                        }) || "N/A"}{" "}
                        {currency}
                      </Text>
                    </Flex>

                    <Flex gap={"15px"}>
                      <Tag text={"Network fee"} />
                      <Text
                        color={textColor}
                        fontFamily={"Plus Jakarta Sans"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {data?.fee || "0"} NGN
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* column 3 */}

              <Flex direction={"column"} gap={"15px"} alignItems={"flex-end"}>
                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={{ base: "15px", md: "25px" }}
                  fontWeight={500}
                  lineHeight={"normal"}
                >
                  {currency?.includes("air")
                    ? `₦${Number(data?.amount)?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}`
                    : ` ${Number(data?.amount)?.toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })},${currency}`}
                </Text>

                <Flex direction={"column"} gap={"15px"} mt={"5px"}>
                  <SecondaryButton
                    text={"Approve"}
                    isDisabled={completeTransaction.isPending}
                    isLoading={
                      completeTransaction.isPending && loadingFor === "approve"
                    }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setLoadingFor("approve");
                      completeTransaction
                        .mutateAsync({
                          transactionId: data?.id,
                          status: "Completed",
                        })
                        .then((result) => {
                          toast({
                            title: "Transaction Approved",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                          setLoadingFor("");
                        })
                        .catch((err) => {
                          toast({
                            title: "Transaction Approval Failed",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                          });
                        });
                    }}
                    primary
                  />
                  <SecondaryButton
                    text={"Reject"}
                    isDisabled={completeTransaction.isPending}
                    isLoading={
                      completeTransaction.isPending && loadingFor === "reject"
                    }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setLoadingFor("reject");
                      completeTransaction
                        .mutateAsync({
                          transactionId: data?.id,
                          status: "Rejected",
                        })
                        .then((result) => {
                          toast({
                            title: "Transaction Rejected",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                          setLoadingFor("");
                        })
                        .catch((err) => {
                          toast({
                            title: "Transaction Rejection Failed",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                          });
                        });
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </>
        </>
      )}
    </Flex>
  );
};

export default PendingTransactionCard;
