import { Box, Flex, Image, Spinner, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../form-components/custom-text";
import settingIco from "../../assets/icons/general/setting.svg";
import { useNavigate } from "react-router-dom";
import Dropdown from "../form-components/dropdown";
import { formatNumber } from "../../utils/functions/formatNumber";

const StatsCard = ({
  image,
  imageSize,
  title,
  stats,
  nav,
  active,
  type,
  coinId,
}: any) => {
  console.log("coin id", coinId);
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Flex
      cursor={"pointer"}
      p={"25px 19px 19px 19px"}
      justifyContent={"center"}
      //  alignItems={"flex-end"}
      gap={"20px"}
      height={"112px"}
      bg={bgColor}
      borderRadius={"10px"}
      minW={"180px"}
      _hover={{
        border: "3px solid #00FFE0",
        boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      onClick={() => {
        if (nav) {
          navigate(nav);
        }
      }}
      border={active ? "1px solid #00FFE0" : undefined}
    >
      <Image
        src={image}
        alt="icon"
        width={imageSize ? imageSize : "35px"}
        height={imageSize ? imageSize : "35px"}
        mt={"15px"}
      />
      <Flex
        direction={"column"}
        gap={"8px"}
        width={"100%"}
        alignItems={"center"}
        height={"100%"}
      >
        <Flex
          width={"100%"}
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomText
            text={title ? title : "Dummy"}
            color={textColor}
            weight={500}
          />

          <Box position={"relative"}>
            <Image
              src={settingIco}
              alt={"icon"}
              width={"15px"}
              height={"15px"}
              onClick={handleDropdownToggle}
              cursor="pointer"
            />
            {dropdownOpen && (
              <Dropdown
                isOpen={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
                positionTop={"17px"}
                sub
                left={
                  title === "Total Balance" || title === "Devices"
                    ? true
                    : false
                }
                type={type}
                coinId={coinId}
              />
            )}
          </Box>
        </Flex>
        <Flex>
          <CustomText
            text={stats ? formatNumber(Number(stats)) : <Spinner />}
            color={textColor}
            size={"35px"}
            weight={400}
            lineHeight={"36px"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StatsCard;
