import { Avatar, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import Tag from "../../general/tag";
import settingeditdark from "../../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../../assets/icons/light/setting-edit.svg";
import { format } from "date-fns";
const AprrovedTransactionCard = ({ transaction }: any) => {
  const currency = transaction?.coin
    ? transaction?.coin?.symbol?.split("(")?.[0]
    : "NGN";
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue("#000", "#FFF");
  const inflpostivie = useColorModeValue("#348537", "#7CFF90");
  const inflnegative = useColorModeValue("#FF6674", "#FF6674");

  const editIcon = useColorModeValue(settingeditlight, settingeditdark);
  let inflcolor = inflpostivie;

  if (transaction?.type?.includes("eposit")) {
    inflcolor = inflpostivie;
  } else if (transaction?.type?.includes("ithd")) {
    inflcolor = inflnegative;
  } else {
    inflcolor = textColor;
  }

  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };

  const getSenderLabel = () => {
    if (transaction?.type === "Swap") {
      return "From Coin";
    } else if (transaction?.type?.includes("Fiat")) {
      return "Bank Name";
    } else if (transaction?.Type?.includes("Airtime")) {
      return "Order Id";
    } else if (transaction?.Type?.includes("Tv")) {
      return "Smart Card No";
    } else if (transaction?.Type?.includes("Electricity")) {
      return "Token";
    } else if (transaction?.Type?.includes("Internet")) {
      return "Customer Name";
    } else {
      return "Sender wallet";
    }
  };

  const getReceiverLabel = () => {
    if (transaction?.type === "Swap") {
      return "To Coin";
    } else if (transaction?.type?.includes("Fiat")) {
      return "Account Number";
    } else if (
      transaction?.Type?.includes("Airtime") ||
      transaction?.Type?.includes("Internet")
    ) {
      return "Paid To";
    } else if (transaction?.Type?.includes("Tv")) {
      return "Provider";
    } else if (transaction?.Type?.includes("Electricity")) {
      return "Meter";
    } else {
      return "Recipient wallet";
    }
  };

  const getSenderWallet = () => {
    if (transaction?.type === "Withdraw") {
      return (
        transaction?.fromAddress?.substr(0, 6) +
          "***" +
          transaction?.fromAddress?.substr(
            transaction?.fromAddress?.length - 6,
            transaction?.fromAddress?.length
          ) || "N/A"
      );
    } else if (transaction?.type === "Swap") {
      if (!transaction?.isToCrypto) {
        return transaction?.coin?.symbol?.split("(")?.[0];
      } else {
        return "NGN";
      }
    } else if (transaction?.type === "Received") {
      return transaction?.email;
    } else if (transaction?.type === "Transfer") {
      return transaction?.user?.uniqueId;
    } else if (transaction?.type === "Deposit") {
      return (
        transaction?.fromAddress?.substr(0, 6) +
          "***" +
          transaction?.fromAddress?.substr(
            transaction?.fromAddress?.length - 6,
            transaction?.fromAddress?.length
          ) || "N/A"
      );
    } else if (transaction?.type?.includes("Fiat")) {
      return transaction?.bankName;
    } else if (transaction?.Type?.includes("Airtime")) {
      return transaction?.orderId;
    } else if (transaction?.Type?.includes("Tv")) {
      return transaction?.smartCardNumber;
    } else if (transaction?.Type?.includes("Electricity")) {
      return transaction?.token;
    } else if (transaction?.Type?.includes("Internet")) {
      return transaction?.customerName;
    }
    return "N/A";
  };

  const getReceiverWallet = () => {
    if (transaction?.type === "Withdraw") {
      return (
        transaction?.toAddress?.substr(0, 6) +
          "***" +
          transaction?.toAddress?.substr(
            transaction?.toAddress?.length - 6,
            transaction?.toAddress?.length
          ) || "N/A"
      );
    } else if (transaction?.type === "Swap") {
      if (transaction?.isToCrypto) {
        return transaction?.coin?.symbol?.split("(")?.[0];
      } else {
        return "NGN";
      }
    } else if (transaction?.type === "Received") {
      return transaction?.receiverUserId;
    } else if (transaction?.type === "Transfer") {
      return transaction?.receiverUserId;
    } else if (transaction?.type === "Deposit") {
      return (
        transaction?.toAddress?.substr(0, 6) +
          "***" +
          transaction?.toAddress?.substr(
            transaction?.toAddress?.length - 6,
            transaction?.toAddress?.length
          ) || "N/A"
      );
    } else if (transaction?.type?.includes("Fiat")) {
      return transaction?.accountNumber;
    } else if (
      transaction?.Type?.includes("Airtime") ||
      transaction?.Type?.includes("Internet")
    ) {
      return transaction?.paidTo;
    } else if (transaction?.Type?.includes("Tv")) {
      return transaction?.provider;
    } else if (transaction?.Type?.includes("Electricity")) {
      return transaction?.meterType + " " + transaction?.meterNumber;
    }
    return "N/A";
  };

  const getHeaderPrice = () => {
    if (transaction?.type === "Swap") {
      return Number(transaction?.swappedAmount)?.toLocaleString(undefined, {
        maximumFractionDigits: 5,
      });
    } else {
      return Number(transaction?.amount)?.toLocaleString(undefined, {
        maximumFractionDigits: 5,
      });
    }
  };

  // ! type is coming in wallet transactions
  // ! Type is coming in Utilities transactions

  return (
    <Flex
      cursor={"pointer"}
      // maxWidth={"892px"}
      height={expand ? "206px" : "75px"}
      width={"100%"}
      p={"17px 23px 19px 40px"}
      justifyContent={"space-between"}
      bg={bgColor}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      onClick={handleExpand}
    >
      {/* when row collapse */}
      {!expand && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex gap={"20px"} alignItems={"center"}>
            <Avatar
              size={"sm"}
              bg="#00FFE0"
              color="#000"
              name={transaction?.user?.fullname}
              src={transaction?.user?.image}
            />

            <Text
              width={"128px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {transaction?.user?.fullname || ""}
            </Text>

            <Text
              width={"193px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {transaction?.user?.email || ""}
            </Text>

            <Text
              width={"95px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >{`${transaction?.type || transaction?.Type}`}</Text>

            <Flex gap={"2px"} alignItems={"center"} width={"87px"} wrap="wrap">
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >
                {getHeaderPrice()}
              </Text>

              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"13px"}
                fontWeight={400}
                lineHeight={"normal"}
              >{`${
                transaction?.type === "Swap"
                  ? transaction?.isToCrypto
                    ? currency
                    : "NGN"
                  : currency
              }`}</Text>
            </Flex>

            <Text
              width={"87px"}
              color={textColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"13px"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              Completed
            </Text>
          </Flex>

          <Image
            src={editIcon}
            alt="edit"
            width={"20px"}
            height={"20px"}
            cursor={"pointer"}
            onClick={handleExpand}
          />
        </Flex>
      )}

      {/* when row expand */}
      {expand && (
        <>
          <Flex gap={"50px"} width={"100%"}>
            {/* column 1 */}
            <Flex direction={"column"} gap={"15px"} alignItems={"flex-start"}>
              <Flex gap={"8px"} direction={"column"}>
                <Text
                  width={"105px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"15px"}
                  fontWeight={600}
                  // lineHeight={"25px"}
                >
                  {format(transaction?.createdAt, "dd MMMM yyyy")}
                </Text>
                <Text
                  width={"105px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"15px"}
                  fontWeight={400}
                >
                  {format(transaction?.createdAt, "hh:mm a")}
                </Text>
              </Flex>

              <Flex gap={"8px"} direction={"column"}>
                <Tag text={getSenderLabel()} />

                <Text
                  //  width={"105px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {getSenderWallet() || "N/A"}
                </Text>
              </Flex>

              <Flex gap={"15px"}>
                <Tag text={transaction?.Type ? "Operator Id" : "USDT/NGN"} />
                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {transaction?.type === "Swap"
                    ? Number(transaction?.amount)?.toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })
                    : transaction?.Type
                    ? transaction?.operatorId
                    : Number(transaction?.amountInUsd)?.toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 5,
                        }
                      ) || 0}{" "}
                  {transaction?.isToCrypto
                    ? "NGN"
                    : transaction?.type && "USDT"}
                </Text>
              </Flex>
            </Flex>

            {/* column 2 */}
            <Flex
              direction={"column"}
              gap={"15px"}
              alignItems={"flex-start"}
              width={"100%"}
            >
              <Flex gap={"8px"} direction={"column"}>
                <Flex gap={"2px"} alignItems={"center"}>
                  <Text
                    color={inflcolor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"30px"}
                    fontWeight={600}
                    lineHeight={"30px"}
                  >{`${transaction?.type || transaction?.Type}`}</Text>
                  <Text
                    color={inflcolor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"30px"}
                    fontWeight={600}
                    lineHeight={"30px"}
                  >{`- ${
                    transaction?.type === "Swap"
                      ? transaction?.isToCrypto
                        ? currency
                        : "NGN"
                      : transaction?.Type
                      ? "Utilities"
                      : currency
                  }`}</Text>
                </Flex>

                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"13px"}
                  fontWeight={700}
                  lineHeight={"normal"}
                >
                  User email:{" "}
                  <span
                    style={{ fontWeight: 400, textDecoration: "underline" }}
                  >
                    {transaction?.user?.email || ""}
                  </span>
                </Text>
              </Flex>

              <Flex gap={"54px"}>
                <Flex direction={"column"} gap={"15px"}>
                  <Flex gap={"8px"} direction={"column"} mt={"-3px"}>
                    <Tag text={getReceiverLabel()} />

                    <Text
                      //  width={"105px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {getReceiverWallet() || "N/A"}
                    </Text>
                  </Flex>

                  <Flex gap={"15px"}>
                    <Tag
                      text={
                        transaction?.type?.includes("Fiat")
                          ? "Account Name"
                          : transaction?.Type?.includes("Tv") ||
                            transaction?.Type?.includes("Electricity")
                          ? "Product ID"
                          : "Network"
                      }
                    />
                    <Text
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {transaction?.type?.includes("Fiat")
                        ? transaction?.accountName
                        : transaction?.Type?.includes("Airtime") ||
                          transaction?.Type?.includes("Internet")
                        ? transaction?.networkName
                        : transaction?.Type?.includes("Tv") ||
                          transaction?.Type?.includes("Electricity")
                        ? transaction?.productId
                        : transaction?.network || "N/A"}
                    </Text>
                  </Flex>
                </Flex>

                <Flex direction={"column"} gap={"15px"}>
                  <Flex gap={"8px"} direction={"column"} mt={"-3px"}>
                    <Tag text={"Current balance"} />

                    <Text
                      //  width={"105px"}
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"13px"}
                      fontWeight={700}
                    >
                      {transaction?.balance || 0}{" "}
                      {transaction?.coin?.symbol?.split("(")?.[0] || "NGN"}
                    </Text>
                  </Flex>

                  <Flex gap={"15px"}>
                    <Tag
                      text={transaction?.Type ? "Service Fee" : "Network fee"}
                    />
                    <Text
                      color={textColor}
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {transaction?.type === "Swap"
                        ? transaction?.swapFee
                        : transaction?.Type
                        ? transaction?.serviceFee || 0
                        : transaction?.fee || 0}{" "}
                      NGN
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            {/* column 3 */}

            <Flex direction={"column"} gap={"15px"} alignItems={"flex-end"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"30px"}
                fontWeight={500}
                lineHeight={"normal"}
              >
                {`${getHeaderPrice()}${
                  transaction?.type === "Swap"
                    ? transaction?.isToCrypto
                      ? currency
                      : "NGN"
                    : currency
                }`}
              </Text>

              <Flex gap={"8px"} direction={"column"} mt={"13px"}>
                <Tag text={"Approved by"} />

                <Text
                  //  width={"105px"}
                  ml={"4px"}
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {transaction?.handledByAdmin?.fullname || "Auto"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AprrovedTransactionCard;
