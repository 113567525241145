import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Box, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import StatsCard from "../components/cards/stats-card";

import RateDetailCard from "../components/cards/rate-detail-card";

import CryptoPriceToday from "../components/general/crypto-price-right-bar";
import chartIconGrowDark from "../assets/icons/dark/chart-arrow-grow.svg";
import chartIconDownDark from "../assets/icons/dark/chat-arrow-down.svg";
import chartIconGrowLight from "../assets/icons/light/chat-arrow-grow.svg";
import chartIconDownLight from "../assets/icons/light/chat-arrow-down.svg";
import { useGetCryptoRateStats } from "../utils/wallet.api";
import { useGetLoggedInUser } from "../utils/auth.api";

const Rate = () => {
  const [period] = useState("MAX");

  const [limit] = useState(10);
  const [offset] = useState(0);

  const [coinId, setCoinId] = useState(""); //this is use for highlighted the side crypto price card
  // fetching data using useGetCryptoRateStats
  const {
    data: cryptoData,
    isLoading,
    isError,
    refetch,
  } = useGetCryptoRateStats({ period, limit, offset });
  const { data: user } = useGetLoggedInUser();

  // const textColor = useColorModeValue("#000", "#FFF");
  const chartGrow = useColorModeValue(chartIconGrowLight, chartIconGrowDark);
  const chartDown = useColorModeValue(chartIconDownLight, chartIconDownDark);

  ///stats
  //stats dummy data
  // const statsData = [
  //   { title: "BTC Withdrawals", stats: "32,976.74", image: binance },
  //   { title: "BTC Deposits", stats: "32,976.74", image: binance },
  //   { title: "BTC Swaps", stats: "7,510,893.072", image: binance },
  //   { title: "Total BTC Balance", stats: "$ 29,258", image: binance },
  // ];

  const [statsData, setStatsData] = useState<any>([]);

  const handleCardSelect = (coin: any) => {
    // setSelectedCoin(coin);
    if (coin) {
      setCoinId(coin.coinId);
      //console.log("test 5 in rate", coinId);
      const selectedStats = [
        {
          title: `${coin.coinSymbol} Withdrawals`,
          stats: coin.totalWithdrawals.toFixed(2),
          image: coin.coinIcon,
          type: "withdrawals",
          coinId: coin.coinId,
        },
        {
          title: `${coin.coinSymbol} Deposits`,
          stats: coin.totalDeposits.toFixed(2),
          image: coin.coinIcon,
          type: "deposits",
          coinId: coin.coinId,
        },
        {
          title: `${coin.coinSymbol}/NGN Swaps`,
          stats: coin.totalSwaps.toFixed(2),
          image: coin.coinIcon,
          type: "swaps",
          coinId: coin.coinId,
        },
        {
          title: `Total ${coin.coinSymbol} Balance`,
          stats: coin.totalBalance.toFixed(2),
          image: coin.coinIcon,
          type: "totalBalance",
          coinId: coin.coinId,
        },
      ];
      setStatsData(selectedStats);
    } else {
      setCoinId("");
      const defaultStats = [
        {
          title: "Total Withdrawals",
          stats: cryptoData.totalWithdrawals.toFixed(2),
          image: chartDown,
          type: "withdrawals",
        },
        {
          title: "Total Deposits",
          stats: cryptoData.totalDeposits.toFixed(2),
          image: chartGrow,
          type: "deposits",
        },
        {
          title: "Total Swaps",
          stats: cryptoData.totalSwaps.toFixed(2),
          image: chartGrow,
          type: "swaps",
        },
        {
          title: "Total Balance",
          stats: cryptoData.totalBalance.toFixed(2),
          image: chartGrow,
          type: "totalBalance",
        },
      ];
      setStatsData(defaultStats);
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  useEffect(() => {
    if (cryptoData) {
      const totals = [
        {
          title: "Total Withdrawals",
          stats: cryptoData.totalWithdrawals.toFixed(2),
          image: chartDown,
          type: "withdrawals",
        },
        {
          title: "Total Deposits",
          stats: cryptoData.totalDeposits.toFixed(2),
          image: chartGrow,
          type: "deposits",
        },
        {
          title: "Total Swaps",
          stats: cryptoData.totalSwaps.toFixed(2),
          image: chartGrow,
          type: "swaps",
        },
        {
          title: "Total Balance",
          stats: cryptoData.totalBalance.toFixed(2),
          image: chartGrow,
          type: "totalBalance",
        },
      ];

      setStatsData(totals);
      //setPaginatedData(data.coinData.slice(0, itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoData]);
  return (
    <AdminLayout>
      <Flex direction={"column"} gap={"58px"}>
        {/* top stats row */}
        <Flex
          gap={"26px"}
          justifyContent={"flex-start"}
          alignItems={{ base: undefined, md: "center" }}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
        >
          {statsData?.map((stat: any, index: number) => (
            <StatsCard
              key={index}
              title={stat.title}
              stats={stat.stats}
              image={stat.image}
              type={stat.type}
              coinId={stat.coinId}
            />
          ))}
        </Flex>

        {/* crypto detail and crypto rates today */}
        <Flex gap={"23px"} direction={{ base: "column", md: "row" }}>
          {/* crypto detail */}
          <Box
            overflowX={{ base: "auto", md: undefined }}
            width={{ base: "100%", md: "100%" }}
          >
            <Flex
              gap={"20px"}
              width={{ base: "895px", md: "100%" }}
              direction={"column"}
              // maxWidth={"892px"}
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <Box color="red">Error fetching rates</Box>
              ) : (
                <>
                  {cryptoData?.coinData?.map((item: any, index: number) => (
                    <RateDetailCard
                      key={index}
                      data={item}
                      onClick={handleCardSelect}
                      user={user}
                    />
                  ))}
                </>
              )}
              {/* <Box>
                <Pagination
                  total={cryptoData?.totalCount || 0}
                  limit={limit}
                  offset={offset}
                  onLimitChange={setLimit}
                  onPageChange={setOffset}
                />
              </Box> */}
            </Flex>
          </Box>

          {/* today crypto rate */}
          <CryptoPriceToday coinId={coinId} />
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default Rate;
