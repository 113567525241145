import {
  Flex,
  PinInput,
  PinInputField,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const TwoFactorEnable = ({ pinInput, setPinInput }: any) => {
  const paraColor1 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor4 = useColorModeValue("#C7C7C7", "#909090");

  return (
    <Flex
      justify={"space-between"}
      w={"100%"}
      alignSelf={"stretch"}
      gap={"12px"}
      color={paraColor1}
      fontSize={"20px"}
      fontFamily={"Roboto"}
      fontWeight={"700"}
      lineHeight={"20px"}
      letterSpacing={"0.05px"}
    >
      <PinInput
        errorBorderColor="red.300"
        focusBorderColor="#33FFE6"
        // size={"lg"}
        variant={"flushed"}
        placeholder="0"
        value={pinInput}
        // isInvalid={isVerified === 0 && pinInput !== ""}
        otp={true}
        onChange={(value) => {
          setPinInput(value);
        }}
        autoFocus
      >
        <PinInputField w={"100%"} _placeholder={{ color: "#C7C7C7" }} />
        <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
        <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
        <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
        <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
        <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
      </PinInput>
    </Flex>
  );
};

export default TwoFactorEnable;
