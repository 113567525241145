import { Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const SecondaryButton = ({
  width,
  bg,
  border,
  primary,
  text,
  onClick,
  textColor,
  isLoading,
  isDisabled,
}: any) => {
  const btnClrPrimary = useColorModeValue("#000", "#FFF");
  const btnClrSeconday = useColorModeValue("#FFF", "#000");
  return (
    <Button
      cursor={"pointer"}
      onClick={onClick}
      width={width ? width : "150px"}
      height="40px"
      borderRadius="5px"
      background={bg ? bg : primary ? btnClrPrimary : btnClrSeconday}
      color={
        textColor
          ? textColor
          : bg
          ? btnClrPrimary
          : primary
          ? btnClrSeconday
          : btnClrPrimary
      }
      textAlign="center"
      fontFamily="Plus Jakarta Sans"
      fontSize="13px"
      fontStyle="normal"
      fontWeight="600"
      lineHeight="normal"
      border={
        border
          ? `1px solid ${border}`
          : primary
          ? undefined
          : `1px solid ${btnClrPrimary}`
      }
      _hover={{
        background: bg ? bg : primary ? btnClrPrimary : btnClrSeconday,
      }}
      // _active={{
      //   background: "#555",
      // }}
      borderColor={border}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
