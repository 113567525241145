import React, { useRef, useState } from "react";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  useColorModeValue,
  Flex,
  Image,
  useToast,
  useOutsideClick,
} from "@chakra-ui/react";

//importing currency icons for dropdown
import solano from "../../assets/icons/general/crypto/solano.svg";
import binance from "../../assets/icons/general/crypto/binance.svg";
import eth from "../../assets/icons/general/crypto/eth.svg";
import ton from "../../assets/icons/general/crypto/ton.svg";
import trx from "../../assets/icons/general/crypto/trx.svg";
import usdc from "../../assets/icons/general/crypto/usdc.svg";
import usdt from "../../assets/icons/general/crypto/usdt.svg";
import { useExportData } from "../../utils/file-export.api";

const Dropdown = ({
  isOpen,
  onClose,
  onOpen,
  selectedOption,
  setSelectedOption,
  options,
  positionTop,
  positionRight,
  positionLeft,
  left,
  sub,
  subTransaction,
  subSearch,
  searchDropDownLimit,
  onSelect,
  type,
  pending,
  coinId,
  userId,
  transactionalType,
  searchType,
  search,
  status,
  subHome,
  kycSearch,
}: any) => {
  const toast = useToast();

  const dropdownRef = useRef<any>(null);

  const bgColor = useColorModeValue("#FFF", "#000");
  const hoverColor = useColorModeValue("#67C475", "#00FFE0");
  const hoverColorText = useColorModeValue("#FFF", "#000");
  const textColor = useColorModeValue("#000", "#FFF");

  const optionsTemp = [
    // "Custom",
    "24 hours",
    "This week",
    "Last week",
    "Two weeks ago",
    "This month",
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last 12 months",
    "All time",
  ];
  const downloadOption = ["CSV"];
  const transactionType = [
    "Deposits",
    "Withdrawals",
    "Transfers",
    "Swaps",
    "Utilities",
  ];

  const transactionTypePending = ["Deposits", "Withdrawals", "Transfers"];

  const currencyType = [
    { title: "NGN" },
    { title: "USDT", icon: usdt },
    { title: "TON", icon: ton },
    { title: "BTC", icon: binance },
    { title: "ETH", icon: eth },
    { title: "TRX", icon: trx },
    { title: "SOL", icon: solano },
    { title: "USDC", icon: usdc },
  ];
  const transactionStatus = ["Completed", "Failed"];

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const exportData = useExportData();

  const handleDownload = async (option: any, index: any) => {
    // Debug log to check values before calling mutateAsync
    console.log("handleDownload values:", {
      type,
      transactionalType,
      searchType,
      search,
    });

    // determine download type based on option
    const downloadType = option === "PDF" ? "pdf" : "csv";

    // determine period based on index
    const selectedPeriod = optionsTemp[index];

    // call exportData
    exportData
      .mutateAsync({
        type: type,
        period: selectedPeriod,
        downloadType,
        // coinId: coinId ? coinId : "",
        // userId: userId ? userId : "",
        ...(coinId && typeof coinId === "string" && { coinId }),
        ...(userId && typeof userId === "string" && { userId }),

        ...(transactionalType &&
          typeof transactionalType === "string" && { transactionalType }),
        ...(searchType && typeof searchType === "string" && { searchType }),
        ...(search && typeof search === "string" && { search }),
        ...(status && typeof status === "string" && { status }),
      })
      .then((result) => {
        // console.log("Download result:", result);
        if (downloadType === "csv" && typeof result === "string") {
          // handle CSV download
          const blob = new Blob([result], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          let downloadFileName = `${type}`;

          if (transactionalType && typeof transactionalType === "string") {
            downloadFileName += `-${transactionalType}`;
          }

          if (status && typeof status === "string") {
            downloadFileName += `-${status}`;
          }

          link.download = `${downloadFileName}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (downloadType === "pdf") {
          // console.log("result pdf---------->", result);
          // handle PDF download
          const blob = new Blob([result], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "data.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        toast({
          title: err?.message || "An error occurred while downloading the file",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => onClose(),
  });

  return (
    <Menu isOpen={isOpen}>
      <MenuList
        bg={bgColor}
        color={textColor}
        borderRadius="5px"
        position={"absolute"}
        zIndex={999999999999}
        top={positionTop ? positionTop : "50px"}
        right={positionRight}
        left={positionLeft}
        ref={dropdownRef}
      >
        {(!options ? optionsTemp : options).map(
          (option: any, index: number) => (
            <Box
              key={index}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              position="relative"
            >
              <MenuItem
                bg={hoverIndex === index ? hoverColor : bgColor}
                color={hoverIndex === index ? hoverColorText : textColor}
                onClick={() => {
                  setSelectedOption(option);
                  if (!subSearch && !subHome && !kycSearch) {
                    onSelect("searchType", option);
                  }

                  if (index === 3 && subSearch) {
                    onSelect("searchType", "email");
                  } else if (index === 4 && subSearch) {
                    onSelect("searchType", "amount");
                  } else if (index === 6 && subSearch) {
                    onSelect("searchType", "number");
                  }

                  if (index === 3 && subHome) {
                    onSelect("searchType", "email");
                  } else if (index === 4 && subHome) {
                    onSelect("searchType", "amount");
                  } else if (index === 5 && subHome) {
                    onSelect("searchType", "number");
                  }

                  if (index === 1 && kycSearch) {
                    onSelect("searchType", "device");
                  } else if (index === 2 && kycSearch) {
                    onSelect("searchType", "version");
                  } else if (index === 3 && kycSearch) {
                    onSelect("searchType", "email");
                  } else if (index === 4 && kycSearch) {
                    onSelect("searchType", "transactions");
                  } else if (index === 5 && kycSearch) {
                    onSelect("searchType", "location");
                  } else if (index === 6 && kycSearch) {
                    onSelect("searchType", "number");
                  } else if (index === 7 && kycSearch) {
                    onSelect("searchType", "first");
                  } else if (index === 8 && kycSearch) {
                    onSelect("searchType", "last");
                  } else if (index === 9 && kycSearch) {
                    onSelect("searchType", "user");
                  } else if (index === 10 && kycSearch) {
                    onSelect("searchType", "access");
                  } else if (index === 11 && kycSearch) {
                    onSelect("searchType", "address");
                  } else if (index === 12 && kycSearch) {
                    onSelect("searchType", "referral");
                  }

                  onClose();
                }}
                _hover={{ bg: hoverColor, color: hoverColorText }}
              >
                {option}
              </MenuItem>

              {/* here is sub menu for csv and pdf */}
              {sub && hoverIndex === index && (
                <MenuList
                  position="absolute"
                  right={left ? 0 : undefined}
                  left={left ? undefined : "223px"}
                  top="0"
                  bg={bgColor}
                  color={textColor}
                  borderRadius="5px"
                  zIndex={"9000"}
                >
                  {downloadOption.map((option: any, idx: number) => (
                    <MenuItem
                      key={idx}
                      bg={bgColor}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(option, index);
                        onClose();
                      }}
                      _hover={{ bg: hoverColor, color: hoverColorText }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              )}

              {/* sub menu for transction filter, transaction type and currency */}
              {subTransaction && hoverIndex === index && (
                <MenuList
                  position="absolute"
                  right={left ? 0 : undefined}
                  left={left ? undefined : "223px"}
                  top="0"
                  bg={bgColor}
                  color={textColor}
                  borderRadius="5px"
                  zIndex={"9000"}
                >
                  {index === 0 &&
                    (pending
                      ? transactionTypePending.map(
                          (option: any, idx: number) => (
                            <MenuItem
                              key={idx}
                              bg={bgColor}
                              onClick={() => {
                                onSelect("type", option);
                                onClose();
                              }}
                              _hover={{
                                bg: hoverColor,
                                color: hoverColorText,
                              }}
                            >
                              {option}
                            </MenuItem>
                          )
                        )
                      : transactionType.map((option: any, idx: number) => (
                          <MenuItem
                            key={idx}
                            bg={bgColor}
                            onClick={() => {
                              onSelect("type", option);
                              onClose();
                            }}
                            _hover={{ bg: hoverColor, color: hoverColorText }}
                          >
                            {option}
                          </MenuItem>
                        )))}
                  {index === 1 &&
                    currencyType.map((option: any, idx: number) => (
                      <MenuItem
                        key={idx}
                        bg={bgColor}
                        onClick={() => {
                          onSelect("coinName", option.title);
                          onClose();
                        }}
                        _hover={{ bg: hoverColor, color: hoverColorText }}
                      >
                        <Flex align={"center"} gap={"8px"} key={idx}>
                          {option?.icon && (
                            <Image
                              src={option?.icon}
                              width={"18px"}
                              height={"18px"}
                              alt={"currency"}
                            />
                          )}
                          {option?.title}
                        </Flex>
                      </MenuItem>
                    ))}
                </MenuList>
              )}

              {/* sub menu for Search filter, on which base user wants to search */}
              {subSearch &&
                hoverIndex !== 3 &&
                hoverIndex !== 4 &&
                hoverIndex !== 6 &&
                hoverIndex === index && (
                  <MenuList
                    position="absolute"
                    right={left ? 0 : undefined}
                    left={left ? undefined : "223px"}
                    top="0"
                    bg={bgColor}
                    color={textColor}
                    borderRadius="5px"
                    zIndex={"9000"}
                  >
                    {index === 0 &&
                      optionsTemp.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            // handleDownload(option, index);
                            onSelect("period", option);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          {option}
                        </MenuItem>
                      ))}

                    {index === 1 &&
                      (pending
                        ? transactionTypePending.map(
                            (option: any, idx: number) => (
                              <MenuItem
                                key={idx}
                                bg={bgColor}
                                onClick={() => {
                                  onSelect("type", option);
                                  onClose();
                                }}
                                _hover={{
                                  bg: hoverColor,
                                  color: hoverColorText,
                                }}
                              >
                                {option}
                              </MenuItem>
                            )
                          )
                        : transactionType.map((option: any, idx: number) => (
                            <MenuItem
                              key={idx}
                              bg={bgColor}
                              onClick={() => {
                                onSelect("type", option);
                                onClose();
                              }}
                              _hover={{ bg: hoverColor, color: hoverColorText }}
                            >
                              {option}
                            </MenuItem>
                          )))}

                    {index === 2 &&
                      currencyType.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            onSelect("coinName", option.title);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          <Flex align={"center"} gap={"8px"} key={idx}>
                            {option?.icon && (
                              <Image
                                src={option?.icon}
                                width={"18px"}
                                height={"18px"}
                                alt={"currency"}
                              />
                            )}
                            {option?.title}
                          </Flex>
                        </MenuItem>
                      ))}

                    {index === 5 &&
                      transactionStatus.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            onSelect("transactionStatus", option);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                  </MenuList>
                )}

              {subHome &&
                hoverIndex !== 3 &&
                hoverIndex !== 4 &&
                hoverIndex !== 5 &&
                hoverIndex === index && (
                  <MenuList
                    position="absolute"
                    right={left ? 0 : undefined}
                    left={left ? undefined : "223px"}
                    top="0"
                    bg={bgColor}
                    color={textColor}
                    borderRadius="5px"
                    zIndex={"9000"}
                  >
                    {index === 0 &&
                      optionsTemp.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            // handleDownload(option, index);
                            onSelect("period", option);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          {option}
                        </MenuItem>
                      ))}

                    {index === 1 &&
                      (pending
                        ? transactionTypePending.map(
                            (option: any, idx: number) => (
                              <MenuItem
                                key={idx}
                                bg={bgColor}
                                onClick={() => {
                                  onSelect("type", option);
                                  onClose();
                                }}
                                _hover={{
                                  bg: hoverColor,
                                  color: hoverColorText,
                                }}
                              >
                                {option}
                              </MenuItem>
                            )
                          )
                        : transactionType.map((option: any, idx: number) => (
                            <MenuItem
                              key={idx}
                              bg={bgColor}
                              onClick={() => {
                                onSelect("type", option);
                                onClose();
                              }}
                              _hover={{ bg: hoverColor, color: hoverColorText }}
                            >
                              {option}
                            </MenuItem>
                          )))}

                    {index === 2 &&
                      currencyType.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            onSelect("coinName", option.title);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          <Flex align={"center"} gap={"8px"} key={idx}>
                            {option?.icon && (
                              <Image
                                src={option?.icon}
                                width={"18px"}
                                height={"18px"}
                                alt={"currency"}
                              />
                            )}
                            {option?.title}
                          </Flex>
                        </MenuItem>
                      ))}
                  </MenuList>
                )}

              {kycSearch &&
                hoverIndex !== 1 &&
                hoverIndex !== 2 &&
                hoverIndex !== 3 &&
                hoverIndex !== 4 &&
                hoverIndex !== 5 &&
                hoverIndex !== 6 &&
                hoverIndex !== 7 &&
                hoverIndex !== 8 &&
                hoverIndex !== 9 &&
                hoverIndex !== 10 &&
                hoverIndex !== 11 &&
                hoverIndex !== 12 &&
                hoverIndex === index && (
                  <MenuList
                    position="absolute"
                    right={left ? 0 : undefined}
                    left={left ? undefined : "223px"}
                    top="0"
                    bg={bgColor}
                    color={textColor}
                    borderRadius="5px"
                    zIndex={"9000"}
                  >
                    {index === 0 &&
                      optionsTemp.map((option: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          bg={bgColor}
                          onClick={() => {
                            // handleDownload(option, index);
                            onSelect("period", option);
                            onClose();
                          }}
                          _hover={{ bg: hoverColor, color: hoverColorText }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                  </MenuList>
                )}
            </Box>
          )
        )}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
