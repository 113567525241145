import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SecondaryButton from "../../form-components/secondary-button";
import CustomInputForm from "../form-data/custom-input";
import {
  useGetAccountDetails,
  useUpdateAccountDetails,
} from "../../../utils/wallet.api";
import { useGetLoggedInUser } from "../../../utils/auth.api";

const BankDetailCard = () => {
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue("#E4E4E4", "#000");
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [payload, setPayload] = useState({
    name: "",
    bank_name: "",
    account_number: "",
    password: "",
  });

  const { data: accountDetails, isLoading } = useGetAccountDetails();
  const { data: user } = useGetLoggedInUser();
  const updateAccountDetails = useUpdateAccountDetails();

  const clearFields = () => {
    setPayload({
      name: "",
      bank_name: "",
      account_number: "",
      password: "",
    });
    onClose();
  };

  const handleUpdateAccountDetails = () => {
    if (
      !payload.name ||
      !payload.bank_name ||
      !payload.account_number ||
      !payload.password
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateAccountDetails
      ?.mutateAsync({
        payload,
        id: accountDetails?.id,
      })
      .then((result) => {
        toast({
          title: "Account details updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        clearFields();
      })
      .catch((err) => {
        toast({
          title: err?.message || "Error updating account details",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      width={"318px"}
      height={"188px"}
      borderRadius={"10px"}
      bg={bgColor}
      direction={"column"}
      gap={"17px"}
      p={"23px 18px"}
      _hover={{
        border: "1px solid #00FFE0",
      }}
    >
      <Text
        width={"140px"}
        color={textColor}
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"19px"}
        fontWeight={700}
        lineHeight={"normal"}
      >
        Bank Details
      </Text>

      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <>
          <Text
            color={textColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"14px"}
            fontWeight={500}
            lineHeight={"normal"}
          >
            {accountDetails?.account_number || ""};{" "}
            {accountDetails?.bank_name || ""}
          </Text>
          <Text
            color={textColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"14px"}
            fontWeight={500}
            lineHeight={"normal"}
            mt="-10px"
          >
            {accountDetails?.name || ""}
          </Text>
        </>
      )}
      {(user?.isSuperAdmin || user?.permissions?.updateBanksDetails) && (
        <Flex gap={"20px"} alignItems={"center"} mt={"10px"}>
          <SecondaryButton
            text={"Remove"}
            bg={btnSecondaryBG}
            // border={"#000"}

            border={"#00FFE0"}
            textColor={textColor}
          />
          <SecondaryButton
            text={"Update"}
            primary
            bg={btnPrimaryBG}
            border={"#00FFE0"}
            textColor={"#000"}
            onClick={onOpen}
          />
        </Flex>
      )}

      {/* 2 FA screen modal */}
      <Modal isOpen={isOpen} onClose={clearFields} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pl={"70px"} py={"45px"} bg={bgColor}>
            <Flex direction={"column"} gap={"23px"}>
              {/* change email */}
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"25px"}
              >
                Update Bank details (Super Admin)
              </Text>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Account name"
                  inputType={"text"}
                  value={payload?.name || ""}
                  onChange={(e: any) => {
                    setPayload({ ...payload, name: e.target.value });
                  }}
                />
                <CustomInputForm
                  labelText="Bank"
                  inputType={"text"}
                  value={payload?.bank_name}
                  onChange={(e: any) => {
                    setPayload({ ...payload, bank_name: e.target.value });
                  }}
                />
              </Flex>
              <Flex alignItems={"center"} gap={"20px"}>
                <CustomInputForm
                  labelText="Account number"
                  inputType={"text"}
                  value={payload?.account_number || ""}
                  onChange={(e: any) =>
                    setPayload({ ...payload, account_number: e.target.value })
                  }
                />
                <CustomInputForm
                  labelText="Password"
                  inputType={"password"}
                  value={payload?.password || ""}
                  onChange={(e: any) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                />
              </Flex>
              <Flex gap={"20px"} alignItems={"center"}>
                <SecondaryButton
                  text={"Cancel"}
                  bg={btnSecondaryBG}
                  // border={"#000"}
                  border={btnSecondaryBG}
                  textColor={textColor}
                  isDisabled={updateAccountDetails.isPending}
                  onClick={clearFields}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  textColor={"#000"}
                  isDisabled={updateAccountDetails.isPending}
                  isLoading={updateAccountDetails.isPending}
                  onClick={handleUpdateAccountDetails}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default BankDetailCard;
