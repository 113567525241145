import React, { useEffect, useState } from "react";
import { useGetUserInfoGraph } from "../../utils/wallet.api";
import WorldMap from "react-svg-worldmap";
import { Box } from "@chakra-ui/react";

const MapChart = () => {
  const [newMarkers, setNewMarkers] = useState([]);

  const { data: userLocations } = useGetUserInfoGraph({
    period: "MAX",
    dateFilterFor: "location",
  });

  const refactorMarkers = (locations) => {
    if (locations) {
      const tempMarkers = locations?.map((location) => {
        return {
          country: location?._id,
          value: location?.count,
        };
      });
      setNewMarkers(tempMarkers);
    }
  };

  useEffect(() => {
    refactorMarkers(userLocations?.locations);
    // eslint-disable-next-line
  }, [userLocations]);

  return (
    <Box width="100%" height="550px" display="flex" justifyContent="center">
      <WorldMap
        color="green"
        backgroundColor="transparent"
        value-suffix="people"
        size="xl"
        data={newMarkers}
      />
    </Box>
  );
};

export default MapChart;
