import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

import StatsCard from "../../components/cards/stats-card";
import FilterCard from "../../components/cards/filter-stats-card";

import userIconLight from "../../assets/icons/general/user-icon-light.svg";
import userIconDark from "../../assets/icons/general/user-icon-dark.svg";
import locationLight from "../../assets/icons/light/location.svg";
import deviceLight from "../../assets/icons/light/device.svg";
import locationDark from "../../assets/icons/dark/location.svg";
import deviceDark from "../../assets/icons/dark/device.svg";
import PendingLevelCard from "../../components/cards/pending-level-req-card";
import LineChartWithFiltersUser from "../../components/gaphs/linechart-user";
import SideBarTransactionHistory from "../../components/cards/sidebar-transaction";
import UserDirectoryCard from "../../components/cards/user-directory-card";
import SearchbarKyc from "../../components/searchbar-filters/searchbar-kyc";
import Pagination from "../../components/general/pagination";
import {
  useGetAllUserList,
  useGetKyc3UsersVerifyRecords,
  useGetUsersStats,
} from "../../utils/user.api";
import { useGetLoggedInUser } from "../../utils/auth.api";
import { useNavigate } from "react-router-dom";

const User = () => {
  const navigate = useNavigate();

  //for user directory
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchUser, setSearchUser] = useState("");
  const [periodUser, setPeriodUser] = useState("All time");
  const [searchTypeUser, setSearchTypeUser] = useState("userEmail");
  //for kyc directory
  const [offsetKyc, setOffsetKyc] = useState(0);
  const [limitKyc, setLimitKyc] = useState(10);
  const [searchKyc, setSearchKyc] = useState("");
  const [periodKyc, setPeriodKyc] = useState("All time");
  const [searchTypeKyc, setSearchTypeKyc] = useState("userEmail");
  const {
    data: users,
    refetch: refetchUsers,
    isLoading,
    error,
  } = useGetAllUserList({
    offset,
    limit,
    searchUser,
    periodUser,
    searchTypeUser,
  });

  const {
    data: kycPending,
    isLoading: kycLoading,
    refetch: refetchKYC,
  } = useGetKyc3UsersVerifyRecords({
    offsetKyc,
    limitKyc,
    searchKyc,
    periodKyc,
    searchTypeKyc,
  });

  const { data: user, isLoading: userLoading } = useGetLoggedInUser();
  const { data: statsResponse } = useGetUsersStats("1D");

  // state to hold the selected user ID
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  //for now it is for test purpose so true and false
  const [tab, setTab] = useState("directory");
  const handleTab = (searchType: string) => {
    setTab(searchType);
    setSelectedUser("");
  };
  // const textColor = useColorModeValue("#000", "#FFF");

  const handleCardSelect = (data: any) => {
    setSelectedUser(data);
  };

  const handleFilterChangeUser = (filter: string, value: string) => {
    switch (filter) {
      case "searchType":
        if (value.toLowerCase().includes("email")) {
          setSearchTypeUser("userEmail");
        } else if (value.toLowerCase().includes("device")) {
          setSearchTypeUser("device");
        } else if (value.toLowerCase().includes("version")) {
          setSearchTypeUser("appVersion");
        } else if (value.toLowerCase().includes("transactions")) {
          setSearchTypeUser("totalTransactions");
        } else if (value.toLowerCase().includes("location")) {
          setSearchTypeUser("location");
        } else if (value.toLowerCase().includes("number")) {
          setSearchTypeUser("phoneNumber");
        } else if (value.toLowerCase().includes("first")) {
          setSearchTypeUser("firstName");
        } else if (value.toLowerCase().includes("last")) {
          setSearchTypeUser("lastName");
        } else if (value.toLowerCase().includes("user")) {
          setSearchTypeUser("userLevel");
        } else if (value.toLowerCase().includes("access")) {
          setSearchTypeUser("accessLevel");
        } else if (value.toLowerCase().includes("address")) {
          setSearchTypeUser("ipAddress");
        } else if (value.toLowerCase().includes("referral")) {
          setSearchTypeUser("referralCode");
        } else {
          setSearchTypeUser("");
        }
        break;
      case "period":
        setPeriodUser(value);
        break;
      default:
        break;
    }
  };

  const handleFilterChangeKyc = (filter: string, value: string) => {
    switch (filter) {
      case "searchType":
        if (value.toLowerCase().includes("email")) {
          setSearchTypeKyc("userEmail");
        } else if (value.toLowerCase().includes("device")) {
          setSearchTypeKyc("device");
        } else if (value.toLowerCase().includes("version")) {
          setSearchTypeKyc("appVersion");
        } else if (value.toLowerCase().includes("transactions")) {
          setSearchTypeKyc("totalTransactions");
        } else if (value.toLowerCase().includes("location")) {
          setSearchTypeKyc("location");
        } else if (value.toLowerCase().includes("number")) {
          setSearchTypeKyc("phoneNumber");
        } else if (value.toLowerCase().includes("first")) {
          setSearchTypeKyc("firstName");
        } else if (value.toLowerCase().includes("last")) {
          setSearchTypeKyc("lastName");
        } else if (value.toLowerCase().includes("user")) {
          setSearchTypeKyc("userLevel");
        } else if (value.toLowerCase().includes("access")) {
          setSearchTypeKyc("accessLevel");
        } else if (value.toLowerCase().includes("address")) {
          setSearchTypeKyc("ipAddress");
        } else if (value.toLowerCase().includes("referral")) {
          setSearchTypeKyc("referralCode");
        } else {
          setSearchTypeKyc("");
        }
        break;
      case "period":
        setPeriodKyc(value);
        break;
      default:
        break;
    }
  };
  ///stats
  //stats icon
  const userIcon = useColorModeValue(userIconLight, userIconDark);
  const locationIcon = useColorModeValue(locationLight, locationDark);
  const deviceIcon = useColorModeValue(deviceLight, deviceDark);
  //stats dummy data
  const [statsData, setStatsData] = useState([
    {
      title: "Total Users",
      stats: "0",
      image: userIcon,
      nav: "/user",
      type: "users",
    },
    {
      title: "Locations",
      stats: "0",
      image: locationIcon,
      nav: "/user/location",
      type: "locations",
    },
    {
      title: "Devices",
      stats: "0",
      image: deviceIcon,
      nav: "/user/device",
      type: "devices",
    },
  ]);

  useEffect(() => {
    refetchUsers();
    // refetchKYC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, searchUser, searchTypeUser, periodUser]);

  useEffect(() => {
    refetchKYC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitKyc, offsetKyc, searchKyc, searchTypeKyc, periodKyc]);

  useEffect(() => {
    if (!userLoading && !user?.permissions?.userDirectory) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (statsResponse) {
      setStatsData([
        {
          title: "Total Users",
          stats: statsResponse.totalUsers || "0",
          image: userIcon,
          nav: "/user",
          type: "users",
        },
        {
          title: "Locations",
          stats: statsResponse.locations || "0",
          image: locationIcon,
          nav: "/user/location",
          type: "users",
        },
        {
          title: "Devices",
          stats: statsResponse.devices || "0",
          image: deviceIcon,
          nav: "/user/device",
          type: "users",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsResponse]);

  // users?.walletWithBalance?.totalBalanceInNaira
  return (
    <AdminLayout>
      <Flex direction={"column"} gap={"58px"}>
        {/* top stats row */}
        <Flex
          gap={"26px"}
          justifyContent={"flex-start"}
          alignItems={{ base: undefined, md: "center" }}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
        >
          <FilterCard type={"users"} active />
          {statsData?.map((stat: any, index: number) => (
            <StatsCard
              key={index}
              title={stat.title}
              stats={stat.stats}
              image={stat.image}
              nav={stat.nav}
              type={stat.type}
            />
          ))}
        </Flex>

        {/* pending req and kyc tabs and crypto userdetail */}

        <Flex gap={"23px"} direction={{ base: "column", md: "row" }}>
          {/* pending req */}

          <Box
            overflowX={{ base: "auto", md: undefined }}
            width={{ base: "100%", md: "100%" }}
          >
            <Flex
              gap={"6px"}
              width={"100%"}
              direction={"column"}
              // maxWidth={"892px"}
            >
              <LineChartWithFiltersUser />

              <Flex gap={"10px"} alignItems={"center"} mb={"-6px"}>
                <SearchbarKyc
                  onClick={handleTab}
                  onChangeUser={(e: any) => setSearchUser(e)}
                  onChangeKyc={(e: any) => setSearchKyc(e)}
                  onFilterChangeKyc={handleFilterChangeKyc}
                  onFilterChangeUser={handleFilterChangeUser}
                />
              </Flex>
              {tab === "kyc" && (
                <>
                  {kycLoading ? (
                    <Text>Loading...</Text>
                  ) : kycPending && kycPending?.records?.length > 0 ? (
                    kycPending?.records?.map((item: any, index: number) => (
                      <PendingLevelCard
                        data={item}
                        key={index}
                        onClick={handleCardSelect}
                      />
                    ))
                  ) : (
                    <Text>No KYC Pending Data</Text>
                  )}
                  <Box>
                    <Pagination
                      total={kycPending?.totalCount || 0}
                      limit={limitKyc}
                      offset={offsetKyc}
                      onLimitChange={setLimitKyc}
                      onPageChange={setOffsetKyc}
                    />
                  </Box>
                </>
              )}
              {tab === "directory" && (
                <>
                  {isLoading ? (
                    <Text>Loading...</Text>
                  ) : error ? (
                    <Text>Error loading users</Text>
                  ) : (
                    users?.users?.map((user: any, index: number) => (
                      <UserDirectoryCard
                        data={user}
                        onClick={handleCardSelect}
                      />
                    ))
                  )}
                  <Box>
                    <Pagination
                      total={users?.totalCount || 0}
                      limit={limit}
                      offset={offset}
                      onLimitChange={setLimit}
                      onPageChange={setOffset}
                    />
                  </Box>
                </>
              )}
            </Flex>
          </Box>

          <SideBarTransactionHistory user={selectedUser} />
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default User;
