import {
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import LogoLight from "../../assets/payremit-logo(s)/payremit-admin-light.svg";
import LogoDark from "../../assets/payremit-logo(s)/payremit-admin-dark.svg";
import theme from "../../theme/theme";

const AuthCardHeader = ({
  widthHeading,
  widthSubheading,
  headingSize,
  subheadingSize,
  subheadingWeight,
  title,
  des,
  imgSrc,
  imageW,
  desColor,
  mbImg,
  textalign,
}: any) => {
  const src = useColorModeValue(LogoLight, LogoDark);
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);
  const parts = des?.split(/({[^}]+})/);
  const partsColor = useColorModeValue("#5A5A5A", "#EEE");

  return (
    <Flex
      justify={"center"}
      align={"center"}
      direction={"column"}
      gap="7px"
      mb="48px"
    >
      <Image mb={mbImg || "41px"} src={imgSrc || src} w={imageW} />

      <Heading
        width={widthHeading}
        color={color}
        fontFamily={"Audiowide"}
        fontSize={headingSize ? headingSize : "24px"}
        fontWeight={"400"}
        textAlign={"center"}
      >
        {title}
      </Heading>
      <Text
        width={widthSubheading}
        fontSize={subheadingSize ? subheadingSize : "14px"}
        fontWeight={subheadingWeight ? subheadingWeight : 400}
        textAlign={textalign}
        //letterSpacing={"0.035px"}
        //  fontSize="14px"

        color={desColor}
      >
        {parts?.map((part: any, index: number) => {
          if (part.startsWith("{") && part.endsWith("}")) {
            return (
              <Text
                width={widthSubheading}
                as="span"
                fontSize={subheadingSize}
                display="inline"
                color={partsColor}
                fontWeight={subheadingWeight ? subheadingWeight : 700}
                key={index}
              >
                {part.substring(1, part.length - 1)}
              </Text>
            );
          }
          return part;
        })}
      </Text>
    </Flex>
  );
};

export default AuthCardHeader;
