import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SecondaryButton from "../../form-components/secondary-button";
import TwoFactorEnable from "../../auth/2fa-new";
import PrimaryButton from "../../form-components/PrimaryButton";
import {
  useEnable2Fa,
  useEnable2FaOtp,
  useUpdateProfile,
} from "../../../utils/user.api";
import { useGetLoggedInUser } from "../../../utils/auth.api";
import CustomInputForm from "../form-data/custom-input";
import QRCode from "react-qr-code";

const TwoFactorCard = () => {
  const toast = useToast();

  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue("#E4E4E4", "#000");
  const textColorAction = useColorModeValue("#67C475", "#00FFE0");
  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");

  const [pin, setPin] = useState("");

  const { data: twoFAInfo } = useEnable2Fa();
  const { data: user } = useGetLoggedInUser();
  const enableOTP = useEnable2FaOtp();
  const updateUser = useUpdateProfile();

  const {
    isOpen: isTwoFAModalOpen,
    onOpen: onTwoFAModalOpen,
    onClose: onTwoFAModalClose,
  } = useDisclosure();

  const handleModalClose = () => {
    onTwoFAModalClose();
    setPin("");
  };

  const handle2FASubmit = async () => {
    if (pin === "") {
      toast({
        title: "Please enter OTP",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (pin.length > 6 || pin.length < 6) {
      toast({
        title: "Invalid OTP",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    enableOTP
      ?.mutateAsync(pin)
      .then((result: any) => {
        if (result?.isValid && user?.is2FAEnabled) {
          updateUser.mutateAsync({ is2FAEnabled: false });
          toast({
            title: "2FA disabled successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          handleModalClose();
        } else if (result?.isValid) {
          toast({
            title: "2FA enabled successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          handleModalClose();
        } else {
          toast({
            title: "Invalid OTP",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      width={"318px"}
      height={"188px"}
      borderRadius={"10px"}
      bg={bgColor}
      direction={"column"}
      gap={"17px"}
      p={"23px 18px"}
      _hover={{
        border: "1px solid #00FFE0",
      }}
    >
      <Text
        width={"140px"}
        color={textColor}
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"19px"}
        fontWeight={700}
        lineHeight={"normal"}
      >
        Two-Factor Authentication
      </Text>

      <Text
        width={"150px"}
        color={textColor}
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"14px"}
        fontWeight={500}
        lineHeight={"normal"}
      >
        2FAStatus:{" "}
        <span
          style={{
            fontWeight: 700,
            color: user?.is2FAEnabled ? textColorAction : "red",
          }}
        >
          {user?.is2FAEnabled ? "Enabled" : "Disabled"}
        </span>
      </Text>

      <Flex gap={"20px"} alignItems={"center"} mt={"10px"}>
        <SecondaryButton
          text={"Disable 2FA"}
          bg={btnSecondaryBG}
          // border={"#000"}
          border={"#00FFE0"}
          textColor={textColor}
          isDisabled={!user?.is2FAEnabled}
          onClick={onTwoFAModalOpen}
        />
        <SecondaryButton
          text={"Enable 2FA"}
          primary
          bg={btnPrimaryBG}
          border={"#00FFE0"}
          textColor={"#000"}
          onClick={onTwoFAModalOpen}
          isDisabled={user?.is2FAEnabled}
        />
      </Flex>

      {/* 2 FA screen modal */}
      <Modal isOpen={isTwoFAModalOpen} onClose={handleModalClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={bgColor} borderBottom={`1px solid #333333`}>
            {user?.is2FAEnabled ? "Disable" : "Enable"} Two-Factor
            Authentication
          </ModalHeader>
          <ModalCloseButton
            bg={"#00FFE0"}
            borderRadius={"100px"}
            color={"black"}
          />
          <ModalBody justifyContent={"center"} py={"45px"} bg={bgColor}>
            <Flex direction={"column"} gap={"15px"} justifyContent={"center"}>
              {!user?.is2FAEnabled && (
                <>
                  <Box mx="auto">
                    <QRCode
                      value={twoFAInfo?.authUrl || ""}
                      bgColor={bgColor}
                      size={150}
                    />
                  </Box>
                  <Text color={textColor} fontSize="14px" fontWeight="600">
                    Secret Key
                  </Text>
                  <CustomInputForm
                    width="100%"
                    inputType={"text"}
                    value={twoFAInfo?.authSecretText || ""}
                  />
                </>
              )}
              <Text
                color={textColor}
                fontSize="14px"
                fontWeight="600"
                mt="10px"
              >
                Verification Code
              </Text>
              {/* <TwoFactorAuthentication /> */}
              <TwoFactorEnable pinInput={pin} setPinInput={setPin} />
            </Flex>
          </ModalBody>
          <ModalFooter bg={bgColor} borderTop={`1px solid #333333`}>
            <Flex alignItems={"center"} gap={"20px"} alignSelf={"flex-end"}>
              <PrimaryButton
                text={"Cancel"}
                type="submit"
                width={"150px"}
                height={"40px"}
                mt={"0px"}
                secondary
                isDisabled={enableOTP?.isPending}
                onClick={handleModalClose}
              />

              <PrimaryButton
                text={"Verify Now"}
                type="submit"
                width={"150px"}
                height={"40px"}
                mt={"0px"}
                isDisabled={enableOTP?.isPending}
                isLoading={enableOTP?.isPending}
                onClick={handle2FASubmit}
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TwoFactorCard;
