import { Flex, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../form-components/dropdown-gen";
import CustomDropdownAdmin from "./dropdown-admin";
import SecondaryButton from "../form-components/secondary-button";
import {
  useGiveAdminAccess,
  useUpdateAdminPermission,
} from "../../utils/auth.api";

function AdminRoleCard({ admin }: any) {
  const updateAdminPermission = useUpdateAdminPermission();
  const updateAdminAccess = useGiveAdminAccess();

  const toast = useToast();
  //for now it is for test purpose so true and false
  const textColor = useColorModeValue("#000", "#FFF");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");

  const [options, setOptions] = useState([
    {
      titlePage: "Home",
      titleAccess: "Give Access",
      key: "home",
    },
    {
      titlePage: "Transactions",
      titleAccess: "Give Access",
      key: "transactions",
    },
    {
      titlePage: "Rate settings",
      titleAccess: "Give Access",
      key: "rateSettings",
    },
    {
      titlePage: "User directory",
      titleAccess: "Give Access",
      key: "userDirectory",
    },
    {
      titlePage: "Support",
      titleAccess: "Give Access",
      key: "support",
    },
    {
      titlePage: "Settings",
      titleAccess: "Give Access",
      key: "settings",
    },
    {
      titlePage: "Update Bank details",
      titleAccess: "Give Access",
      key: "updateBanksDetails",
    },
    {
      titlePage: "Create CUSTOM referral code",
      titleAccess: "Give Access",
      key: "createCustomCodeChange",
    },
    {
      titlePage: "Add new Admin",
      titleAccess: "Give Access",
      key: "addNewAdmin",
    },
  ]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(admin?.isSuperAdmin);

  const handleUpdatePermissions = () => {
    const permissions = options.reduce((acc: any, option) => {
      acc[option.key] = option.titleAccess === "Give Access" ? true : false;
      return acc;
    }, {});
    updateAdminPermission
      .mutateAsync({
        userId: admin?.id,
        ...permissions,
        isSuperAdmin,
      })
      .then((result) => {
        toast({
          title: "Permissions updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: err?.message || "Error updating permissions",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (admin?.permissions) {
      const newOptions = options.map((option: any) => {
        const access = admin?.permissions?.[option.key];
        return {
          ...option,
          titleAccess: access ? "Revoke Access" : "Give Access",
        };
      });
      setOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin?.permissions]);

  return (
    <Flex alignItems={"center"} gap={"20px"}>
      <Text
        width={"160px"}
        color={textColor}
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"17px"}
        fontWeight={700}
      >
        {admin?.fullname || admin?.email?.split("@")?.[0] || ""}
      </Text>
      <CustomDropdown
        placeholder={isSuperAdmin ? "Super Admin" : "Admin"}
        options={["Admin", "Super Admin", "Support", "Custom"]}
        handleChange={(value: string) => {
          if (value === "Super Admin") {
            setIsSuperAdmin(true);
          } else {
            setIsSuperAdmin(false);
          }
        }}
      />
      <CustomDropdownAdmin
        placeholder={"Privileges"}
        options={options}
        handleChange={(value: any) => {
          setOptions((prev) => {
            const newOptions = [...prev];
            newOptions[value.index] = {
              ...newOptions[value.index],
              titleAccess: value.value,
            };
            return newOptions;
          });
        }}
      />
      <SecondaryButton
        text={admin?.isActive ? "Revoke" : "Make Admin"}
        isDisabled={
          updateAdminPermission?.isPending || updateAdminAccess?.isPending
        }
        isLoading={updateAdminAccess?.isPending}
        onClick={() => {
          updateAdminAccess
            .mutateAsync({
              UserId: admin?.id,
              isActive: !admin?.isActive,
              isAdmin: true,
            })
            .then((result) => {
              toast({
                title: "Permissions updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            })
            .catch((err) => {
              toast({
                title: err?.message || "Error updating permissions",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        }}
        // primary
        // bg={btnPrimaryBG}
        // border={"#00FFE0"}
        // textColor={"#000"}
      />
      <SecondaryButton
        text={"Update"}
        primary
        bg={btnPrimaryBG}
        border={"#00FFE0"}
        textColor={"#000"}
        isDisabled={
          updateAdminPermission?.isPending || updateAdminAccess?.isPending
        }
        isLoading={updateAdminPermission?.isPending}
        onClick={handleUpdatePermissions}
      />
    </Flex>
  );
}

export default AdminRoleCard;
