import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import settingIcon from "../../assets/icons/general/setting.svg";
import SecondaryButton from "../form-components/secondary-button";
import CustomInput from "../setting/custom-input-setting";
import { useUpdateReferralCodeByUserId } from "../../utils/auth.api";

const Tag = ({ text, key, width, weight, icon, user, reffCode, wrap }: any) => {
  const toast = useToast();

  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor = useColorModeValue("#E9E9E9", "#535353");

  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#1C1C1C");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#00FFE0");
  const bgModal = useColorModeValue("#E4E4E4", "#000");

  const updateReferralCode = useUpdateReferralCodeByUserId();
  const [referralCode, setReferralCode] = useState<string>("");

  const {
    isOpen: isChangeModalOpen,
    onOpen: onChangeModalOpen,
    onClose: onChangeModalClose,
  } = useDisclosure();

  const handleUpdateReferralCode = () => {
    if (referralCode.length < 1) {
      toast({
        title: "Referral code cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateReferralCode
      .mutateAsync({ UserId: user?.user?.id, RefferalCode: referralCode })
      .then((result) => {
        toast({
          title: "Referral code updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reffCode(referralCode);
        onChangeModalClose();
        setReferralCode("");
      })
      .catch((err) => {
        toast({
          title: err?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      width={width}
      key={key}
      borderRadius={"15px"}
      height={"20px"}
      bg={bgColor}
      px={"6px"}
      alignItems={"center"}
      gap={"10px"}
    >
      <Text
        color={textColor}
        fontFamily={"Plus Jakarta Sans"}
        fontSize={"13px"}
        fontWeight={weight ? weight : 700}
        lineHeight={"17px"}
        whiteSpace={wrap || "nowrap"}
      >
        {text ? text : "dummy"}
      </Text>
      {icon && (
        <Image
          src={settingIcon}
          alt="settingicon"
          width={"13px"}
          height={"13px"}
          cursor={"pointer"}
          onClick={onChangeModalOpen}
        />
      )}

      {/* update reff, and add custom reff */}
      <Modal
        isOpen={isChangeModalOpen}
        onClose={() => {
          onChangeModalClose();
          setReferralCode("");
        }}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody pl={"31px"} py={"45px"} bg={bgModal}>
            <Flex direction={"column"} gap={"20px"}>
              <Flex gap={"20px"} alignItems={"center"}>
                <CustomInput
                  placeholder={"Current Referral code"}
                  width={"192px"}
                  value={user?.user?.referralCode || ""}
                />
                <CustomInput
                  placeholder={"New Referral Code"}
                  width={"192px"}
                  value={referralCode}
                  onChange={(e: any) => setReferralCode(e.target.value)}
                />
              </Flex>
              <Flex gap={"20px"} alignItems={"center"}>
                <SecondaryButton
                  text={"Cancel"}
                  bg={btnSecondaryBG}
                  // border={"#000"}
                  border={"#00FFE0"}
                  textColor={textColor}
                  width={"102px"}
                  isDisabled={updateReferralCode?.isPending}
                  onClick={() => {
                    onChangeModalClose();
                    setReferralCode("");
                  }}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  textColor={"#000"}
                  width={"102px"}
                  isDisabled={updateReferralCode?.isPending}
                  isLoading={updateReferralCode?.isPending}
                  onClick={handleUpdateReferralCode}
                />
              </Flex>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onClose}>
        Close
      </Button>
      <Button variant="ghost">Secondary Action</Button>
    </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Tag;
