import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Divider, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import { useGetUserDataGraph } from "../../utils/user.api";

type Filter = "1D" | "5D" | "1M" | "6M" | "YTD" | "1Y" | "5Y" | "MAX";

interface ChartData {
  x: string[];
  y: number[];
}

const LineChartWithFiltersUser: React.FC = () => {
  const lineColor = useColorModeValue("#58a65c", "#b771b7");
  const textColor = useColorModeValue("#616161", "#b2b9b6");
  const selectedColor = useColorModeValue("#3b5998", "#9a67ea");

  const [selectedFilter, setSelectedFilter] = useState<Filter>("1D");
  const [chartData, setChartData] = useState<ChartData>({ x: [], y: [] });

  const { data, isLoading } = useGetUserDataGraph({
    period: selectedFilter,
  });

  useEffect(() => {
    if (data) {
      const formattedData = {
        x: data.map((item: { date: string }) => item.date),
        y: data.map((item: { count: number }) => item.count),
      };
      setChartData(formattedData);
    }
  }, [data]);

  const options: ApexOptions = {
    series: [
      {
        data: chartData.x.map((date, index) => ({
          x: new Date(date).getTime(),
          y: chartData.y[index],
        })),
      },
    ],
    chart: {
      type: "area",
      stacked: false,
      height: 20,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    colors: [lineColor],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#919895",
        },
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "#919895",
        },
      },
      type: "datetime",
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
  };

  const handleFilterChange = (filter: Filter) => {
    setSelectedFilter(filter);
  };

  const buttonStyle = (filter: Filter) => ({
    color: selectedFilter === filter ? selectedColor : textColor,
    borderBottom:
      selectedFilter === filter ? `2px solid ${selectedColor}` : "none",
  });

  return (
    <Flex direction={"column"} gap={"10px"} width={"447px"}>
      <Flex gap={"10px"} alignItems={"center"} ml={"10px"}>
        {(["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y", "MAX"] as Filter[]).map(
          (filter) => (
            <React.Fragment key={filter}>
              <button
                onClick={() => handleFilterChange(filter)}
                style={buttonStyle(filter)}
              >
                {filter}
              </button>
              {filter !== "MAX" && (
                <Divider orientation="vertical" color={textColor} />
              )}
            </React.Fragment>
          )
        )}
      </Flex>
      <Flex width={"100%"}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Chart
            options={options}
            series={options.series}
            type="area"
            height="474px"
            width={"892px"}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default LineChartWithFiltersUser;
