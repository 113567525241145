import {
  Flex,
  Image,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import userIconLight from "../../assets/icons/general/user-icon-light.svg";
import userIconDark from "../../assets/icons/general/user-icon-dark.svg";
import settingIcon from "../../assets/icons/general/setting.svg";
import { useNavigate } from "react-router-dom";
import Dropdown from "../form-components/dropdown";
import { useGetUsersStats } from "../../utils/user.api";

const FilterCard = ({
  type,
  active,
  p,
  title,
  count,
  isLoading,
  left,
}: any) => {
  // Fetching user stats
  const { data: userStatsResponse } = useGetUsersStats("MAX");

  // Prepare data for display
  const data = userStatsResponse
    ? [
        { title: "Today", count: userStatsResponse?.newUsers?.today },
        { title: "Week", count: userStatsResponse?.newUsers?.thisWeek },
        { title: "Month", count: userStatsResponse?.newUsers?.thisMonth },
      ]
    : [
        { title: "Today", count: "0" },
        { title: "Week", count: "0" },
        { title: "Month", count: "0" },
      ];

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const userIcon = useColorModeValue(userIconLight, userIconDark);
  const textColor = useColorModeValue("#000", "#FFF");

  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <Flex
      cursor={"pointer"}
      bg={bgColor}
      padding={"24px 48px 24px 48px"}
      alignItems={"center"}
      gap={"15px"}
      borderRadius={"10px"}
      _hover={{
        border: "3px solid #00FFE0",
        boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      onClick={() => {
        navigate("/user");
      }}
      border={active ? "1px solid #00FFE0" : undefined}
    >
      <Flex
        direction={"column"}
        gap={"10px"}
        alignItems={"center"}
        position={"relative"}
      >
        <Image
          src={settingIcon}
          alt="settingicon"
          width={"15px"}
          height={"15px"}
          onClick={handleDropdownToggle}
          cursor="pointer"
        />
        {dropdownOpen && (
          <Dropdown
            isOpen={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
            positionTop={"17px"}
            left={left || false}
            sub
            type={type || "users"}
          />
        )}
        <Image src={userIcon} alt="Usericon" width={"35px"} height={"35px"} />
      </Flex>

      {!title && (
        <Flex gap={"8px"} alignItems={"center"}>
          {data?.map((item: any, index: number) => (
            <Flex
              key={index}
              direction={"column"}
              gap={"8px"}
              alignItems={"center"}
              // _hover={{
              //   border: "1px solid #00FFE0",
              //   //boxShadow: "4px 3px 0px 0px #00FFE0",
              // }}
            >
              <Text
                color={textColor}
                fontWeight={600}
                // size={"11px"}
                fontSize={"11px"}
                fontFamily={"Plus Jakarta Sans"}
                lineHeight={"20px"}
              >
                {item.title}
              </Text>

              <Text
                color={textColor}
                fontWeight={500}
                //size={"35px"}
                fontSize={"35px"}
                fontFamily={"Plus Jakarta Sans"}
                lineHeight={"36px"}
              >
                {item.count}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}

      {title && (
        <Flex direction={"column"} gap={"8px"} alignItems={"center"}>
          <Text
            color={textColor}
            fontWeight={600}
            // size={"11px"}
            fontSize={"11px"}
            fontFamily={"Plus Jakarta Sans"}
            lineHeight={"20px"}
          >
            {title}
          </Text>

          <Text
            color={textColor}
            fontWeight={500}
            //size={"35px"}
            fontSize={"35px"}
            fontFamily={"Plus Jakarta Sans"}
            lineHeight={"36px"}
          >
            {isLoading ? <Spinner size="sm" /> : count}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default FilterCard;
