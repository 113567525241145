import { Box } from "@chakra-ui/react";
import ThemeToggle from "../general/ThemeToggle";

const AuthNav = () => {
  return (
    <Box p="30px">
      <ThemeToggle ml="auto" />
    </Box>
  );
};

export default AuthNav;
