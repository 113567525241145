import React, { useEffect, useState } from "react";
import { Box, Image, useColorModeValue } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow1 from "../../assets/icons/light/backArrow.svg";
import arrow2 from "../../assets/icons/dark/backArrow.svg";
import AuthLayout from "../../layouts/AuthLayout";
import AuthCardHeader from "../../components/auth/AuthCardHeader";
import TwoFactorAuthentication from "../../components/auth/2fa";

const Verification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData } = location.state || {};
  const [timer, setTimer] = useState(60);
  //const des = `We have sent an email to  { ${maskedEmail} }  with a verification code at `;
  const des = "We have sent an email to your email with a verification code";
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <AuthLayout noAuthNav={true}>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => {
          // if (fromPage === "forgotPassword") {
          //   navigate("/auth/forgot-password");
          // } else {
          //   navigate("/auth/signup");
          // }

          navigate(-1);
        }}
        cursor={"pointer"}
        mb={"40px"}
      />

      <AuthCardHeader
        title="Enter your verification code"
        des={des}
        desColor={useColorModeValue("#000", "#FFF")}
        widthHeading={"457px"}
        widthSubheading={"372px"}
        headingSize={"43px"}
        subheadingSize={"27px"}
        subheadingWeight={400}
      />
      <Box mt={"72px"}>
        <TwoFactorAuthentication email={formData?.email} />
      </Box>
    </AuthLayout>
  );
};

export default Verification;
