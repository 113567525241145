import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Divider, Flex, useColorModeValue } from "@chakra-ui/react";
import { useGetGraphTransactionStats } from "../../utils/wallet.api";
import { formatNumber } from "../../utils/functions/formatNumber";

type Filter = "1D" | "5D" | "1M" | "6M" | "YTD" | "1Y" | "5Y" | "MAX";

interface ChartData {
  x: string[];
  deposit: number[];
  withdraw: number[];
}

const MultilineChartWithFilters: React.FC = () => {
  const textColor = useColorModeValue("#616161", "#b2b9b6");
  const selectedColor = useColorModeValue("#3b5998", "#9a67ea");
  const depositLineColor = useColorModeValue("#6fc172", "#762373");
  const withdrawLineColor = useColorModeValue("#3c7288", "#9f6952");

  const [selectedFilter, setSelectedFilter] = useState<Filter>("5D");
  const [chartData, setChartData] = useState<ChartData>({
    x: [],
    deposit: [],
    withdraw: [],
  });

  const { data: graphData, refetch } = useGetGraphTransactionStats({
    period: selectedFilter,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  useEffect(() => {
    if (graphData) {
      const depositGraphData = graphData.graphData[0].depositBalances;
      const withdrawGraphData = graphData.graphData[0].withdrawalBalances;

      // Merge dates from both datasets
      const allDates = Array.from(
        new Set([
          ...Object.keys(depositGraphData),
          ...Object.keys(withdrawGraphData),
        ])
      ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      const depositValues = allDates.map((date) => depositGraphData[date] || 0);
      const withdrawValues = allDates.map(
        (date) => withdrawGraphData[date] || 0
      );

      setChartData({
        x: allDates,
        deposit: depositValues,
        withdraw: withdrawValues,
      });
    }
  }, [graphData]);

  const options: ApexOptions = {
    series: [
      {
        name: "Deposit",
        data: chartData.x.map((date, index) => ({
          x: new Date(date).getTime(),
          y: chartData.deposit[index],
        })),
        color: depositLineColor,
      },
      {
        name: "Withdraw",
        data: chartData.x.map((date, index) => ({
          x: new Date(date).getTime(),
          y: chartData.withdraw[index],
        })),
        color: withdrawLineColor,
      },
    ],
    chart: {
      type: "area",
      stacked: false,
      height: 20,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
        },
        formatter: function (val: number) {
          return `$${formatNumber(val)}`;
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: textColor,
        },
        format: "dd MMM yyyy",
      },

      type: "datetime",
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: number) {
          return `$${formatNumber(val)}`;
        },
      },
    },
  };

  const handleFilterChange = (filter: Filter) => {
    setSelectedFilter(filter);
    refetch();
  };

  const buttonStyle = (filter: Filter) => ({
    color: selectedFilter === filter ? selectedColor : textColor,
    borderBottom:
      selectedFilter === filter ? `2px solid ${selectedColor}` : "none",
  });

  return (
    <Flex direction={"column"} gap={"10px"} width={"447px"}>
      <Flex gap={"10px"} alignItems={"center"} ml={"10px"}>
        {(["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y", "MAX"] as Filter[]).map(
          (filter) => (
            <React.Fragment key={filter}>
              <button
                onClick={() => handleFilterChange(filter)}
                style={buttonStyle(filter)}
              >
                {filter}
              </button>
              {filter !== "MAX" && (
                <Divider orientation="vertical" color={textColor} />
              )}
            </React.Fragment>
          )
        )}
      </Flex>
      <Flex width={"100%"}>
        <Chart
          options={options}
          series={options.series}
          type="area"
          height="400px"
          width="840px"
        />
      </Flex>
    </Flex>
  );
};

export default MultilineChartWithFilters;
