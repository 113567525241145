import React from "react";
import { Text } from "@chakra-ui/react";
import theme from "../../theme/theme";

const CustomText = ({
  text,
  size,
  h,
  weight,
  color,
  align,
  cursor,
  fontStyle,
  secondary,
  mb,
  mt,
  letterSpacing,
  superScript,
  overflow,
  wrap,
  whiteSpace,
  textOverflow,
  noOfLines,
  opacity,
  fontFamily,
  borderBottom,
  textDecoration,
  onClick,
}: any) => {
  return (
    <>
      <Text
        fontFamily={fontFamily ? fontFamily : "Plus Jakarta Sans"}
        fontSize={size ? size : "13px"}
        lineHeight={h ? h : "20px"}
        fontWeight={weight ? weight : 400}
        color={color ? color : secondary ? "#666" : theme.colors.heading}
        textAlign={align || "left"}
        fontStyle={fontStyle}
        onClick={onClick}
        cursor={cursor}
        mb={mb}
        mt={mt}
        overflow={overflow}
        flexWrap={wrap}
        whiteSpace={whiteSpace}
        textOverflow={textOverflow}
        noOfLines={noOfLines}
        opacity={opacity}
        borderBottom={borderBottom}
        letterSpacing={letterSpacing}
        textDecoration={textDecoration}
      >
        {text}
      </Text>

      {superScript && (
        <Text
          //fontFamily={"Plus Jakarta Sans"}
          color={color ? color : "#212121"}
          fontSize={"9px"}
          fontWeight={800}
          as="sup"
          letterSpacing={"1px"}
        >
          TM
        </Text>
      )}
    </>
  );
};

export default CustomText;
