import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCard from "../components/auth/AuthCard";
import bgImage from "../assets/backgrounds/BackgroundImage.png";
import AuthNav from "../components/auth/AuthNav";

const AuthLayout = ({ children, noAuthNav }: any) => {
  const bgColor = useColorModeValue(theme.colors.light.bg, "#030303");
  return (
    <Box
      bgImage={bgImage}
      bgColor={bgColor}
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="50px"
      fontSize={"14px"}
    >
      {noAuthNav ? <Box h="100px" /> : <AuthNav />}
      <Flex justify={"center"} align={"center"} direction={"column"} flex={1}>
        <AuthCard>{children}</AuthCard>
      </Flex>
    </Box>
  );
};

export default AuthLayout;
