import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "../general/tag";
import SecondaryButton from "../form-components/secondary-button";
import settingIco from "../../assets/icons/general/setting.svg";
import settingeditdark from "../../assets/icons/dark/setting-edit.svg";
import settingeditlight from "../../assets/icons/light/setting-edit.svg";
import CustomInput from "../general/input";
import Dropdown from "../form-components/dropdown";
import LineChartWithFiltersCryptoPrice from "../gaphs/linechart-cryptoprice";
import { useUpdateCoinPrice } from "../../utils/user.api";

const RateDetailCard = ({ data, onClick, user }: any) => {
  const toast = useToast();

  const { mutateAsync: updateCoinPrice, isPending: isLoading } =
    useUpdateCoinPrice();

  const [id, setId] = useState("");

  useEffect(() => {
    if (data) {
      setId(data?.coinId);
    }
  }, [data]);

  const editIcon = useColorModeValue(
    settingeditlight,

    settingeditdark
  );

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.4)",
    "rgba(0, 0, 0, 0.3)"
  );

  const textColor = useColorModeValue("#000", "#FFF");
  const borderColor = useColorModeValue(
    "rgba(0, 255, 224, 0.30)",
    "rgba(0, 255, 224, 0.30)"
  );

  // btn colors bg and border
  const btnSecondaryBG = useColorModeValue("#FFF", "#262626");
  const btnPrimaryBG = useColorModeValue(" #00FFE0", "#153116");

  const [buyRate, setBuyRate] = useState<number | undefined>(undefined);
  const [sellRate, setSellRate] = useState<number | undefined>(undefined);

  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
    if (!expand) {
      onClick(data);
    } else {
      onClick();
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const handleUpdatePrice = async () => {
    const updatedData: { coinId: string; price?: number; priceSell?: number } =
      { coinId: id };
    if (!buyRate && !sellRate) {
      toast({
        title: "Rates are mandatory",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // if (!sellRate) {
    //   toast({
    //     title: "Sell Rate is mandatory",
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    if (buyRate !== undefined) {
      updatedData.price = buyRate;
    }
    if (sellRate !== undefined) {
      updatedData.priceSell = sellRate;
    }

    updateCoinPrice(updatedData)
      .then((result) => {
        toast({
          title: "Coin price updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setExpand(false);
      })
      .catch((err) => {
        toast({
          title: err?.message || "Error updating coin price. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      // maxWidth={"892px"}
      height={expand ? "328px" : "75px"}
      width={"100%"}
      p={"17px 23px 19px 40px"}
      justifyContent={"space-between"}
      bg={bgColor}
      _hover={{
        border: "3px solid #00FFE0",
        //boxShadow: "4px 3px 0px 0px #00FFE0",
      }}
      border={`1px solid ${borderColor}`}
    >
      {/* when row collapse */}
      {!expand && (
        <>
          <Flex gap={"20px"}>
            <Image
              alt="icon"
              src={data?.coinIcon}
              width={"45px"}
              height={"45px"}
            />

            <Flex gap={"2px"} alignItems={"center"} width={"210px"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                // fontSize={"34px"}
                fontSize={{ base: "15px", md: "25px" }}
                fontWeight={700}
                lineHeight={"normal"}
              >
                {data?.coinSymbol?.split("(")?.[0]}/
              </Text>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ base: "15px", md: "25px" }}
                fontWeight={400}
                lineHeight={"normal"}
              >
                NGN
              </Text>
            </Flex>

            <Flex alignItems={"center"} gap={"7px"}>
              <Text
                color={textColor}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ base: "12px", md: "20px" }}
                fontWeight={600}
                lineHeight={"normal"}
              >
                {`B- ₦${Number(data?.price || 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} // S- ₦${Number(data?.priceSell)?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}`}
              </Text>

              <Box position={"relative"}>
                <Image
                  src={settingIco}
                  alt="icon"
                  width={"15px"}
                  height={"15px"}
                  onClick={handleDropdownToggle}
                  cursor="pointer"
                />

                {dropdownOpen && (
                  <Dropdown
                    isOpen={dropdownOpen}
                    onClose={() => setDropdownOpen(false)}
                    positionTop={"17px"}
                    sub
                    left
                    type="coinRates"
                    coinId={data?.coinId}
                    // positionRight={0}
                  />
                )}
              </Box>
            </Flex>
          </Flex>
          {(user?.isSuperAdmin || user?.permissions?.rateSettings) && (
            <Image
              src={editIcon}
              alt="icon"
              width={"45px"}
              height={"45px"}
              cursor={"pointer"}
              onClick={handleExpand}
            />
          )}
        </>
      )}

      {/* when row expand */}
      {expand && (
        <>
          <Flex gap={"21px"} width={"100%"}>
            {/* column 1 */}
            <Flex direction={"column"} gap={"19px"} width={"100%"}>
              <Flex alignItems={"center"} gap={"20px"} mb={"20px"}>
                <Image
                  src={data?.coinIcon}
                  width={"48px"}
                  height={"48px"}
                  alt="Icon"
                />
                <Flex gap={"2px"} alignItems={"center"}>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={{ base: "15px", md: "25px" }}
                    fontWeight={700}
                    lineHeight={"normal"}
                  >
                    {data?.coinSymbol?.split("(")?.[0]}/
                  </Text>
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={{ base: "15px", md: "25px" }}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    NGN
                  </Text>
                </Flex>
              </Flex>

              <Flex direction={"column"} gap={"7px"}>
                <Tag
                  text={`${data?.coinSymbol?.split("(")?.[0]}/USDT`}
                  width={"129px"}
                  weight={400}
                />
                <Text
                  color={textColor}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"normal"}
                >
                  {data?.usdtValue}
                </Text>
              </Flex>

              <Flex gap={"43px"}>
                <Flex direction={"column"} gap={"7px"}>
                  <Tag text={"PAYREMIT VOLUME"} weight={400} />
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"normal"}
                  >
                    {data?.totalVolume}
                  </Text>
                </Flex>

                <Flex direction={"column"} gap={"7px"}>
                  <Tag text={"PREVIOUS RATES"} weight={400} />
                  <Text
                    color={textColor}
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"normal"}
                  >
                    {" "}
                    {`₦${data?.priceFormer}`} / {`₦${data?.priceFormerSell}`}
                  </Text>
                </Flex>
              </Flex>

              <Flex gap={"43px"}>
                <Flex direction={"column"} gap={"7px"} maxW={"121px"}>
                  <Tag text={"BUY RATES"} weight={400} />
                  <CustomInput
                    px="5px"
                    placeholder={"Set new rates"}
                    value={buyRate ?? ""}
                    onChange={(e: any) => setBuyRate(Number(e.target.value))}
                  />
                </Flex>

                <Flex direction={"column"} gap={"7px"} maxW={"121px"}>
                  <Tag text={"SELL RATES"} weight={400} />
                  <CustomInput
                    px="5px"
                    placeholder={"Set new rates"}
                    value={sellRate ?? ""}
                    onChange={(e: any) => setSellRate(Number(e.target.value))}
                  />
                </Flex>
              </Flex>
            </Flex>
            {/* column 2 */}
            <Flex minWidth={"447px"} direction={"column"} gap={"20px"}>
              <Flex alignItems={"center"} gap={"20px"} alignSelf={"flex-end"}>
                <SecondaryButton
                  text={"Cancel"}
                  bg={btnSecondaryBG}
                  border={"#000"}
                  onClick={handleExpand}
                  isDisabled={isLoading}
                />
                <SecondaryButton
                  text={"Update"}
                  primary
                  bg={btnPrimaryBG}
                  border={"#00FFE0"}
                  onClick={handleUpdatePrice}
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              </Flex>
              <Flex width={"100%"}>
                <LineChartWithFiltersCryptoPrice id={id} />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default RateDetailCard;
