import React from "react";
import { Input, useColorModeValue } from "@chakra-ui/react";

const CustomInput = ({ placeholder, width, value, onChange }: any) => {
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.5)",
    "rgba(0, 0, 0, 0.5)"
  );
  const textColor = useColorModeValue("#000", "#FFF");

  return (
    <Input
      placeholder={placeholder}
      width={width ? width : "192px"}
      height="40px"
      borderRadius="5px"
      background={bgColor}
      color={textColor}
      border="none"
      paddingLeft="10px"
      value={value}
      onChange={onChange}
      fontSize="14px"
      _placeholder={{ color: "#636363" }}
    />
  );
};

export default CustomInput;
