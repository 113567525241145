import React, { useState } from "react";
import {
  Box,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import emailLight from "../../assets/icons/light/emailIcon.svg";
import emailLightActive from "../../assets/icons/light/emailActive.svg";
import emailDark from "../../assets/icons/dark/email.svg";
import arrow1 from "../../assets/icons/light/backArrow.svg";
import arrow2 from "../../assets/icons/dark/backArrow.svg";
import AuthLayout from "../../layouts/AuthLayout";
import AuthCardHeader from "../../components/auth/AuthCardHeader";
import UserInfoInput from "../../components/form-components/UserInfoInput";
import PrimaryButton from "../../components/form-components/PrimaryButton";
import { useGetOtp } from "../../utils/auth.api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isInvalid, setIsInvalid] = useState(false);
  console.log(isInvalid);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Replace this with your useGetOtp hook
  const { mutate: getOtp, isPending: isLoading } = useGetOtp();

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await getOtp(formData.email);
      toast({
        title: "OTP sent successfully!",
        status: "success",
      });
      navigate("/auth/verification", {
        state: { formData, from: "forgotPassword" },
      });
    } catch (error: any) {
      toast({
        title: error.message || "Failed to send OTP",
        status: "error",
      });
    }
  };

  const arrowIcon = useColorModeValue(arrow1, arrow2);
  const emailIcon = useColorModeValue(emailLight, emailDark);

  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => navigate(-1)}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Forgot Password?"
        des="Enter your associated email to get the verification code."
      />
      <form onSubmit={handleFormSubmit}>
        <UserInfoInput
          type="email"
          placeholder="Email"
          darkBG="transparent"
          icon={emailIcon}
          activeIcon={useColorModeValue(emailLightActive, emailDark)}
          name="email"
          value={email}
          onChange={handleChange}
          border={!emailPattern.test(email) && email ? "1px solid red" : ""}
        />
        {!emailPattern.test(email) && email && (
          <Text mt="2" color="red">
            Enter a valid email address.
          </Text>
        )}
        <Box minH="2rem" />
        <PrimaryButton
          type="submit"
          text={isLoading ? <Spinner size="sm" /> : "Get Verification Code"}
          disabled={!email || isLoading}
          onClick={() => {
            if (!email) {
              setIsInvalid(true);
            } else {
              setIsInvalid(false);
            }
          }}
        />
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
