import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Divider, Flex, useColorModeValue } from "@chakra-ui/react";
import { useGetUserInfoGraph } from "../../utils/wallet.api";

type Filter = "1D" | "5D" | "1M" | "6M" | "YTD" | "1Y" | "5Y" | "MAX";

const MultilineChartWithFiltersUser: React.FC = () => {
  const textColor = useColorModeValue("#616161", "#b2b9b6");
  const selectedColor = useColorModeValue("#3b5998", "#9a67ea");
  const depositLineColor = useColorModeValue("#6fc172", "#762373");
  const withdrawLineColor = useColorModeValue("#3c7288", "#9f6952");

  const [selectedFilter, setSelectedFilter] = useState<Filter>("6M");
  const [chartData, setChartData] = useState<any>(null);

  const { data: userDevices, refetch } = useGetUserInfoGraph({
    period: selectedFilter,
    dateFilterFor: "devices",
  });

  const options: ApexOptions = {
    series: [
      {
        name: "Mac",
        data: chartData?.x.map((date: any, index: number) => ({
          x: new Date(date).getTime() || "",
          y: chartData.mac[index],
        })),
        color: depositLineColor,
      },
      {
        name: "Window",
        data: chartData?.x.map((date: any, index: number) => ({
          x: new Date(date).getTime() || "",
          y: chartData.window[index],
        })),
        color: withdrawLineColor,
      },
      {
        name: "Android",
        data: chartData?.x.map((date: any, index: number) => ({
          x: new Date(date).getTime() || "",
          y: chartData.android[index],
        })),
        color: "green",
      },
      {
        name: "IOS",
        data: chartData?.x.map((date: any, index: number) => ({
          x: new Date(date).getTime() || "",
          y: chartData.ios[index],
        })),
        color: "orange",
      },
    ],
    chart: {
      type: "area",
      stacked: false,
      height: 20,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
        },
        formatter: function (val: number) {
          return `${val}`;
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: textColor,
        },
        format: selectedFilter === "1D" ? "hh:mm" : "dd MMM yyyy",
      },

      type: "datetime",
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: number) {
          return `${val}`;
        },
      },
    },
  };

  const handleFilterChange = (filter: Filter) => {
    setSelectedFilter(filter);
  };

  const buttonStyle = (filter: Filter) => ({
    color: selectedFilter === filter ? selectedColor : textColor,
    borderBottom:
      selectedFilter === filter ? `2px solid ${selectedColor}` : "none",
  });

  const refactorDevices = (devices: any) => {
    let android: number[] = [];
    let ios: number[] = [];
    let mac: number[] = [];
    let window: number[] = [];
    let x: any = [];
    if (!devices) {
      return;
    }
    Object?.keys(devices)?.forEach((date) => {
      x.push(date);
      devices[date].forEach((device: any) => {
        if (device._id.includes("Windows")) {
          window.push(device.count);
        } else if (device._id.includes("Mac")) {
          mac.push(device.count);
        } else if (
          device._id.includes("iPhone") ||
          device._id.includes("iOS")
        ) {
          ios.push(device.count);
        } else {
          android.push(device.count);
        }
      });
    });
    // Use the updated arrays for chartData
    setChartData({
      x: x,
      mac,
      window,
      android,
      ios,
    });
  };

  useEffect(() => {
    refactorDevices(userDevices?.devices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDevices]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Flex direction={"column"} gap={"10px"} width={"447px"}>
      <Flex gap={"10px"} alignItems={"center"} ml={"10px"}>
        {(["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y", "MAX"] as Filter[]).map(
          (filter) => (
            <React.Fragment key={filter}>
              <button
                onClick={() => handleFilterChange(filter)}
                style={buttonStyle(filter)}
              >
                {filter}
              </button>
              {filter !== "MAX" && (
                <Divider orientation="vertical" color={textColor} />
              )}
            </React.Fragment>
          )
        )}
      </Flex>
      <Flex width={"100%"}>
        <Chart
          options={options}
          series={options.series}
          type="area"
          height="543px"
          width="896px"
        />
      </Flex>
    </Flex>
  );
};

export default MultilineChartWithFiltersUser;
